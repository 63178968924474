import React from 'react';
import { AlertDialogWrapper } from '../../../components';
import { Text } from '@chakra-ui/react';
import { useUnpublishCourse } from '../queries';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	id: number;
}
export const UnpublishCourseModal: React.FC<IProps> = ({
	isOpen,
	onClose,
	id,
}) => {
	const { isLoading, onUnpublish } = useUnpublishCourse();

	return (
		<AlertDialogWrapper
			size={'xl'}
			isOpen={isOpen}
			onClose={onClose}
			acceptButtonProps={{
				isLoading,
				isDisabled: isLoading,
			}}
			acceptCallback={() => {
				if (id) {
					onUnpublish(id, {
						onSuccess: onClose,
					});
				}
			}}>
			<Text
				fontWeight={700}
				color={'darkBlue'}
				fontSize={'2xl'}
				textAlign={'center'}>
				Are you sure you want to unpublish this course?
			</Text>
		</AlertDialogWrapper>
	);
};
