import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const inviteMultipleUserSchema = yup.object().shape({
	permissionId: yup.string().required(ErrorMessages.REQUIRED),
	isContactCreated: yup.boolean(),
	areDealsCreated: yup.boolean(),
	value: yup.number(),
	description: yup.string(),
});

export const inviteMultipleUsersResolver = yupResolver(
	inviteMultipleUserSchema,
);
