import { colors } from '../../theme/colors';
import { BaseTypeIcon } from '../../types';

export const SearchIcon: React.FC<BaseTypeIcon> = ({
	width = 24,
	height = 24,
	fillColor = colors.darkBlueTable[100],
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
				stroke={fillColor}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
