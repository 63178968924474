import { useEffect, useState } from 'react';
import { changeUserRoleRequest, nextRole } from '../helper';
import { Alerter } from '../../../utils/Alerter';
import { queryClient } from '../../../main';

interface ReturnType {
	isLoading: boolean;
	startChangeRoleReq: (id: number, currentRoleId: number | null) => void;
}

export const useChangeUserRole = (refetch: any): ReturnType => {
	const [isLoading, setIsLoading] = useState(false);
	async function startChangeRoleReq(
		id: number,
		currentRoleId: number | null,
	) {
		if (!currentRoleId) {
			Alerter.error("User doesn't have role");
			return;
		}
		setIsLoading(true);
		const result = await changeUserRoleRequest(id, nextRole[currentRoleId]);

		if (result) {
			refetch();
		}
	}
	const isFetching = queryClient.isFetching('user-by-id');
	useEffect(() => {
		isFetching && setIsLoading(false);
	}, [isFetching]);

	return {
		isLoading,
		startChangeRoleReq,
	};
};
