import React from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { EditIcon } from '../../../../../assets/icons';

interface IProps {
	src?: string;
	setActiveStep: (value: number) => void;
	description: string;
	title: string;
}
export const MainCourseImage: React.FC<IProps> = ({
	src,
	setActiveStep,
	title,
	description,
}) => {
	return (
		<Box padding={'20px'} borderRadius={'10px'} bg={'white'}>
			<Text color={'darkBlue'} fontWeight={'700'} fontSize={'xl'}>
				Main Course image
			</Text>
			<Box
				pos={'relative'}
				mt={'10px'}
				borderRadius={'10px'}
				bgSize={'cover'}
				h={'200px'}
				bgImage={`url(${src})`}
				bgPosition="center"
				bgColor={'rgba(16, 22, 44, 0.5)'}
				bgBlendMode={'multiply'}
				bgRepeat="no-repeat">
				<Flex
					align={'center'}
					justify={'center'}
					direction={'column'}
					h={'full'}>
					<Text
						maxW={'70%'}
						isTruncated={true}
						color={'white'}
						fontSize={'2xl'}
						fontWeight={'700'}>
						{title}
					</Text>
					<Text
						maxW={'70%'}
						isTruncated={true}
						fontWeight={'500'}
						color={'white'}>
						{description}
					</Text>
				</Flex>
				<IconButton
					top={'20px'}
					right={'20px'}
					bg={'white'}
					pos={'absolute'}
					onClick={() => {
						setActiveStep(0);
					}}
					aria-label={'edit main course image'}
					icon={<EditIcon fillColor={'#08BB4B'} />}
				/>
			</Box>
		</Box>
	);
};
