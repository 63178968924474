import React from 'react';
import { HStack, Flex, Text, Button } from '@chakra-ui/react';
import { greenButton } from '../../../../../theme/elementStyles';

interface IEditEntryTemplateProps {
	label: string;
	value: string;
	isLoading: boolean;
	loadingText: string;
	onClick: VoidFunction;
	buttonLabel: React.ReactNode;
}

export const EditEntryTemplate: React.FC<IEditEntryTemplateProps> = ({
	label,
	value,
	isLoading,
	loadingText,
	onClick,
	buttonLabel,
}) => {
	return (
		<Flex align="center" justify="space-between">
			<HStack>
				<Text
					fontSize={'16px'}
					color={'lightText'}
					whiteSpace={['normal', 'nowrap']}>
					{label}
				</Text>
				<Text
					fontSize={'16px'}
					color={'darkBlue'}
					whiteSpace={['normal', 'nowrap']}
					fontWeight={700}>
					{value}
				</Text>
			</HStack>
			<Button
				{...greenButton}
				isLoading={isLoading}
				loadingText={loadingText}
				onClick={onClick}>
				{buttonLabel}
			</Button>
		</Flex>
	);
};
