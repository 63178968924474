import React from 'react';
import ReactSelect, { Props } from 'react-select';
import { stylesConfig } from './styles';
import { Box } from '@chakra-ui/react';

interface ICustomSelect extends Props {
	errMsg?: string;
}

export const CustomSelect: React.FC<ICustomSelect> = ({ errMsg, ...props }) => {
	return (
		<Box w={'full'}>
			<ReactSelect
				aria-invalid={Boolean(errMsg)}
				styles={stylesConfig()}
				components={{
					IndicatorSeparator: () => null,
				}}
				{...props}
			/>
		</Box>
	);
};
