import React from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { EditIcon } from '../../../assets/icons';
import { useCourseContext } from '../context';

interface IProps {
	setActiveStep: (value: number) => void;
}
export const CourseDescriptionBox: React.FC<IProps> = ({ setActiveStep }) => {
	const { title, description } = useCourseContext();
	return (
		<Flex
			p={'20px'}
			borderRadius={'10px'}
			bg={'white'}
			align={'center'}
			justify={'space-between'}>
			<Box>
				<Text
					noOfLines={2}
					fontWeight={'700'}
					fontSize={'xl'}
					color={'darkBlue'}>
					{title}
				</Text>
				<Text noOfLines={3} fontWeight={'500'} color={'darkBlue'}>
					{description}
				</Text>
			</Box>
			<IconButton
				alignSelf={'flex-start'}
				onClick={() => setActiveStep(0)}
				aria-label={'Edit'}
				icon={<EditIcon fillColor={'#08BB4B'} />}
			/>
		</Flex>
	);
};
