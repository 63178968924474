import { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { ApiResponse } from '../types';
import intercept from './interceptor';

type KeyValue<U> = {
	[key: string]: U;
};
const appAxios = intercept(import.meta.env.VITE_BASE_URL);
const identityAxios = intercept(import.meta.env.VITE_OIDC_AUTHORITY);
const chatAxios = intercept(import.meta.env.VITE_BASE_CHAT_URL);

const handleError = (err: any) => {
	if (err && err.response && err?.response?.data) {
		return { data: err.response.data };
	} else {
		Sentry.captureException(err);
		return {
			data: {
				success: false,
				errors: [
					{
						key: 'Request error',
						message: 'Request error',
					},
				],
			},
		};
	}
};

class Api<T> {
	constructor(private axiosInstance: AxiosInstance) {}
	async get(
		url: string,
		params: any,
		headers: KeyValue<string> = {},
		config?: any,
	) {
		const response = await this.axiosInstance
			.get(url, {
				params,
				headers: {
					...headers,
				},
				...config,
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async post(
		url: string,
		body: T,
		config: AxiosRequestConfig = {},
	): Promise<ApiResponse<any>> {
		const response = await this.axiosInstance
			.post(url, body, config)
			.catch(err => handleError(err));
		//console.log(response)
		return response.data;
	}

	async put(url: string, body: T, headers: KeyValue<string> = {}) {
		const response = await this.axiosInstance
			.put(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async patch(url: string, body: T, headers: KeyValue<string> = {}) {
		const response = await this.axiosInstance
			.patch(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async delete(url: string, body?: T, headers: KeyValue<string> = {}) {
		const response = await this.axiosInstance
			.delete(url, {
				data: body,
				headers,
			})
			.catch(err => handleError(err));
		return response.data;
	}
}

export const API_IDENTITY = new Api(identityAxios);
export const API_CHAT = new Api(chatAxios);
export default new Api(appAxios);
