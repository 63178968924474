import React, { ReactNode } from 'react';

interface IAttachmentsList<T> {
	items: T[];
	renderItem: (item: T) => ReactNode;
	keyExtractor: (item: T, index: number) => string;
}

export function AttachmentsList<T>({
	items,
	renderItem,
	keyExtractor,
}: IAttachmentsList<T>): JSX.Element {
	return (
		<>
			{items?.map((item, index) => (
				<React.Fragment key={keyExtractor(item, index)}>
					{renderItem(item)}
				</React.Fragment>
			))}
		</>
	);
}
