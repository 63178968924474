import { colors } from '../colors';

export const Progress = {
	variants: {
		dark: {
			filledTrack: {
				bg: colors.inactiveBlue,
			},
			track: {
				bg: '#BDCDE9',
			},
		},
		light: {
			filledTrack: {
				bg: colors.inactiveBlue,
			},
			track: {
				bg: 'white',
			},
		},
		risk: {
			filledTrack: {
				bg: colors.inactiveBlue,
			},
			track: {
				bg: '#F2F2F3',
			},
		},
	},
	baseStyle: {
		filledTrack: {
			bg: colors.inactiveBlue,
		},
		track: {
			bg: '#BDCDE9',
		},
	},
};
