import React, { useState } from 'react';
import {
	IconButton,
	Text,
	HStack,
	Select,
	Button,
	Checkbox,
	Input,
	TextProps,
	SelectProps,
	StackProps,
} from '@chakra-ui/react';
import {
	MdFirstPage,
	MdLastPage,
	MdArrowForwardIos,
	MdArrowBackIos,
} from 'react-icons/md';

interface IExtendedPaginationProps {
	totalCount: number;
	pageSize: number;
	gotoPage: (value: number) => void;
	currentPage: number;
	setPageSize: (value: number) => void;
	openDeleteConfirmationWindow?: () => void;
	currentItemsLength?: number;
	extendedPagination?: boolean;
	perPageTextProps?: TextProps;
	pagesTextProps?: TextProps;
	selectProps?: SelectProps;
	defaultPaginationProps?: StackProps;
}

export const ExtendedPagination: React.FC<IExtendedPaginationProps> = ({
	totalCount,
	pageSize,
	gotoPage,
	currentPage,
	setPageSize,
	openDeleteConfirmationWindow,
	currentItemsLength,
	extendedPagination,
	perPageTextProps,
	pagesTextProps,
	selectProps,
	defaultPaginationProps,
}) => {
	const pageCount = Math.ceil(totalCount / pageSize);
	const [extendedPage, setExtendedPage] = useState(false);
	const [jumpToPage, setJumpToPage] = useState(currentPage);

	return (
		<>
			<HStack spacing="15px" m={4} {...defaultPaginationProps}>
				<Text
					flexShrink="0"
					color="gray.500"
					fontSize="sm"
					{...perPageTextProps}>
					Items per page:
				</Text>
				<Select
					ml={2}
					w="170px"
					value={pageSize}
					onChange={e => {
						setPageSize(Number(e.target.value));
						gotoPage(1);
					}}
					{...selectProps}>
					{[10, 20, 30, 40, 50].map(pageSize => (
						<option key={pageSize} value={pageSize}>
							{pageSize}
						</option>
					))}
				</Select>
				<Text flexShrink="0" mr={8} fontSize="sm" {...pagesTextProps}>
					{totalCount === 0
						? 0
						: currentPage * pageSize - pageSize + 1}{' '}
					-{' '}
					{currentPage * pageSize > totalCount
						? totalCount
						: currentPage * pageSize}{' '}
					of {totalCount}
				</Text>
				<HStack>
					<IconButton
						aria-label="First page"
						variant="ghost"
						onClick={() => gotoPage(1)}
						isDisabled={currentPage === 1}
						icon={<MdFirstPage size={28} />}
					/>
					<IconButton
						aria-label="Previous Page"
						variant="ghost"
						onClick={() => gotoPage(currentPage - 1)}
						isDisabled={currentPage === 1}
						icon={<MdArrowBackIos />}
					/>
					<IconButton
						aria-label="Next page"
						variant="ghost"
						onClick={() => gotoPage(currentPage + 1)}
						isDisabled={currentPage === pageCount}
						icon={<MdArrowForwardIos />}
					/>
					<IconButton
						aria-label="Last Page"
						variant="ghost"
						onClick={() => gotoPage(pageCount)}
						isDisabled={currentPage === pageCount}
						icon={<MdLastPage size={28} />}
					/>
				</HStack>

				{extendedPagination ? (
					<Checkbox
						isChecked={extendedPage}
						onChange={e => setExtendedPage(!extendedPage)}>
						{' '}
						Extended pagination{' '}
					</Checkbox>
				) : null}

				<HStack w="100%" justifyContent={'flex-end'}>
					{openDeleteConfirmationWindow ? (
						<Button
							isDisabled={totalCount === 0}
							mr="10px"
							justifySelf="end"
							onClick={openDeleteConfirmationWindow}
							colorScheme="red">
							Clear log
						</Button>
					) : null}
				</HStack>
			</HStack>

			{extendedPage ? (
				<>
					<Text mx={4} mb={4} color="gray.500" fontSize="sm">
						Available range: {totalCount ? 1 : 0} -{' '}
						{Math.ceil(totalCount / pageSize)}
					</Text>
					<HStack mx={4} mb={4}>
						<Text color="gray.500" fontSize="sm">
							Go to page:
						</Text>
						<Input
							max={Math.ceil(totalCount / pageSize).toString()}
							onChange={(e: any) => {
								if (
									+e.target.value <=
										Math.ceil(totalCount / pageSize) &&
									+e.target.value > 0
								) {
									setJumpToPage(+e.target.value);
								} else {
									setJumpToPage(-1);
								}
							}}
							maxW="100px"
						/>
						<Button
							colorScheme={'blue'}
							isDisabled={jumpToPage < 0}
							onClick={() => gotoPage(jumpToPage)}
							size="sm">
							Go
						</Button>
					</HStack>
				</>
			) : null}
		</>
	);
};
