import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface IStepper {
	isActive: boolean;
	label: string;
	stepNumber: number;
}
export const Stepper: React.FC<IStepper> = ({
	isActive,
	label,
	stepNumber,
}) => {
	return (
		<Flex
			_notLast={{
				flexGrow: '1',
			}}
			align={'center'}
			_after={{
				content: '""',
				flexGrow: '1',
				borderTop: `2px dashed ${isActive ? '#08BB4B' : '#4A5991'}`,
			}}>
			<Flex
				align={'center'}
				bg={isActive ? '#08BB4B' : '#4A5991'}
				h={'52px'}
				borderRadius={'50px'}
				p={'10px 20px 10px 10px'}>
				<Flex
					mr={'10px'}
					justify={'center'}
					align={'center'}
					bg={'white'}
					w={'32px'}
					h={'32px'}
					borderRadius={'full'}>
					<Text
						color={isActive ? 'lightGreen' : 'inactiveBlue'}
						fontWeight={'500'}
						fontSize={'20px'}>
						{stepNumber}
					</Text>
				</Flex>
				<Text color={'white'} fontWeight={'500'}>
					{label}
				</Text>
			</Flex>
		</Flex>
	);
};
