import { FC, ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface SectionWrapperProps extends BoxProps {
	children: ReactNode;
}

export const SectionWrapper: FC<SectionWrapperProps> = ({
	children,
	...other
}) => {
	return (
		<Box p={'20px'} bg={'white'} {...other}>
			{children}
		</Box>
	);
};
