import React from 'react';
import { HStack, Text } from '@chakra-ui/react';

interface IAnalyticsDetailsItemProps {
	label: string;
	value?: string | number;
}
export const AnalyticsDetailsItem: React.FC<IAnalyticsDetailsItemProps> = ({
	label,
	value,
}) => {
	return (
		<HStack gap="10px" p="10px">
			<Text fontSize="18px" color="lightText">
				{label}:
			</Text>
			<Text fontSize="18px" color="darkBlue" fontWeight={700}>
				{value || '-'}
			</Text>
		</HStack>
	);
};
