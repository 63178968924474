import React from 'react';
import { Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { RenderTd } from '../../../../components';
import { colors } from '../../../../theme/colors';
import { Transaction } from '../../types';
import { useNavigate } from 'react-router-dom';
import { formatBillingAmount } from '../../../../utils';

interface ITableRowProps {
	transaction: Transaction;
	lastItem: boolean;
}
export const TableRow: React.FC<ITableRowProps> = ({
	transaction,
	lastItem,
}) => {
	const navigate = useNavigate();

	const {
		amount,
		fullName,
		date,
		email,
		orderId,
		statusResponse,
		transactionId,
		cardLastFour,
		userId,
	} = transaction;

	return (
		<Tr
			bg="white"
			cursor="pointer"
			onClick={() => navigate(`/user/${userId}`)}>
			<RenderTd
				value={date ? dayjs(date).format('MM/DD/YY') : '-'}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={transactionId}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={orderId}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={fullName}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={cardLastFour}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={email}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={formatBillingAmount(amount)}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={statusResponse}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
		</Tr>
	);
};
