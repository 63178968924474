import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface ICustomSwitchProps {
	isChecked: boolean;
	setIsChecked: (value: boolean) => void;
	leftLabel: string;
	rightLabel: string;
	trackSize?: number;
}
export const CustomSwitch: React.FC<ICustomSwitchProps> = ({
	isChecked,
	setIsChecked,
	leftLabel,
	rightLabel,
	trackSize = 67,
}) => {
	const handleToggle = () => {
		setIsChecked(!isChecked);
	};

	return (
		<Flex
			h="34px"
			borderRadius="20px"
			p="2px"
			bg={isChecked ? 'lightBlue' : 'dropBlue'}
			transition=".3s"
			maxW="fit-content"
			position="relative"
			onClick={handleToggle}
			cursor="pointer">
			<Text
				zIndex={2}
				fontSize="12px"
				lineHeight="30px"
				textAlign="center"
				transition=".3s"
				fontWeight={500}
				color={isChecked ? 'white' : undefined}
				w={`${trackSize}px`}>
				{leftLabel}
			</Text>
			<Text
				zIndex={2}
				fontSize="12px"
				lineHeight="30px"
				textAlign="center"
				fontWeight={500}
				w={`${trackSize}px`}>
				{rightLabel}
			</Text>
			<Box
				zIndex={1}
				position="absolute"
				left={isChecked ? `${trackSize + 2}px` : '2px'}
				transition=".3s"
				w={`${trackSize}px`}
				h="30px"
				borderRadius="20px"
				bg="white"
			/>
		</Flex>
	);
};
