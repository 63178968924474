import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { changePermissionReq } from '../helper';
import { queryClient } from '../../../main';

interface ReturnType {
	isLoading: boolean;
	startChangePermissionReq: (userId: number) => void;
	isChangingState: boolean;
	setIsChangingState: Dispatch<SetStateAction<boolean>>;
	setNewPermId: Dispatch<SetStateAction<number>>;
	readyToChange: boolean;
	newPermId: number;
	userNotify: boolean;
	setUserNotify: Dispatch<SetStateAction<boolean>>;
}

export const useChangePermission = (
	refetch: any,
	initialPermissionId: number | null,
): ReturnType => {
	const [isLoading, setIsLoading] = useState(false);
	const [isChangingState, setIsChangingState] = useState(false);
	const [prevPermId, setPrevPermId] = useState(0);
	const [newPermId, setNewPermId] = useState(0);
	const [readyToChange, setReadyToChange] = useState(false);
	const [userNotify, setUserNotify] = useState(true);

	useEffect(() => {
		if (newPermId !== prevPermId) {
			setReadyToChange(true);
			return;
		}
		setReadyToChange(false);
	}, [newPermId]);

	useEffect(() => {
		if (initialPermissionId) {
			setPrevPermId(initialPermissionId);
			setNewPermId(initialPermissionId);
		}
	}, [initialPermissionId]);

	async function startChangePermissionReq(userId: number) {
		let permId: number;
		// checking if user doesn't have permission id currently, so that we settin' it to 1 by default then
		if (!newPermId) {
			permId = 1;
		} else {
			permId = newPermId;
		}
		if (userId) {
			setIsLoading(true);
			const result = await changePermissionReq(
				userId,
				permId,
				userNotify,
			);
			if (result) {
				refetch();
			}
		}
	}

	const isFetching = queryClient.isFetching('user-by-id');
	useEffect(() => {
		if (isFetching) {
			setIsLoading(false);
			setReadyToChange(false);
			setIsChangingState(false);
		}
	}, [isFetching]);
	return {
		isLoading,
		startChangePermissionReq,
		isChangingState,
		setIsChangingState,
		setNewPermId,
		newPermId,
		readyToChange,
		setUserNotify,
		userNotify,
	};
};
