import { CourseItemType } from './types';

export function splitCoursesArrayByHighlight(
	arr: CourseItemType[],
): [CourseItemType[], CourseItemType[]] {
	const highlightedCourses = [];
	const notHighlightedCourses = [];

	for (const obj of arr) {
		if (obj.highlighted) {
			highlightedCourses.push(obj);
		} else {
			notHighlightedCourses.push(obj);
		}
	}

	return [highlightedCourses, notHighlightedCourses];
}
