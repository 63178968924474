import { BillingTransaction } from './Tabs/Billing/helper';

export type LessonItem = {
	description: string;
	id: number;
	isCompleted: boolean;
	name: string;
	position: number;
};

export type SectionItem = {
	completedLessonsCount: number;
	id: number;
	name: string;
	position: 1;
	lessons: LessonItem[];
};

export type CourseAnalyticsItem = {
	id: number;
	name: string;
	progress: number;
	sections: SectionItem[];
};

export interface UserDetails {
	claimsIds: string;
	documentLink: string;
	email: string;
	firstName: string;
	invitationDate: string;
	isDocumentCompleted: boolean;
	isSuspended: boolean;
	lastLoginDate: string;
	lastName: string;
	permissionGroupId: number;
	phoneNumber: string | null;
	roleId: number;
	id: number;
	signUpDate: string;
	status: string;
	address: string | null;
	courseAnalytics: CourseAnalyticsItem[];
	nmiTransactions: BillingTransaction[];
	loginCount: number;
	invitationResendDate: string;
	soonLookingId: number;
	cashAvailableToInvestId: number;
	realEstateExperienceId: number;
}

export interface UserCommunity {
	displayName: string;
	nickName: string;
	avatarUrl: null | string;
	about: null | string;
	channelMessagesCount: number;
	dmMessagesCount: number | null;
}

export type RiskScoreAction = {
	type: 1; // I guess 1 is email for now
	createdAt: string;
	text: string;
};

export type RiskScoreResponse = {
	totalScore: number;
	onboardingFormAction: boolean | null;
	pandaEnrollmentAction: boolean | null;
	courseCompletionAction: boolean | null;
	dmResponseAction: boolean | null;
	platformLoginAction: boolean | null;
	communityEnrollmentAction: boolean | null;
	isMailingDisabled: boolean;
	actions: RiskScoreAction[];
};

// TIMELINE TYPES

export enum TimelineTypeEnum {
	Call = 1,
	Email,
	DirectMessage,
	Note,
}

export enum TimelineEnumAttachment {
	File = 1,
	Image,
}

export type TimelineFileItem = {
	id: number;
	name: string;
	fileUrl: string;
	date: string;
	type: number;
	imageUrl?: never;
	thumbnailUrl?: never;
	extension: string;
	fileSize: number;
};

export type TimelineImageItem = {
	id: number;
	name: string;
	imageUrl: string;
	thumbnailUrl: string;
	date: string;
	type: number;
	fileUrl?: never;
	extension: string;
	fileSize: number;
};

export type UnionTimelineAttachment = TimelineFileItem | TimelineImageItem;

export type TimelineContentType = {
	topic: null | string;
	note: null | string;
	message: null | string;
	email: null | string;
	subject: null | string;
	bodyText: null | string;
};

export type TimelineItemType = {
	adminId: number;
	adminName: string;
	id: number;
	createdAt: string;
	changedAt: string | null;
	date: string | null;
	timeFrom: string | null;
	timeTo: string | null;
	type: number;
	content: TimelineContentType;
	files: Array<TimelineFileItem>;
	images: Array<TimelineImageItem>;
};

export type DeleteTimelinePayload = {
	id: number;
	userId: number;
};

export type CreateTimelinePayload = {
	userId: number;
	payload: FormData;
};

export type UpdateTimelinePayload = {
	userId: number;
	payload: FormData;
	timelineId: number;
};
