import React, { useEffect } from 'react';
import { SidebarContent } from './SideBarContent';
import { MobileNav } from './MobileNav';
import { Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { MenuContent } from './MenuContent';
import { useGetCurrentUser } from '../../../hooks';
interface ISideBarProps {
	menuExpanded: boolean;
	setMenuExpanded: (value: boolean) => void;
}

export const SideBar: React.FC<ISideBarProps> = ({
	menuExpanded,
	setMenuExpanded,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const location = useLocation();

	const { data, isLoading } = useGetCurrentUser();

	useEffect(() => {
		onClose();
	}, [location.pathname]);

	return (
		<>
			<SidebarContent
				data={data}
				isLoading={isLoading}
				onClose={onClose}
				display={{ base: 'none', md: 'flex' }}
				menuExpanded={menuExpanded}
				setMenuExpanded={setMenuExpanded}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full">
				<DrawerContent>
					<MenuContent
						onClose={onClose}
						isLoading={isLoading}
						data={data}
					/>
				</DrawerContent>
			</Drawer>
			<MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
		</>
	);
};
