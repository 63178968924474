import apiBase from '../../api/api.base';
import { Transaction } from './types';

export const fetchTransactionHistory = async (
	pageSize: number,
	currentPage: number,
	date: string | null,
	cardNumbers: string[],
): Promise<{ transactions: Transaction[]; totalCount: number }> => {
	const response = await apiBase.get(
		'api/admin/transaction/nmi/all',
		{
			currentPage,
			pageSize,
			date,
			CardLastFour: cardNumbers,
		},
		{},
		{
			paramsSerializer: {
				indexes: null, // remove brackets from query string when pass array to params
			},
		},
	);

	return response.value;
};

export const fetchCardsNumbers = async (
	searchString: string,
): Promise<{ cardDigits: string[] }> => {
	const response = await apiBase.get('api/admin/transaction/nmi/all/digits', {
		searchString,
		cardLastFour: searchString,
	});

	return response.value;
};
