export const generateTimeOptions = (minInMinutes = 5) => {
	const timeOptions = [];

	for (let hour = 0; hour < 24; hour++) {
		for (let minute = 0; minute < 60; minute += minInMinutes) {
			const period = hour < 12 ? 'AM' : 'PM';
			const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
			const formattedHour = adjustedHour.toString().padStart(2, '0');
			const formattedMinute = minute.toString().padStart(2, '0');
			const timeValue = `${formattedHour}:${formattedMinute} ${period}`;

			timeOptions.push({
				label: timeValue,
				value: timeValue,
			});
		}
	}

	return timeOptions;
};
