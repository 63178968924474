import React from 'react';
import { Button, Center, HStack, Spinner, VStack } from '@chakra-ui/react';
import { useGetFourthStep } from '../../queries/useGetFourthStep';
import { useCourseContext } from '../../context';
import { useNavigate, Link } from 'react-router-dom';
import {
	MainCourseImage,
	PreviewCourseImage,
	SectionPreview,
} from './components';
import { usePublishCourse } from '../../../Courses/queries';
import { CompletionMessagePreview } from './components/CompletionMessagePreview';

interface IFourthStep {
	setActiveStep: (value: number) => void;
}

export const FourthStepTab: React.FC<IFourthStep> = ({ setActiveStep }) => {
	const { id, description, title } = useCourseContext();

	const navigate = useNavigate();

	const { isLoading, data } = useGetFourthStep(id);

	const { isLoading: publishedLoading, onPublish } = usePublishCourse();

	return (
		<VStack align={'stretch'} spacing={'20px'}>
			{isLoading ? (
				<Center h={'60vh'}>
					<Spinner size={'xl'} />
				</Center>
			) : (
				<>
					<MainCourseImage
						description={description}
						title={title}
						setActiveStep={setActiveStep}
						src={data?.mainCourseImageLink}
					/>

					<PreviewCourseImage
						setActiveStep={setActiveStep}
						src={data?.previewCourseImageLink}
					/>

					{data?.sections.map((it, index) => (
						<SectionPreview
							setActiveStep={setActiveStep}
							key={it.id}
							item={it}
							sectionCountName={`Section ${index + 1}`}
						/>
					))}

					<CompletionMessagePreview
						setActiveStep={setActiveStep}
						isChecked={data?.isCourseCompletedMessage}
						completionMessage={data?.courseCompletedMessage}
					/>

					<HStack spacing={'20px'}>
						<Button
							onClick={() => {
								if (id) {
									onPublish(id, {
										onSuccess: data => {
											if (data.success) {
												navigate('/courses');
											}
										},
									});
								}
							}}
							isDisabled={publishedLoading}
							isLoading={publishedLoading}
							w={'full'}
							h={'59px'}
							colorScheme={'greenButton'}>
							Publish
						</Button>
						<Button
							to={'/courses'}
							as={Link}
							w={'full'}
							h={'59px'}
							colorScheme={'darkButton'}>
							Save without publishing
						</Button>
					</HStack>
				</>
			)}
		</VStack>
	);
};
