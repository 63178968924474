import React, { useState } from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import {
	FactorCard,
	Gauges,
	UnavailablePlaceholder,
	ActionResponse,
	ActionResponsesHeader,
} from './components';
import { ActionResponseEmailModal } from './modals';
import { useGetUserRiskScore } from '../../hooks';
import { RiskScoreAction } from '../../types';

interface IRiskScoreProps {
	userId: number;
}

export const RiskScore: React.FC<IRiskScoreProps> = ({ userId }) => {
	const { data } = useGetUserRiskScore(userId);
	const [selectedActionView, setSelectedActionView] =
		useState<RiskScoreAction | null>(null);

	const onCloseActionResponseViewModal = () => {
		setSelectedActionView(null);
	};

	if (data?.value === null) {
		return (
			<Box borderRadius="10px" padding="20px" bg="white">
				<UnavailablePlaceholder>
					Risk Score is unavailable for the current user
				</UnavailablePlaceholder>
			</Box>
		);
	}

	return (
		<Box borderRadius="10px" padding="20px" bg="white">
			<Gauges data={data?.value} />
			<Text color="black" fontSize="18px" fontWeight={600} mt="24px">
				Factors:
			</Text>
			<SimpleGrid columns={2} mt="16px" gap="8px">
				<FactorCard
					name="Less Than $10k On The Onboarding Form"
					value={25}
					state={data?.value?.onboardingFormAction}
				/>
				<FactorCard
					name="No PandaDoc After 24 Hours Of Enrollment"
					value={75}
					state={data?.value?.pandaEnrollmentAction}
				/>
				<FactorCard
					name="Did Not Complete 90% Of Course After 7 Days"
					value={50}
					state={data?.value?.courseCompletionAction}
				/>
				<FactorCard
					name="No Response to DM after 24 hours"
					value={50}
					state={data?.value?.dmResponseAction}
				/>
				<FactorCard
					name="No Login To Section 8 Pro After 24 Hours"
					value={75}
					state={data?.value?.platformLoginAction}
				/>
				<FactorCard
					name="No Enrollment into Community after 18 hours"
					value={75}
					state={data?.value?.communityEnrollmentAction}
				/>
			</SimpleGrid>
			<ActionResponsesHeader
				userId={userId}
				isMailingDisabled={data?.value?.isMailingDisabled}
			/>
			{data?.value?.actions?.length ? (
				<SimpleGrid columns={2} mt="16px" gap="8px">
					{data?.value?.actions.map((elem, index) => (
						<ActionResponse
							key={index}
							{...elem}
							onViewMore={() => {
								setSelectedActionView(elem);
							}}
						/>
					))}
				</SimpleGrid>
			) : (
				<UnavailablePlaceholder containerProps={{ mt: '16px' }}>
					No action-responses have been issued yet
				</UnavailablePlaceholder>
			)}
			<ActionResponseEmailModal
				isOpen={!!selectedActionView}
				onClose={onCloseActionResponseViewModal}
				data={selectedActionView}
			/>
		</Box>
	);
};
