import React, { PropsWithChildren, useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';

export const ProtectedArea: React.FC<PropsWithChildren> = ({ children }) => {
	const { userData } = useAuth();

	const navigate = useNavigate();

	useEffect(() => {
		if (userData) {
			navigate('/');
		}
	}, [userData]);

	return <>{userData ? children : <Loader centerHeight="100vh" />}</>;
};
