import React from 'react';
import { colors } from '../../../../../theme/colors';

interface ISignalStrengthIconProps {
	position: number;
	isSelected: boolean;
}

export const SignalStrengthIcon: React.FC<ISignalStrengthIconProps> = ({
	position,
	isSelected,
}) => {
	const getBarColor = (index: number) => {
		if (isSelected) {
			// dark
			if (index <= position) {
				return colors.dropBlue;
			} else {
				return '#253674';
			}
		} else {
			// light
			if (index <= position) {
				return '#9AA4CB';
			} else {
				return '#F2F2F3';
			}
		}
	};

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect
				x="4"
				y="12"
				width="4"
				height="8"
				rx="1"
				fill={getBarColor(1)}
			/>
			<rect
				x="10"
				y="8"
				width="4"
				height="12"
				rx="1"
				fill={getBarColor(2)}
			/>
			<rect
				x="16"
				y="4"
				width="4"
				height="16"
				rx="1"
				fill={getBarColor(3)}
			/>
		</svg>
	);
};
