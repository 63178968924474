import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationError, InferType } from 'yup';
import { formatFileSize } from '../utils';

const addLessonSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	description: yup.string().required(ErrorMessages.REQUIRED),
	image: yup
		.mixed<File>()
		// .test('required', ErrorMessages.REQUIRED, (value, context) => {
		// 	const isPreviewImageUploaded =
		// 		context.parent?.isPreviewImageUploaded;
		// 	const isVideoUploaded = context.parent?.isVideoUploaded;
		// 	const videoFile = context.parent?.videoFile;
		// 	const previewImage = context.parent?.previewImage;
		// 	// return !!(
		// 	// 	isPreviewImageUploaded ||
		// 	// 	isVideoUploaded ||
		// 	// 	videoFile ||
		// 	// 	previewImage
		// 	// );
		// 	return true;
		// })
		.test(function (data) {
			const size = data?.size;
			if (size && size > 5e6) {
				return new ValidationError(
					`5 MB max, but you have ${formatFileSize(size)}`,
					undefined,
					'image',
				);
			}
			return true;
		}),
	previewImage: yup
		.mixed<File>()
		// .test('required', ErrorMessages.REQUIRED, (value, context) => {
		// 	console.log(context, 'context');
		// 	const isVideoUploaded = context.parent?.isVideoUploaded;
		// 	const videoFile = context.parent?.videoFile;
		// 	return !(videoFile || isVideoUploaded);
		// })
		.test(function (data) {
			const size = data?.size;
			if (size && size > 5e6) {
				return new ValidationError(
					`5 MB max, but you have ${formatFileSize(size)}`,
					undefined,
					'previewImage',
				);
			}
			return true;
		}),
	attachmentsToDelete: yup.mixed<Array<number>>(),
	newAttachments: yup.mixed<File[]>().default([]),
	isVideoUploaded: yup.bool(),
	isPreviewImageUploaded: yup.bool(),
	isImageUploaded: yup.bool(),
	videoFile: yup.mixed<File>(),
});

export type AddLessonFormValues = InferType<typeof addLessonSchema>;

export const addLessonResolver = yupResolver(addLessonSchema);
