import React from 'react';
import {
	VStack,
	FormLabel,
	Select,
	ModalFooter,
	ModalBody,
	Button,
	HStack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
	useMutation,
} from 'react-query';
import {
	CustomCheckbox,
	InputText,
	ConfirmAdminModal,
} from '../../../../../components';
import { inviteUserResolver } from '../../../../../validation';
import { API_IDENTITY } from '../../../../../api/api.base';
import { ApiResponseEmpty } from '../../../../../types';
import { Alerter } from '../../../../../utils/Alerter';
import { blueButton, greenButton } from '../../../../../theme/elementStyles';
import { usePermissionGroupsStore } from '../../../../../store';

interface IInviteForm {
	firstName: string;
	lastName: string;
	email: string;
	roleId: string;
	permissionId: string;
	isContactCreated: boolean;
	areDealsCreated: boolean;
	value: number | null;
	description: string | null;
}

interface IInviteSingleUserTabProps {
	onClose: () => void;
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<any, unknown>>;
}

export const InviteSingleUserTab: React.FC<IInviteSingleUserTabProps> = ({
	onClose,
	refetch,
}) => {
	const {
		isOpen: isAdminConfirmOpen,
		onClose: onAdminConfirmClose,
		onOpen: onAdminConfirmOpen,
	} = useDisclosure();
	const {
		formState: { errors },
		handleSubmit,
		register,
		watch,
		setError,
		setValue,
	} = useForm<IInviteForm>({
		// @ts-ignore
		resolver: inviteUserResolver,
		defaultValues: {
			areDealsCreated: true,
			isContactCreated: true,
			value: 0,
		},
	});

	const { items } = usePermissionGroupsStore();

	const { mutate, isLoading } = useMutation(
		(data: IInviteForm) =>
			API_IDENTITY.post('/api/admin/users/invite', data),
		{
			onSuccess: (data: ApiResponseEmpty) => {
				if (data.success) {
					onClose();
					refetch();
					Alerter.success('User has been invited');
				} else {
					Alerter.error(data.errors[0]?.message);
				}
			},
			onError: error => {
				console.log(error, 'error');
			},
		},
	);

	const onSubmit = (data: IInviteForm) => {
		if (!data.value && watch('areDealsCreated')) {
			setError('value', { message: 'Input the value, please' });
			return;
		}
		if (data.roleId === '1' && !isAdminConfirmOpen) {
			onAdminConfirmOpen();
			return;
		}
		mutate(data);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<VStack alignItems={'start'} fontWeight={'700'}>
						<InputText
							{...register('firstName')}
							placeholder="First Name"
							label="First Name"
							errorMsg={errors.firstName?.message}
							width="100%"
							labelFontSize={'16px'}
						/>
						<InputText
							{...register('lastName')}
							placeholder="Last Name"
							label="Last Name"
							errorMsg={errors.lastName?.message}
							width="100%"
							labelFontSize={'16px'}
						/>
						<InputText
							{...register('email')}
							placeholder="E-mail"
							label="E-mail"
							errorMsg={errors.email?.message}
							width="100%"
							labelFontSize={'16px'}
						/>
						<FormLabel
							w="100%"
							mb="8px"
							fontSize="16px"
							lineHeight="25px"
							fontWeight={700}>
							Role
						</FormLabel>
						<Select
							w="100%"
							{...register('roleId')}
							border={'none'}
							height="66px"
							fontSize={'16px'}
							color={'darkBlue'}
							fontWeight={'500'}
							_placeholder={{ color: 'lightText' }}
							borderRadius="10px"
							bg="background"
							defaultValue={'2'}>
							<option value={'2'}>User</option>
							<option value={'1'}>Admin</option>
						</Select>
						<FormLabel
							w="100%"
							mb="8px"
							fontSize="16px"
							lineHeight="25px"
							fontWeight={700}>
							Permissions
						</FormLabel>
						<Select
							w="100%"
							{...register('permissionId')}
							border={'none'}
							height="66px"
							fontSize={'16px'}
							color={'darkBlue'}
							fontWeight={'500'}
							_placeholder={{ color: 'lightText' }}
							borderRadius="10px"
							bg="background">
							{items.map(elem => (
								<option
									key={elem.id}
									value={elem.id.toString()}>
									{elem.name}
								</option>
							))}
						</Select>
						<HStack>
							<CustomCheckbox
								isChecked={watch('isContactCreated')}
								onChange={e => {
									if (!e.target.checked) {
										if (watch('areDealsCreated')) {
											setValue(
												'isContactCreated',
												e.target.checked,
											);
											setValue(
												'areDealsCreated',
												e.target.checked,
											);
										}
									}
									setValue(
										'isContactCreated',
										e.target.checked,
									);
								}}
								color={'darkBlue'}
								fontSize={'16px'}
							/>
							<Text color={'darkBlue'}>Sync Contact</Text>
						</HStack>
						<HStack>
							<CustomCheckbox
								isChecked={watch('areDealsCreated')}
								onChange={e => {
									setValue(
										'areDealsCreated',
										e.target.checked,
									);
									if (e.target.checked) {
										setValue(
											'isContactCreated',
											e.target.checked,
										);
									}
								}}
								color={'darkBlue'}
								fontSize={'16px'}
							/>
							<Text color={'darkBlue'}>Create Deal</Text>
						</HStack>
						{watch('areDealsCreated') ? (
							<>
								<InputText
									{...register('description')}
									placeholder="Description"
									label="Description"
									width="100%"
									labelFontSize={'16px'}
								/>
								<InputText
									{...register('value')}
									placeholder="0,0"
									label="Value"
									width="100%"
									errorMsg={errors.value?.message}
									labelFontSize={'16px'}
								/>
							</>
						) : null}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<Button mr={3} onClick={onClose} w={'50%'} {...blueButton}>
						Cancel
					</Button>
					<Button
						isLoading={isLoading}
						type="submit"
						w={'50%'}
						{...greenButton}>
						Invite
					</Button>
				</ModalFooter>
			</form>
			<ConfirmAdminModal
				isOpen={isAdminConfirmOpen}
				onClose={onAdminConfirmClose}
				onConfirm={handleSubmit(onSubmit)}
			/>
		</>
	);
};
