import { Box, Skeleton } from '@chakra-ui/react';
import React from 'react';

const data = Array.from({ length: 4 }, (_, index) => ({ id: index + 1 }));

export const TimelineSkeletonList: React.FC = () => {
	return (
		<>
			{data.map(it => (
				<Box key={it.id}>
					<Skeleton height={160} borderRadius={10} />
				</Box>
			))}
		</>
	);
};
