import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { SectionWrapper } from './SectionWrapper';
import {
	CashAvailableToInvestSize,
	mapInvestmentAmount,
	mapRealEstateExperience,
	mapSoonLookingPeriod,
	RealEstateExperience,
	SoonLookingPeriod,
} from '../../../constants';

interface IProps {
	label: string;
	value: string;
}

const SectionItem: React.FC<IProps> = ({ label, value }) => (
	<Box>
		<Text color={'lightText'}>{label}</Text>
		<Text fontWeight={600} variant={'bodyBig'}>
			{value}
		</Text>
	</Box>
);

interface IOnboardingFormSection {
	soonLookingId: number;
	cashAvailableToInvestId: number;
	realEstateExperienceId: number;
}

export const OnboardingFormSection: React.FC<IOnboardingFormSection> = ({
	soonLookingId,
	realEstateExperienceId,
	cashAvailableToInvestId,
}) => {
	return (
		<SectionWrapper borderRadius={'10px'}>
			<VStack align={'stretch'} spacing={'15px'}>
				<Text fontWeight={700} variant={'bodyLarge'}>
					Onboarding Form
				</Text>
				<SectionItem
					label={'How soon do you want to get started?'}
					value={
						mapSoonLookingPeriod[
							soonLookingId as SoonLookingPeriod
						] || '-'
					}
				/>
				<SectionItem
					value={
						mapInvestmentAmount[
							cashAvailableToInvestId as CashAvailableToInvestSize
						] || '-'
					}
					label={'How much capital do you have available to deploy?'}
				/>
				<SectionItem
					label={'What’s your real estate experience?'}
					value={
						mapRealEstateExperience[
							realEstateExperienceId as RealEstateExperience
						] || '-'
					}
				/>
			</VStack>
		</SectionWrapper>
	);
};
