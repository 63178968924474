import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface Payload {
	courseId: number;
	formData: FormData;
}
export const useEditFirstStep = () => {
	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		Payload
	>({
		mutationFn: ({ courseId, formData }) =>
			Api.post(`/api/admin/courses/${courseId}/first-step`, formData),
		onSuccess: (data, { courseId }) => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			} else {
				// queryClient.invalidateQueries(['section', courseId, sectionId]);
			}
		},
		onError: () => {
			Alerter.error('Error while editing first step');
		},
	});

	return {
		isLoading,
		onEditFirstStep: mutate,
	};
};
