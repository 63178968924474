import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const courseCompletionMessageSchema = yup.object().shape({
	message: yup.string().required(ErrorMessages.REQUIRED),
});

export const courseCompletionMessageResolver = yupResolver(
	courseCompletionMessageSchema,
);
