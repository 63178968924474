import React from 'react';
import {
	Button,
	Center,
	Input,
	Text,
	ModalBody,
	ModalFooter,
	FormLabel,
	Select,
	HStack,
	VStack,
} from '@chakra-ui/react';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
} from 'react-query';
import { useForm } from 'react-hook-form';
import { blueButton, greenButton } from '../../../../../theme/elementStyles';
import { useInviteMultipleUsers } from '../../../hooks';
import { usePermissionGroupsStore } from '../../../../../store';
import { CustomCheckbox, InputText } from '../../../../../components';
import { inviteMultipleUsersResolver } from '../../../../../validation/invite-multiple-user.schema';

interface InviteMultipleUsersProps {
	onClose: VoidFunction;
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<any, unknown>>;
}

export interface IInviteMultipleUsersForm {
	permissionId: string;
	isContactCreated: boolean;
	areDealsCreated: boolean;
	value: number | null;
	description: string | null;
}

export const InviteMultipleUsers: React.FC<InviteMultipleUsersProps> = ({
	onClose,
	refetch,
}) => {
	const {
		formState: { errors },
		handleSubmit,
		register,
		watch,
		setError,
		setValue,
	} = useForm<IInviteMultipleUsersForm>({
		// @ts-ignore
		resolver: inviteMultipleUsersResolver,
		defaultValues: {
			areDealsCreated: true,
			isContactCreated: true,
			value: 0,
		},
	});

	const {
		inviteAllBtnActive,
		startUploadingUsers,
		buttonName,
		inputRef,
		setFile,
		inviteAllBtnLoading,
	} = useInviteMultipleUsers(onClose, refetch);

	const { items } = usePermissionGroupsStore();

	const onSubmit = (data: IInviteMultipleUsersForm) => {
		if (!data.value && watch('areDealsCreated')) {
			setError('value', { message: 'Input the value, please' });
			return;
		}
		startUploadingUsers(data);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<ModalBody>
				<Center>
					<Button {...blueButton}>
						<Text noOfLines={1}>{buttonName}</Text>
						<Input
							ref={inputRef}
							type="file"
							pos="absolute"
							opacity={'0'}
							fontSize="0px"
							cursor={'pointer'}
							accept=".csv"
							onChange={e => {
								if (e.target.files) {
									setFile(e.target.files[0]);
								}
							}}
						/>
					</Button>
				</Center>
				<VStack alignItems={'start'} fontWeight={'700'}>
					<FormLabel
						w="100%"
						mb="8px"
						fontSize="16px"
						lineHeight="25px"
						fontWeight={700}>
						Permissions
					</FormLabel>
					<Select
						w="100%"
						{...register('permissionId')}
						border={'none'}
						height="66px"
						fontSize={'16px'}
						color={'darkBlue'}
						fontWeight={'500'}
						_placeholder={{ color: 'lightText' }}
						borderRadius="10px"
						bg="background">
						{items.map(elem => (
							<option key={elem.id} value={elem.id.toString()}>
								{elem.name}
							</option>
						))}
					</Select>
					<HStack>
						<CustomCheckbox
							isChecked={watch('isContactCreated')}
							onChange={e => {
								if (!e.target.checked) {
									if (watch('areDealsCreated')) {
										setValue(
											'isContactCreated',
											e.target.checked,
										);
										setValue(
											'areDealsCreated',
											e.target.checked,
										);
									}
								}
								setValue('isContactCreated', e.target.checked);
							}}
							color={'darkBlue'}
							fontSize={'16px'}
						/>
						<Text color={'darkBlue'}>Sync Contact</Text>
					</HStack>
					<HStack>
						<CustomCheckbox
							isChecked={watch('areDealsCreated')}
							onChange={e => {
								setValue('areDealsCreated', e.target.checked);
								if (e.target.checked) {
									setValue(
										'isContactCreated',
										e.target.checked,
									);
								}
							}}
							color={'darkBlue'}
							fontSize={'16px'}
						/>
						<Text color={'darkBlue'}>Create Deal</Text>
					</HStack>
					{watch('areDealsCreated') ? (
						<>
							<InputText
								{...register('description')}
								placeholder="Description"
								label="Description"
								width="100%"
								labelFontSize={'16px'}
							/>
							<InputText
								{...register('value')}
								placeholder="0,0"
								label="Value"
								width="100%"
								errorMsg={errors.value?.message}
								labelFontSize={'16px'}
							/>
						</>
					) : null}
				</VStack>
			</ModalBody>
			<ModalFooter justifyContent={'center'}>
				<Button
					{...greenButton}
					w={'100%'}
					isDisabled={!inviteAllBtnActive}
					type="submit"
					isLoading={inviteAllBtnLoading}>
					Invite all
				</Button>
			</ModalFooter>
		</form>
	);
};
