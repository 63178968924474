const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 100;
const MAX_DEGREES = 360;

export const safeRateValue = (progress: number): number => {
	if (!progress) {
		return MIN_PERCENTAGE;
	}

	if (progress > MAX_PERCENTAGE) {
		return MAX_PERCENTAGE;
	}

	if (progress < 0) {
		return MIN_PERCENTAGE;
	}

	return progress;
};

export const safeParseNumber = (value: number): number => {
	if (!value) {
		return 0;
	}

	const parsed = Number(value);
	return Number.isNaN(parsed) ? 0 : parsed;
};

export const getDegrees = (value: number): number =>
	Math.round((value / MAX_PERCENTAGE) * MAX_DEGREES);

export const getProgressBackground = (degree: number, color: string) =>
	`${color} 0deg ${degree}deg, #F2F2F3 ${degree}deg 360deg`;
