import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useGetSecondStep = (courseId: number) => {
	const { isFetching, data } = useQuery<
		ApiResponse<{
			author: {
				id: number;
				name: string;
			};
		}>
	>({
		queryKey: ['second-step', courseId],
		enabled: !!courseId,
		queryFn: async () =>
			Api.get(`/api/admin/courses/${courseId}/second-step`, {}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while getting second step');
		},
	});

	return {
		isLoading: isFetching,
		data: data?.value.author,
	};
};
