import React from 'react';
import { FieldWrapper } from '../../../components';
import {
	Box,
	Button,
	Flex,
	HStack,
	IconButton,
	VStack,
	Text,
	Center,
	Spinner,
} from '@chakra-ui/react';
import { InputText, InputTextArea } from '../../../../../components';
import {
	LeftPaginationArrowIcon,
	PlusIcon,
	TrashIcon,
} from '../../../../../assets/icons';
import { useForm } from 'react-hook-form';
import {
	CreateSectionForm,
	createSectionResolver,
} from '../../../../../validation/create-section.schema';
import {
	useCreateNewLesson,
	useDeleteSection,
	useEditSection,
	useGetSectionById,
} from '../../../queries';
import { LessonItem } from './LessonItem';

interface ISectionForm {
	onClose?: () => void;
	sectionId: number;
	courseId: number;
}
export const SectionForm: React.FC<ISectionForm> = ({
	onClose,
	sectionId,
	courseId,
}) => {
	const { isLoading: isLoadingSection, data: section } = useGetSectionById({
		courseId: courseId,
		sectionId,
	});

	const {
		formState: { errors },
		handleSubmit,
		register,
	} = useForm<CreateSectionForm>({
		defaultValues: {
			name: '',
			description: '',
		},
		values: {
			name: section?.name ? section.name : '',
			description: section?.description ? section.description : '',
		},
		resolver: createSectionResolver,
	});

	const { isLoading: deleteLoading, onDeleteSection } = useDeleteSection();

	const { isLoading: editingLoading, onEditSection } = useEditSection();

	const { isLoading: createLoading, onCreateNewLesson } =
		useCreateNewLesson();

	const onSubmit = (values: CreateSectionForm) => {
		if (section?.id && courseId) {
			onEditSection({
				sectionId: section.id,
				courseId: courseId,
				body: values,
			});
		}
	};

	return (
		<FieldWrapper mt={'20px'}>
			{isLoadingSection ? (
				<Center h={'200px'}>
					<Spinner />
				</Center>
			) : (
				<>
					<form onSubmit={handleSubmit(onSubmit)}>
						<VStack spacing={'20px'} align={'stretch'}>
							<Box>
								<Flex
									mb="8px"
									align={'center'}
									justify={'space-between'}>
									<Text
										fontSize={'xl'}
										color={'darkBlue'}
										fontWeight={700}>
										Section Name
									</Text>
									<HStack>
										<Button
											isLoading={deleteLoading}
											onClick={() => {
												if (section?.id && courseId) {
													onDeleteSection(
														{
															courseId: courseId,
															sectionId:
																section.id,
														},
														{
															onSuccess: onClose,
														},
													);
												}
											}}
											colorScheme={'lightBlueButton'}
											rightIcon={<TrashIcon />}>
											Delete Section
										</Button>
										<IconButton
											onClick={onClose}
											aria-label={'close form'}
											icon={
												<Box
													transform={
														'rotate(-90deg)'
													}>
													<LeftPaginationArrowIcon />
												</Box>
											}
										/>
									</HStack>
								</Flex>
								<InputText
									placeholder={'Name'}
									errorMsg={errors.name?.message}
									{...register('name')}
								/>
							</Box>
							<InputTextArea
								label={'Section Description'}
								placeholder={'Description'}
								errorMsg={errors.description?.message}
								{...register('description')}
							/>
							<Button
								isLoading={editingLoading}
								size={'lg'}
								borderColor={'lightGreen'}
								color={'lightGreen'}
								variant={'outline'}
								type={'submit'}>
								Save
							</Button>
						</VStack>
					</form>
					<VStack mt={'20px'} spacing={'20px'} align={'stretch'}>
						{section?.lessons.map((it, index) => (
							<LessonItem
								courseId={courseId}
								sectionId={sectionId}
								key={it.id}
								item={it}
								countName={`Lesson ${index + 1}`}
							/>
						))}
					</VStack>
					{/*/!*	ADD NEW LESSON*!/*/}
					<Box mt={'20px'}>
						<Button
							onClick={() => {
								if (sectionId && courseId) {
									onCreateNewLesson({
										courseId: courseId,
										sectionId,
									});
								}
							}}
							isDisabled={createLoading}
							isLoading={createLoading}
							colorScheme={'lightBlueButton'}
							size={'lg'}
							rightIcon={<PlusIcon />}>
							Add New Lesson
						</Button>
					</Box>
				</>
			)}
		</FieldWrapper>
	);
};
