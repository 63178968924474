import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export const FieldWrapper: React.FC<PropsWithChildren<BoxProps>> = ({
	children,
	...rest
}) => {
	return (
		<Box p={'20px'} borderRadius={'10px'} bg={'white'} {...rest}>
			{children}
		</Box>
	);
};
