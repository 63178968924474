import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, FormLabel, Text } from '@chakra-ui/react';
import './editor-styles.css';

const modules = {
	toolbar: [
		['bold', 'italic', 'underline', 'strike'],

		[{ size: ['small', false, 'large', 'huge'] }],
		[{ header: [1, 2, 3, 4, 5, 6, false] }],

		[{ color: [] }, { background: [] }],
		[{ font: [] }],
		[{ align: [] }],
		['link'],

		['clean'],
	],
};

interface IEditor extends ReactQuillProps {
	label?: string;
	errMsg?: string;
}

export const Editor = React.forwardRef<null, IEditor>(
	({ label, errMsg, ...rest }, ref) => {
		return (
			<Box>
				<FormLabel
					fontSize={'xl'}
					mb="8px"
					color={'darkBlue'}
					fontWeight={700}>
					{label}
				</FormLabel>
				<ReactQuill
					className={errMsg ? 'ql-error' : undefined}
					theme="snow"
					modules={modules}
					{...rest}
				/>
				{errMsg ? (
					<Text mt={'8px'} fontSize={'sm'} color={'red.500'}>
						{errMsg}
					</Text>
				) : null}
			</Box>
		);
	},
);

Editor.displayName = 'EditorComponent';
