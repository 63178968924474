import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { SectionItemType } from '../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface IPayload {
	courseId: number;
	sectionId: number;
}
export const useGetSectionById = (payload: IPayload) => {
	const { isFetching, data } = useQuery<ApiResponse<SectionItemType>>({
		enabled: !!(payload.courseId && payload.sectionId),
		queryFn: async () =>
			Api.get(
				`/api/admin/courses/${payload.courseId}/third-step/section/${payload.sectionId}`,
				{},
			),
		queryKey: ['section', payload.courseId, payload.sectionId],
		cacheTime: 0,
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while getting section');
		},
	});
	return {
		isLoading: isFetching,
		data: data?.value,
	};
};
