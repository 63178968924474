import { Text } from '@chakra-ui/react';
import React from 'react';
import { AlertDialogWrapper } from '../../../components';
import { useUpdateCourseHighlight } from '../queries';
import { CourseItemType } from '../types';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	data: CourseItemType | null;
}

export const UpdateHighlightCourseModal: React.FC<IProps> = ({
	isOpen,
	onClose,
	data,
}) => {
	const { isLoading, onUpdateHighlight } = useUpdateCourseHighlight();
	const { id, highlighted } = data || {};
	return (
		<AlertDialogWrapper
			size={'xl'}
			isOpen={isOpen}
			onClose={onClose}
			acceptButtonProps={{
				isLoading,
				isDisabled: isLoading,
			}}
			acceptCallback={() => {
				if (id) {
					onUpdateHighlight(id, {
						onSuccess: onClose,
					});
				}
			}}>
			<Text
				fontWeight={700}
				color={'darkBlue'}
				fontSize={'2xl'}
				textAlign={'center'}>
				Are you sure you want to{' '}
				{highlighted ? 'remove highlight from ' : 'highlight '}
				this course?
			</Text>
		</AlertDialogWrapper>
	);
};
