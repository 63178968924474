import { useEffect, useState } from 'react';
import { changeUserStatusRequest } from '../helper';
import { queryClient } from '../../../main';

interface ReturnType {
	isLoading: boolean;
	startChangeStatusReq: (id: number, newStatus: boolean) => void;
}

export const useChangeUserStatus = (refetch: any): ReturnType => {
	const [isLoading, setIsLoading] = useState(false);

	async function startChangeStatusReq(id: number, newStatus: boolean) {
		if (id) {
			setIsLoading(true);
			const result = await changeUserStatusRequest(id, newStatus);
			if (result) {
				refetch();
			}
		}
	}

	const isFetching = queryClient.isFetching('user-by-id');
	useEffect(() => {
		isFetching && setIsLoading(false);
	}, [isFetching]);
	return {
		isLoading,
		startChangeStatusReq,
	};
};
