import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';
import { FirstStepResponse } from '../types';

export const useGetFirstStep = (courseId: number) => {
	const { isFetching, data } = useQuery<ApiResponse<FirstStepResponse>>({
		queryKey: ['first-step', courseId],
		enabled: !!courseId,
		queryFn: async () =>
			Api.get(`/api/admin/courses/${courseId}/first-step`, {}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while getting second step');
		},
	});

	return {
		isLoading: isFetching,
		data: data?.value,
	};
};
