import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface Payload {
	courseId: number;
	authorId: number;
}
export const useEditSecondStep = () => {
	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		Payload
	>({
		mutationFn: async payload =>
			Api.post(`/api/admin/courses/${payload.courseId}/second-step`, {
				authorId: payload.authorId,
			}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while editing second step');
		},
	});

	return {
		isLoading,
		onEditSecondStep: mutate,
	};
};
