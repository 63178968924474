import React from 'react';
import { Box, Text, HStack } from '@chakra-ui/react';
import { ActivityMarker } from '../../../../../assets/icons';
import { colors } from '../../../../../theme/colors';
import { Icon } from '../../../../../components';

interface IFactorCardProps {
	name: string;
	value: number;
	state?: boolean | null;
}

export const FactorCard: React.FC<IFactorCardProps> = ({
	value,
	name,
	state,
}) => {
	const getIcon = () => {
		if (state === null) {
			return <ActivityMarker color={colors.lightGray100} />;
		}
		if (typeof state === 'boolean') {
			if (state) {
				return <ActivityMarker color={colors.inactiveBlue} />;
			} else {
				return (
					<Icon
						name="lock"
						width="24px"
						height="24px"
						color={colors.lightGray200}
					/>
				);
			}
		}
		return null;
	};

	const getBackground = () => {
		if (state === null) {
			return 'background';
		}
		if (typeof state === 'boolean') {
			if (state) {
				return '#FBEDED';
			} else {
				return '#F0F0F0';
			}
		}
		return 'white';
	};

	return (
		<Box
			bg={getBackground()}
			borderRadius="10px"
			height="fit-content"
			p="20px">
			<HStack spacing="16px" align="flex-start">
				{getIcon()}
				<Box>
					<Text color="darkBlue" fontWeight={600} fontSize="16px">
						{name}
					</Text>
					<Text
						color="inactiveBlue"
						fontSize="14px"
						fontWeight={500}
						mt="4px">
						Point value: {value}
					</Text>
				</Box>
			</HStack>
		</Box>
	);
};
