import React, {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useContext,
	useMemo,
	useState,
} from 'react';

type CourseContext = {
	id: number;
	title: string;
	description: string;
	setCourseId: Dispatch<SetStateAction<number>>;
	setCourseData: Dispatch<
		SetStateAction<{ title: string; description: string }>
	>;
};

const CourseContext = createContext<CourseContext>({} as CourseContext);

export const useCourseContext = () => {
	return useContext(CourseContext);
};

export const CourseContextProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [courseId, setCourseId] = useState(0);
	const [{ title, description }, setCourseData] = useState({
		title: '',
		description: '',
	});

	const value = useMemo(
		() => ({
			id: courseId,
			title,
			description,
			setCourseId,
			setCourseData,
		}),
		[title, courseId, description],
	);

	return (
		<CourseContext.Provider value={value}>
			{children}
		</CourseContext.Provider>
	);
};
