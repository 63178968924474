import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { UserCommunity } from '../types';
import { API_CHAT } from '../../../api/api.base';

export const useGetUsersCommunity = (id?: number) => {
	return useQuery<ApiResponse<UserCommunity>>({
		enabled: !!id,
		queryFn: () => API_CHAT.get(`/api/admin/user/${id}`, {}),
		queryKey: ['user-community', id],
	});
};
