import React from 'react';
import { Button } from '@chakra-ui/react';
import { CloseIcon } from '../../../assets/icons';

interface IPickedCardProps {
	cardNumber: string;
	onRemove: (cardToRemove: string) => void;
}
export const PickedCard: React.FC<IPickedCardProps> = ({
	cardNumber,
	onRemove,
}) => {
	return (
		<Button
			px="8px"
			py="4px"
			h="25px"
			bg="dropBlue"
			onClick={() => onRemove(cardNumber)}
			lineHeight="16px"
			fontSize="14px"
			fontWeight={400}
			borderRadius="20px"
			rightIcon={<CloseIcon size="10" />}>
			{cardNumber}
		</Button>
	);
};
