import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { SectionItemType } from '../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useGetFourthStep = (courseId: number) => {
	const { isFetching, data } = useQuery<
		ApiResponse<{
			mainCourseImageLink: string;
			previewCourseImageLink: string;
			sections: SectionItemType[];
			courseCompletedMessage: string | null;
			isCourseCompletedMessage: boolean;
		}>
	>({
		queryKey: ['fourth-step', courseId],
		enabled: !!courseId,
		cacheTime: 0,
		queryFn: async () =>
			Api.get(`/api/admin/courses/${courseId}/fourth-step`, {}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0]?.message);
			}
		},
		onError: () => {
			Alerter.error('Error while getting preview');
		},
	});

	return {
		isLoading: isFetching,
		data: data?.value,
	};
};
