import React, { FC, PropsWithChildren } from 'react';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { userManager } from '../../utils';
import { OIDC_CONFIG } from '../../constants';
import { ProtectedArea } from '../ProtectedArea';

const oidcConfig: AuthProviderProps = {
	...OIDC_CONFIG,
	// onSignIn: user => {
	// 	window.location.href = '/';
	// },
	onSignOut: () => {
		userManager
			.signoutRedirect({
				extraQueryParams: {
					returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
				},
			})
			.catch(e => {
				location.href = `${
					import.meta.env.VITE_OIDC_AUTHORITY
				}/connect/logout?returnUrl=${
					import.meta.env.VITE_OIDC_REDIRECT_URI
				}`;
			});
		console.log('SIGN-OUT');
	},
	autoSignOutArgs: {
		extraQueryParams: {
			returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
		},
	},
	autoSignOut: true,
};

export const OidcAuth: FC<PropsWithChildren> = ({ children }) => (
	<AuthProvider {...oidcConfig} userManager={userManager}>
		<ProtectedArea>{children}</ProtectedArea>
	</AuthProvider>
);
