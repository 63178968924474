import React from 'react';
import { LeftPaginationArrowIcon } from '../../../assets/icons';
import { IconButton } from '@chakra-ui/react';

interface IExpandedButton {
	menuExpanded: boolean;
	onClick: () => void;
}
export const ExpandedButton: React.FC<IExpandedButton> = ({
	menuExpanded,
	onClick,
}) => {
	return (
		<IconButton
			onClick={onClick}
			display={{ base: 'none', md: 'inline-flex' }}
			transition={'transform .2s'}
			right={-2}
			top={'50%'}
			transform={`translateY(-50%) rotate(${
				menuExpanded ? '0' : '180deg'
			})`}
			position={'absolute'}
			background={'transparent'}
			_hover={{
				background: 'blackAlpha.500',
			}}
			_active={{
				background: 'blackAlpha.500',
			}}
			aria-label={'Expand icon'}
			icon={
				<LeftPaginationArrowIcon
					fillColor={'#7E788F'}
					width={20}
					height={20}
				/>
			}
		/>
	);
};
