import { BaseTypeIcon } from '../../types';

export const PaperclipIcon: React.FC<BaseTypeIcon> = ({
	width = 21,
	height = 20,
	fillColor = '#4A5991',
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.1281 9.08345L10.6151 16.5965C8.90653 18.305 6.13644 18.305 4.42789 16.5965C2.71935 14.8879 2.71935 12.1178 4.42789 10.4093L11.9409 2.89626C13.0799 1.75723 14.9267 1.75723 16.0657 2.89626C17.2047 4.03529 17.2047 5.88202 16.0657 7.02105L8.84731 14.2394C8.2778 14.8089 7.35443 14.809 6.78491 14.2394C6.2154 13.6699 6.2154 12.7466 6.78491 12.177L13.1194 5.84254"
				stroke={fillColor}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
