import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Text,
	HStack,
	StackDivider,
	Box,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { RiskScoreAction } from '../../../types';
import { USDateFormat } from '../../../../../constants';

interface IActionResponseEmailModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	data: RiskScoreAction | null;
}

export const ActionResponseEmailModal: React.FC<
	IActionResponseEmailModalProps
> = ({ isOpen, onClose, data }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<Box p="20px">
					<Text
						color="darkBlue"
						fontSize="20px"
						fontWeight={700}
						py="8px">
						Action-response
					</Text>
					<HStack
						divider={<StackDivider borderColor="dropBlue" />}
						spacing="8px">
						<Text
							color="inactiveBlue"
							fontSize="14px"
							fontWeight={500}>
							{dayjs(data?.createdAt).format(USDateFormat)}
						</Text>
						<Text
							color="inactiveBlue"
							fontSize="14px"
							fontWeight={500}>
							{dayjs(data?.createdAt).format('hh:mm A')}
						</Text>
					</HStack>
				</Box>
				<ModalCloseButton
					bg="background"
					top="20px"
					right="20px"
					boxSize="44px"
					_hover={{ bg: 'dropBlue' }}
				/>
				<ModalBody p="0 20px 20px 20px">
					<Text
						whiteSpace="break-spaces"
						fontSize="16px"
						color="blue300"
						fontWeight={500}>
						{data?.text}
					</Text>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
