import { useEffect, useState } from 'react';

export const useDebounce = (value: any, timeout = 500, cb?: () => void) => {
	// Save a local copy of `value` in this state which is local to our hook
	const [state, setState] = useState(value);

	useEffect(() => {
		// Set timeout to run after delay
		const handler = setTimeout(() => {
			setState(value);
			cb?.();
		}, timeout);

		// clear the setTimeout listener on unMount
		return () => clearTimeout(handler);
	}, [value, timeout]);

	return state;
};
