import React from 'react';
import dayjs from 'dayjs';
import {
	Table,
	TableContainer,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { RenderTd, RenderTh } from '../../../../../components';
import { SectionWrapper } from './SectionWrapper';
import { USDateFormat } from '../../../../../constants';

interface LoginActivityProps {
	lastLoginDate?: string;
	loginCount?: number;
	dmsToTomCruzCount?: number | null;
}

export const LoginActivity: React.FC<LoginActivityProps> = ({
	lastLoginDate,
	loginCount,
	dmsToTomCruzCount,
}) => {
	return (
		<SectionWrapper borderRadius={'10px'}>
			<Text fontWeight={'700'} variant={'bodyLarge'}>
				Login & Activity Data
			</Text>

			<TableContainer
				mt={'15px'}
				borderRadius={'10px'}
				border={'1px solid #1C2959'}>
				<Table variant="simple" size={'sm'}>
					<Thead>
						<Tr bg={'lightBlue'}>
							<RenderTh
								textAlign={'center'}
								value={'Last Login Date'}
							/>
							<RenderTh textAlign={'center'} value={'Logins'} />
							<RenderTh
								textAlign={'center'}
								borderRightColor={'#1C2959'}
								value={'DM to Tom Cruz'}
							/>
						</Tr>
					</Thead>
					<Tbody bg={'#FFFFFF'}>
						<Tr>
							<RenderTd
								textAlign={'center'}
								borderRight={'1.5px solid #1C2959'}
								value={
									lastLoginDate
										? dayjs(lastLoginDate).format(
												USDateFormat,
										  )
										: '-'
								}
							/>
							<RenderTd
								textAlign={'center'}
								borderRight={'1.5px solid #1C2959'}
								value={loginCount || '-'}
							/>
							<RenderTd
								textAlign={'center'}
								value={String(dmsToTomCruzCount || '-')}
							/>
						</Tr>
					</Tbody>
				</Table>
			</TableContainer>
		</SectionWrapper>
	);
};
