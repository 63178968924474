import React, { useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	Button,
	Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { InputText } from '../InputText/InputText';
import {
	ConfirmAdminRoleForm,
	confirmAdminRoleResolver,
} from '../../validation';
import { blueButton, greenButton } from '../../theme/elementStyles';

interface IConfirmAdminModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	onConfirm: () => Promise<void> | void;
}

export const ConfirmAdminModal: React.FC<IConfirmAdminModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
}) => {
	const cancelRef = useRef(null);

	const {
		formState: { errors },
		handleSubmit,
		register,
		reset,
	} = useForm<ConfirmAdminRoleForm>({
		resolver: confirmAdminRoleResolver,
	});

	const onCloseWithEffect = () => {
		onClose();
		reset({});
	};

	const onSubmit = (data: ConfirmAdminRoleForm) => {
		onConfirm();
		onCloseWithEffect();
	};

	return (
		<AlertDialog
			isCentered={true}
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onCloseWithEffect}>
			<AlertDialogOverlay>
				<form onSubmit={handleSubmit(onSubmit)}>
					<AlertDialogContent>
						<AlertDialogCloseButton
							bg="background"
							top="20px"
							right="20px"
							boxSize="44px"
							_hover={{ bg: 'dropBlue' }}
						/>
						<AlertDialogHeader
							fontSize="20px"
							fontWeight={700}
							px="20px"
							pt="20px"
							color="darkBlue">
							Confirmation
						</AlertDialogHeader>

						<AlertDialogBody px="20px">
							<Text
								color="lightGray"
								fontWeight={500}
								fontSize="16px"
								my="10px">
								Enter the word “ADMIN” to confirm the creation
								of an admin user
							</Text>
							<InputText
								{...register('text')}
								placeholder="Enter the word confirmation"
								errorMsg={errors.text?.message}
								height="46px"
								bg="white"
								border="1px solid"
								borderColor="dropBlue"
							/>
						</AlertDialogBody>

						<AlertDialogFooter
							justifyContent={'stretch'}
							gap="10px"
							px="20px"
							pb="20px">
							<Button
								{...blueButton}
								ref={cancelRef}
								flex={1}
								onClick={onCloseWithEffect}>
								Cancel
							</Button>
							<Button {...greenButton} flex={1} type="submit">
								Confirm
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</form>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
