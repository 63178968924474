export const VerificationIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M7.49992 9.58363L9.16659 11.2503L12.9166 7.5003M16.6666 10.0003C16.6666 14.0907 12.205 17.0656 10.5816 18.0127C10.3971 18.1203 10.3048 18.1741 10.1747 18.2021C10.0736 18.2237 9.92622 18.2237 9.82519 18.2021C9.695 18.1741 9.60275 18.1203 9.41826 18.0127C7.79489 17.0656 3.33325 14.0907 3.33325 10.0003V6.01497C3.33325 5.34871 3.33325 5.01558 3.44222 4.72922C3.53848 4.47625 3.6949 4.25053 3.89797 4.07157C4.12783 3.869 4.43975 3.75203 5.06359 3.51809L9.53175 1.84253C9.705 1.77756 9.79162 1.74508 9.88074 1.7322C9.95978 1.72078 10.0401 1.72078 10.1191 1.7322C10.2082 1.74508 10.2948 1.77756 10.4681 1.84253L14.9362 3.51809C15.5601 3.75203 15.872 3.869 16.1019 4.07157C16.3049 4.25053 16.4614 4.47625 16.5576 4.72922C16.6666 5.01558 16.6666 5.34871 16.6666 6.01497V10.0003Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
