import * as yup from 'yup';
import { InferType } from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

export const createSectionSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	description: yup.string().required(ErrorMessages.REQUIRED),
});

export type CreateSectionForm = InferType<typeof createSectionSchema>;

export const createSectionResolver = yupResolver(createSectionSchema);
