export enum StatusCourseEnum {
	Published = 1,
	Unpublished = 2,
}

export type CourseItemType = {
	id: number;
	title: string;
	description: string;
	imageLink: null | string;
	statusId: number;
	highlighted: boolean;
};
