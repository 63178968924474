import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Center,
	Collapse,
	Spinner,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { CourseDescriptionBox, NextButton, PrevButton } from '../../components';
import { PlusIcon } from '../../../../assets/icons';
import { useCourseContext } from '../../context';
import {
	useEditSecondStep,
	useGetAuthors,
	useGetSecondStep,
} from '../../queries';
import { Alerter } from '../../../../utils/Alerter';
import { AddAuthorForm, AuthorItem } from './components';

interface IProps {
	setActiveStep: (value: number) => void;
}
export const SecondStepTab: React.FC<IProps> = ({ setActiveStep }) => {
	const { id: courseId } = useCourseContext();

	const [selectedId, setSelectedId] = useState<number>();

	const [idToEdit, setIdToEdit] = useState(0);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const { isLoading: authorsLoading, authors } = useGetAuthors();

	const formRef = useRef<HTMLDivElement>(null);

	const { isLoading: authorsIdsLoading, data: selectedAuthor } =
		useGetSecondStep(courseId);

	const { isLoading: isEditingLoading, onEditSecondStep } =
		useEditSecondStep();

	const onSelectAuthor = (id: number) => {
		setSelectedId(id);
	};

	const onSubmit = () => {
		if (selectedId === selectedAuthor?.id) {
			setActiveStep(2);
		}
		if (selectedId) {
			onEditSecondStep(
				{
					courseId: courseId,
					authorId: selectedId,
				},
				{
					onSuccess: data => {
						if (data.success) {
							setActiveStep(2);
						}
					},
				},
			);
		} else {
			Alerter.info('select at least one author');
		}
	};

	useEffect(() => {
		if (idToEdit) {
			onOpen();
			setTimeout(() => {
				formRef?.current?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 400);
		}
	}, [idToEdit]);

	useEffect(() => {
		if (selectedAuthor && selectedAuthor.id) {
			setSelectedId(selectedAuthor.id);
		}
	}, [selectedAuthor]);

	return (
		<Box>
			<CourseDescriptionBox setActiveStep={setActiveStep} />

			{authorsLoading ? (
				<Center h={'60vh'}>
					<Spinner size={'xl'} />
				</Center>
			) : authors && authors.length !== 0 ? (
				<>
					<Text my={'20px'} fontSize={'xl'} fontWeight={'700'}>
						Select Course Author
					</Text>
					<VStack align={'stretch'} spacing={'10px'}>
						{authors.map(it => (
							<AuthorItem
								setIdToEdit={() => setIdToEdit(it.id)}
								key={it.id}
								name={it.name}
								selected={it.id === selectedId}
								onSelected={() => onSelectAuthor(it.id)}
							/>
						))}
					</VStack>
				</>
			) : null}
			<Collapse in={isOpen} animateOpacity={true} ref={formRef}>
				<AddAuthorForm
					idToEdit={idToEdit}
					onClose={() => {
						onClose();
						setIdToEdit(0);
					}}
				/>
			</Collapse>
			<VStack mt={'20px'} align={'stretch'} spacing={'20px'}>
				<Button
					onClick={onOpen}
					isDisabled={isOpen}
					size={'lg'}
					colorScheme={'lightBlueButton'}
					rightIcon={<PlusIcon />}>
					Add Author
				</Button>
				<Stack direction={'row'} spacing={'20px'}>
					<PrevButton w={'full'} onClick={() => setActiveStep(0)} />
					<NextButton
						w={'full'}
						isDisabled={isEditingLoading || authorsIdsLoading}
						isLoading={isEditingLoading}
						onClick={onSubmit}
					/>
				</Stack>
			</VStack>
		</Box>
	);
};
