import { useQuery } from 'react-query';
import { fetchVerificationUsers } from '../helpers';

export const useGetVerificationUsers = (
	orderId: number,
	pageSize: number,
	currentPage: number,
	searchByStatus: string,
	searchTerm: string,
) => {
	return useQuery(
		[
			'users-verification-list',
			currentPage,
			pageSize,
			orderId,
			searchByStatus,
			searchTerm,
		],
		() =>
			fetchVerificationUsers(
				currentPage,
				pageSize,
				orderId,
				searchByStatus,
				searchTerm,
			),
	);
};
