import { Divider, HStack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { USDateFormat } from '../../../../../../../constants';

interface IDateView {
	date: string;
}

export const DateView: React.FC<IDateView> = ({ date }) => {
	return (
		<HStack
			spacing={'8px'}
			divider={
				<Divider
					orientation={'vertical'}
					borderColor={'dropBlue'}
					h={'15px'}
				/>
			}>
			<Text variant={'bodySmall'} color={'inactiveBlue'}>
				{dayjs(date).format(USDateFormat)}
			</Text>
			<Text variant={'bodySmall'} color={'inactiveBlue'}>
				{dayjs(date).format('hh:mm A')}
			</Text>
		</HStack>
	);
};
