import { useMutation, useQueryClient } from 'react-query';
import { ApiResponse } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface Payload {
	courseId: number;
	sectionId: number;
	lessonId: number;
	formData: FormData;
}
export const useEditLesson = () => {
	const queryClient = useQueryClient();
	const { isLoading, mutate } = useMutation<
		ApiResponse<number>,
		unknown,
		Payload
	>({
		mutationFn: ({ courseId, sectionId, lessonId, formData }) =>
			Api.post(
				`/api/admin/courses/${courseId}/third-step/section/${sectionId}/lesson/${lessonId}`,
				formData,
			),
		onSuccess: (data, { courseId, sectionId }) => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			} else {
				queryClient.invalidateQueries(['section', courseId, sectionId]);
			}
		},
		onError: () => {
			Alerter.error('Error while editing lesson');
		},
	});

	return {
		isLoading,
		onEditLesson: mutate,
	};
};
