import { Button, Flex, Text } from '@chakra-ui/react';
import { greenButton } from '../../../../../../theme/elementStyles';
import { PlusIcon } from '../../../../../../assets/icons';
import { useTimelineStore } from '../../../../store';

export const TimelineHeader: React.FC = () => {
	const onOpen = useTimelineStore(state => state.onOpen);
	return (
		<Flex align={'center'} justify={'space-between'} mb={'15px'}>
			<Text fontWeight={700} variant={'bodyLarge'}>
				Timeline
			</Text>
			<Button
				{...greenButton}
				onClick={onOpen}
				h={'40px'}
				leftIcon={<PlusIcon width={20} height={20} />}>
				Add
			</Button>
		</Flex>
	);
};
