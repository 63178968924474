import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import API from '../../../api/api.base';
import { AxiosProgressEvent } from 'axios';
import { Alerter } from '../../../utils/Alerter';

interface IPayload {
	url: string;
	data: FormData;
	onUpload: (event: AxiosProgressEvent) => void;
}
export const useUploadFile = () => {
	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty | string,
		unknown,
		IPayload
	>({
		mutationFn: async ({ url, data, onUpload }) =>
			API.post(url, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: onUpload,
			}),
		onSuccess: data => {
			if (typeof data === 'string' && data.includes('data: Completed')) {
				Alerter.success('Uploaded successfully');
			}
			if (typeof data === 'object' && !data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Upload failed');
		},
	});

	return {
		isLoading,
		mutate,
	};
};
