import React from 'react';
import { Box, Progress, Flex } from '@chakra-ui/react';
import { ProgressPoint } from './ProgressPoint';

interface IProgressBarProps {
	score?: number;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ score = 0 }) => {
	return (
		<Box mt="24px" position="relative">
			<Progress
				value={score}
				variant="risk"
				borderRadius="8px"
				height="16px"
				max={100}
				sx={{
					'& > div:first-of-type': {
						transitionProperty: 'width',
					},
				}}
			/>
			<Flex
				position="absolute"
				top={0}
				left={0}
				bottom={0}
				right={0}
				width="100%"
				alignItems="center">
				<ProgressPoint
					position={1}
					popupLabel="Low level: 0-34 points"
					popupBackground="lightGreen"
					isSelected={score >= 0}
					left="0%"
				/>
				<ProgressPoint
					position={2}
					popupLabel="Medium level: 34-64 points"
					popupBackground="#ECCE2E"
					isSelected={score >= 35}
					left="34%"
				/>
				<ProgressPoint
					position={3}
					popupLabel="High level: 64-100+ points"
					popupBackground="#F35050"
					isSelected={score >= 65}
					left="65%"
				/>
				<Box />
			</Flex>
		</Box>
	);
};
