import React from 'react';
import { Tooltip, Center } from '@chakra-ui/react';
import { SignalStrengthIcon } from './SignalStrengthIcon';

interface IProgressPointProps {
	position: number;
	popupLabel: string;
	popupBackground: string;
	isSelected: boolean;
	left: string;
}

export const ProgressPoint: React.FC<IProgressPointProps> = ({
	popupLabel,
	popupBackground,
	position,
	isSelected,
	left,
}) => {
	return (
		<Tooltip
			bg={popupBackground}
			padding="10px"
			borderRadius="5px"
			label={popupLabel}>
			<Center
				boxSize="36px"
				position="absolute"
				left={`calc(${left} - 18px)`}
				border="1px solid"
				borderColor={isSelected ? 'transparent' : '#F2F2F3'}
				transition="all .3s"
				bg={isSelected ? 'inactiveBlue' : 'white'}
				_hover={{ bg: isSelected ? '#7886BA' : 'dropBlue' }}
				borderRadius="50%">
				<SignalStrengthIcon
					position={position}
					isSelected={isSelected}
				/>
			</Center>
		</Tooltip>
	);
};
