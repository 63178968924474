import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useUpdateCourseHighlight = () => {
	const queryClient = useQueryClient();
	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		number
	>({
		mutationFn: id => Api.put(`/api/admin/courses/${id}/highlight`, {}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			} else {
				queryClient.invalidateQueries('courses');
			}
		},
		onError: () => {
			Alerter.error('Error while updating course highlight status');
		},
	});

	return {
		isLoading,
		onUpdateHighlight: mutate,
	};
};
