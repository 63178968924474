import React from 'react';
import { Button, useTab } from '@chakra-ui/react';

interface ICustomTabProps {
	children?: React.ReactNode;
}

export const CustomTab = React.forwardRef<null, ICustomTabProps>(
	(props, ref) => {
		const tabProps = useTab({ ...props, ref });
		const isSelected = !!tabProps['aria-selected'];

		return (
			<Button
				bg="white"
				borderBottom="2px solid"
				borderColor={isSelected ? '#0865BB' : 'transparent'}
				color={isSelected ? '#0865BB' : 'darkBlue'}
				fontSize="16px"
				fontWeight={700}
				borderRadius={0}
				{...tabProps}>
				{tabProps.children}
			</Button>
		);
	},
);

CustomTab.displayName = 'CustomTab';
