import React from 'react';
import {
	Box,
	Button,
	Center,
	Flex,
	HStack,
	SimpleGrid,
	Spinner,
	Switch,
	Text,
} from '@chakra-ui/react';
import { PlusIcon } from '../../assets/icons';
import { colors } from '../../theme/colors';
import { CourseItem, HighlightedCourse } from './components';
import { CourseItemType } from './types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { splitCoursesArrayByHighlight } from './utils';

interface ICourse {
	setIdToDeleteCourse: (id: number) => void;
	setIdToPublishCourse: (id: number) => void;
	setIdToUnpublishedCourse: (id: number) => void;
	setCourseToUpdateHighlight: (course: CourseItemType) => void;
	onEditToggle: () => void;
	onDropItem: (draggedItemId: number, newOrder: number) => void;
	onHoverItem: (draggedItemId: number, newOrder: number) => void;
	createLoading: boolean;
	onCreateCourse: () => void;
	courses?: CourseItemType[];
	isLoading: boolean;
	isEditing: boolean;
}
export const Courses: React.FC<ICourse> = ({
	setIdToDeleteCourse,
	setIdToPublishCourse,
	setIdToUnpublishedCourse,
	createLoading,
	onCreateCourse,
	isLoading,
	isEditing,
	onEditToggle,
	courses,
	setCourseToUpdateHighlight,
	onDropItem,
	onHoverItem,
}) => {
	const [highlightedCourses, notHighlightedCourses] =
		splitCoursesArrayByHighlight(courses || []);

	return (
		<Box h={'100%'}>
			<Flex
				justifyContent={{ base: 'stretch', md: 'right' }}
				columnGap="34px">
				<HStack columnGap="10px" spacing={0}>
					<Text color={colors.darkBlue} fontWeight={600}>
						Edit mode
					</Text>
					<Switch
						size="lg"
						isChecked={isEditing}
						onChange={onEditToggle}
						sx={{
							'> span': {
								bg: colors.dropBlue,
							},
						}}
						_checked={{
							'> span': {
								bg: colors.lightBlue,
							},
						}}
					/>
				</HStack>
				<Button
					onClick={onCreateCourse}
					isLoading={createLoading}
					w={{ base: 'full', md: 'auto' }}
					rightIcon={<PlusIcon />}
					colorScheme={'lightBlueButton'}
					height={'64px'}>
					Create Course
				</Button>
			</Flex>
			{/*<Center h={'60vh'}>*/}
			{/*	<Heading variant={'h4'} size={'lg'}>*/}
			{/*		No Courses yet*/}
			{/*	</Heading>*/}
			{/*</Center>*/}

			{isLoading ? (
				<Center height={'60vh'}>
					<Spinner size={'xl'} />
				</Center>
			) : (
				<>
					<Box w="calc(100% + 2rem)" ml="-1rem" mt="20px" mb="20px">
						{highlightedCourses?.map(it => (
							<HighlightedCourse
								setIdToDeleteCourse={setIdToDeleteCourse}
								setIdToPublishCourse={setIdToPublishCourse}
								setIdToUnpublishedCourse={
									setIdToUnpublishedCourse
								}
								key={it.id}
								item={it}
								isEditing={isEditing}
								setCourseToUpdateHighlight={
									setCourseToUpdateHighlight
								}
							/>
						))}
					</Box>
					<DndProvider backend={HTML5Backend}>
						<SimpleGrid
							alignItems={'flex-start'}
							mt={'20px'}
							gridTemplateColumns={
								'repeat(auto-fill, minmax(506px,1fr))'
							}
							gap={'10px'}>
							{notHighlightedCourses?.map((it, index) => (
								<CourseItem
									index={index}
									setIdToDeleteCourse={setIdToDeleteCourse}
									setIdToPublishCourse={setIdToPublishCourse}
									setIdToUnpublishedCourse={
										setIdToUnpublishedCourse
									}
									key={it.id}
									item={it}
									isEditing={isEditing}
									setCourseToUpdateHighlight={
										setCourseToUpdateHighlight
									}
									onDropItem={onDropItem}
									onHoverItem={onHoverItem}
								/>
							))}
						</SimpleGrid>
					</DndProvider>
				</>
			)}

			{/*<Pagination*/}
			{/*	marginTop={'20px'}*/}
			{/*	totalCount={100}*/}
			{/*	pageSize={10}*/}
			{/*	currentPage={1}*/}
			{/*	onPageChange={() => {}}*/}
			{/*/>*/}
		</Box>
	);
};
