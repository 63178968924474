import { FieldView } from './FieldView';
import dayjs from 'dayjs';
import { USDateFormat } from '../../../../../../../constants';

interface IDMView {
	message: string | null;
	date: string | null;
	timeFrom: string | null;
}

export const DMView: React.FC<IDMView> = ({ message, date, timeFrom }) => {
	return (
		<>
			<FieldView label={'Message'} value={message} />
			<FieldView
				label={'Due date'}
				value={date ? dayjs(date).format(USDateFormat) : null}
			/>
			<FieldView
				label={'Time from'}
				value={
					timeFrom
						? dayjs(`${date} ${timeFrom}`).format('hh:mm A')
						: null
				}
			/>
		</>
	);
};
