export const RISK_STATUS_OPTIONS = [
	{
		value: '0',
		label: 'All Risk Score',
	},
	{
		value: '1',
		label: 'None',
	},
	{
		value: '2',
		label: 'Low',
	},
	{
		value: '3',
		label: 'Medium',
	},
	{
		value: '4',
		label: 'High',
	},
];

export const INVITATION_STATUS_OPTIONS = [
	{
		value: '0',
		label: 'All invitation statuses',
	},
	{
		value: '2',
		label: 'Active',
	},
	{
		value: '1',
		label: 'Pending',
	},
	{
		value: '3',
		label: 'Disabled',
	},
];

export const SEARCH_BY_OPTIONS = [
	{
		value: '1',
		label: 'By name',
	},
	{
		value: '2',
		label: 'By email',
	},
];
