import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { AuthorDetailsItemType } from '../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useGetAuthorById = (id: number) => {
	const { isLoading, data } = useQuery<ApiResponse<AuthorDetailsItemType>>({
		enabled: !!id,
		queryFn: async () => Api.get(`/api/admin/courses/authors/${id}`, {}),
		queryKey: ['author', id],
		cacheTime: 0,
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while get author');
		},
	});

	return {
		isLoading,
		data: data?.value,
	};
};
