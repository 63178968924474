import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { CustomProgress } from '../../../../../../components';
import { Section } from '../Section/Section';
import { CourseAnalyticsItem, SectionItem } from '../../../../types';

export const CoursesItem: React.FC<CourseAnalyticsItem> = ({
	name,
	sections,
	progress,
}) => {
	const sortedSections = sections.sort(
		(a: SectionItem, b: SectionItem) => a.position - b.position,
	);

	return (
		<Box borderRadius="10px" bg="white" mb="20px">
			<Box p="20px" borderTopRadius="10px" bg="dropBlue">
				<Text color="blueText" fontSize="18px" fontWeight={700}>
					{name}
				</Text>
				<CustomProgress
					mt="20px"
					mb="20px"
					progress={progress}
					variant="light"
					showProgress={true}
				/>
			</Box>
			<Box p="20px">
				{sortedSections.map((item: SectionItem, index: number) => {
					return <Section key={index} {...item} />;
				})}
			</Box>
		</Box>
	);
};
