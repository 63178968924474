import { RiGroupLine, RiPhoneLockLine } from 'react-icons/ri';
import { IconType } from 'react-icons';
import {
	LogsIcon,
	GraduationHatIcon,
	CommunityIcon,
	VerificationIcon,
	TransactionHistoryIcon,
} from '../assets/icons';

interface LinkItemProps {
	id: number;
	icon: IconType;
	text: string;
	link: string;
	innerLinks?: LinkItemProps[];
}

export const LinkItems: Array<LinkItemProps> = [
	{
		id: 1,
		text: 'Users',
		icon: RiGroupLine,
		link: '/',
	},
	{
		id: 2,
		text: 'Courses',
		icon: GraduationHatIcon,
		link: '/courses',
	},
	{
		id: 5,
		text: 'Community',
		icon: CommunityIcon,
		link: '',
		innerLinks: [
			{
				id: 6,
				text: 'Verification',
				icon: VerificationIcon,
				link: '/community/verification',
			},
		],
	},
	{
		id: 3,
		text: 'Logs',
		icon: LogsIcon,
		link: '/logs',
	},
	{
		id: 4,
		text: 'Permission Groups',
		icon: RiPhoneLockLine,
		link: '/permission-groups',
	},
	{
		id: 5,
		text: 'Transaction History',
		icon: TransactionHistoryIcon,
		link: '/transaction-history',
	},
];
