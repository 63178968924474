import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, HStack, Select as ChakraSelect } from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';
import { InputText } from '../../../../components';
import { SearchIcon } from '../../../../assets/icons';

const options = [
	{ value: 1, label: 'From recent to old users' },
	{ value: 2, label: 'From old to recent users' },
];

const searchOptions = [{ value: 1, label: 'Display Name' }];

interface IHeaderProps {
	sortOrder: number;
	setSortOrder: (value: number) => void;
	onOpen: () => void;
	showPopUpButtonDisabled: boolean;
	setSearchByStatus: Dispatch<SetStateAction<string>>;
	setSearchTerm: Dispatch<SetStateAction<string>>;
	searchTerm: string;
}

export const Header: React.FC<IHeaderProps> = ({
	sortOrder,
	setSortOrder,
	onOpen,
	showPopUpButtonDisabled,
	setSearchByStatus,
	searchTerm,
	setSearchTerm,
}) => {
	// const [newSortOrder, setNewSortOrder] = useState<any>(options[0]);

	return (
		<Flex direction="column" rowGap="15px">
			<Flex
				direction={{ base: 'column', md: 'row' }}
				align="center"
				gap={'5px'}
				justifyContent="space-between">
				<Flex w={{ base: '100%', md: '100%' }} columnGap="15px">
					{/* <Select
					styles={{
						indicatorSeparator: () => ({}),
						control: baseStyles => ({
							...baseStyles,
							width: '100%',
							height: '46px',
							borderRadius: '10px',
							border: 'none',
						}),
						menu: baseStyles => ({
							...baseStyles,
							borderRadius: '10px',
							padding: '8px',
						}),
						option: (baseStyles, state) => ({
							...baseStyles,
							borderRadius: '5px',
							background: state.isSelected
								? colors.lightBlue
								: 'transparent',
						}),
					}}
					defaultValue={options[0]}
					value={newSortOrder}
					onChange={option => setNewSortOrder(option)}
					options={options}
				/> */}
					<ChakraSelect
						value={sortOrder}
						maxW={{ base: '100%', md: '250px' }}
						onChange={e => {
							setSortOrder(Number(e.target.value));
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						<option value={1}>From recent to old users</option>
						<option value={2}>From old to recent users</option>
					</ChakraSelect>
					<ChakraSelect
						maxW={{ base: '100%', md: '150px' }}
						onChange={e => {
							setSearchByStatus(e.target.value);
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						<option value={'1'}>All statuses</option>
						<option value={'2'}>Approved</option>
						<option value={'3'}>Rejected</option>
					</ChakraSelect>
				</Flex>

				<HStack
					w={{ base: '100%', md: 'auto' }}
					flexGrow={'1'}
					mt={{ base: '10px', md: 0 }}>
					<Button
						onClick={onOpen}
						isDisabled={showPopUpButtonDisabled}
						justifySelf="end"
						colorScheme="lightBlueButton"
						w={{ base: '100%', md: 'auto' }}>
						Set Verification Status
					</Button>
				</HStack>
			</Flex>

			<Flex align="center" gap="15px">
				<InputText
					value={searchTerm}
					onChange={e => {
						setSearchTerm(e.target.value);
					}}
					height="46px"
					placeholder="Search"
					bg="white"
					border={`1px solid ${colors.dropBlue}`}
					boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
					leftIcon={<SearchIcon />}
					leftIconProps={{
						h: '46px',
					}}
					formControlProps={{
						maxW: { base: '100%', md: '250px' },
					}}
				/>
				<ChakraSelect
					maxW={{ base: '100%', md: '150px' }}
					// value={searchBy.value}
					// onChange={e => {
					// setSearchBy(e.target.value)
					// }}
					height="46px"
					borderRadius="10px"
					bgColor="white"
					color={colors.darkBlue}
					fontWeight={500}
					boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
					border="none"
					sx={{
						paddingRight: '26px',
					}}>
					{searchOptions.map(elem => (
						<option key={elem.value} value={elem.value.toString()}>
							{elem.label}
						</option>
					))}
				</ChakraSelect>
			</Flex>
		</Flex>
	);
};
