import React from 'react';
import {
	Box,
	CloseButton,
	Image,
	Spinner,
	Text,
	Progress,
	VStack,
	BoxProps,
	Skeleton,
} from '@chakra-ui/react';
import { FieldWrapper } from './FieldWrapper';

interface IPreviewFileUpload extends BoxProps {
	src?: string;
	onRemove: () => void;
	label?: string;
	// onUploadFile?: () => void;
	uploadProgress?: number;
	fileName?: string;
	isLoading: boolean;
	errMsg?: string;
	isUploaded?: boolean;
	variant?: 'image' | 'video';
}
export const PreviewFileUpload: React.FC<IPreviewFileUpload> = ({
	src,
	onRemove,
	label,
	// onUploadFile,
	uploadProgress,
	fileName,
	isLoading,
	errMsg,
	isUploaded,
	variant = 'image',
	...rest
}) => {
	return (
		<FieldWrapper {...rest}>
			{label ? (
				<Text
					fontWeight={'700'}
					fontSize={'xl'}
					mb="8px"
					color={'darkBlue'}>
					{label}
				</Text>
			) : null}
			{errMsg ? (
				<Text fontSize={'sm'} color={'red.500'}>
					{errMsg}
				</Text>
			) : null}
			<Box pos={'relative'} display={'inline-flex'}>
				{variant === 'video' ? (
					<video
						style={{ borderRadius: '16px' }}
						controls={true}
						width={'400px'}
						height={'300px'}>
						<source src={src} />
					</video>
				) : (
					<Image
						h={'220px'}
						borderRadius={'16px'}
						src={src}
						fallback={
							<Skeleton
								h={'220px'}
								borderRadius={'10px'}
								w={'300px'}
							/>
						}
					/>
				)}
				{!isLoading && isUploaded ? (
					<CloseButton
						onClick={onRemove}
						bg={'background'}
						right={3}
						top={3}
						pos={'absolute'}
					/>
				) : null}
				{/*{!isLoading && (*/}
				{/*	<>*/}
				{/*		<CloseButton*/}
				{/*			onClick={onRemove}*/}
				{/*			bg={'background'}*/}
				{/*			right={3}*/}
				{/*			top={3}*/}
				{/*			pos={'absolute'}*/}
				{/*		/>*/}
				{/*		{!isUploaded && (*/}
				{/*			<IconButton*/}
				{/*				onClick={onUploadFile}*/}
				{/*				h={'32px'}*/}
				{/*				minW={'32px'}*/}
				{/*				right={3}*/}
				{/*				top={16}*/}
				{/*				pos={'absolute'}*/}
				{/*				aria-label={'Upload image'}*/}
				{/*				icon={<FiUpload />}*/}
				{/*			/>*/}
				{/*		)}*/}
				{/*	</>*/}
				{/*)}*/}

				{isLoading ? (
					<Box
						position="absolute"
						top={0}
						left={0}
						width="100%"
						height="100%"
						bg="rgba(255, 255, 255, 0.8)"
						display="flex"
						alignItems="center"
						justifyContent="center"
						zIndex="999"
						pointerEvents="none">
						<Spinner
							thickness="3px"
							speed="1s"
							emptyColor="gray.200"
						/>
					</Box>
				) : null}
			</Box>
			<Box mt={'10px'}>
				{fileName ? (
					<Text
						fontWeight={'500'}
						isTruncated={true}
						color={'lightGray'}>
						{fileName}
					</Text>
				) : null}
				{uploadProgress ? (
					<VStack align={'stretch'} spacing={0}>
						<Progress
							colorScheme={'lightBlueButton'}
							borderRadius={'10px'}
							value={uploadProgress}
						/>
						<Text
							alignSelf={'flex-end'}
							fontWeight={'500'}
							isTruncated={true}
							color={'lightGray'}>
							{`${uploadProgress}%`}
						</Text>
					</VStack>
				) : null}
			</Box>
		</FieldWrapper>
	);
};
