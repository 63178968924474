import React from 'react';
import { Box, Text, chakra, HStack, Flex } from '@chakra-ui/react';
import { ProgressBar } from './ProgressBar';
import { RiskTag } from '../../../../../components';
import { CircleProgressBar } from './CircleProgressBar';
import { RiskScoreResponse } from '../../../types';
import { countObjectValues } from '../../../../../utils';

interface IGaugesProps {
	data?: RiskScoreResponse | null;
}

export const Gauges: React.FC<IGaugesProps> = ({ data }) => {
	const factorsData = {
		onboardingFormAction: data?.onboardingFormAction,
		pandaEnrollmentAction: data?.pandaEnrollmentAction,
		courseCompletionAction: data?.courseCompletionAction,
		dmResponseAction: data?.dmResponseAction,
		platformLoginAction: data?.platformLoginAction,
		communityEnrollmentAction: data?.communityEnrollmentAction,
	};

	const labelTotal =
		countObjectValues(factorsData, true) +
		countObjectValues(factorsData, null);
	const labelCurrent = countObjectValues(factorsData, true);
	return (
		<Box>
			<Flex gap="24px">
				<CircleProgressBar
					progress={(labelCurrent / labelTotal) * 100}
					labelCurrent={labelCurrent}
					labelTotal={labelTotal}
				/>
				<Box flex={1}>
					<Text color="lightBlue" fontSize="20px" fontWeight={600}>
						Risk Score:{' '}
						<chakra.span color="darkBlue" fontWeight={500}>
							{data?.totalScore} points
						</chakra.span>
					</Text>
					<HStack spacing="4px" my="8px">
						<Text
							color="lightBlue"
							fontSize="18px"
							fontWeight={600}>
							Level:
						</Text>
						<RiskTag score={data?.totalScore || 0} />
					</HStack>
					<Text color="lightText" fontSize="12px" fontWeight={500}>
						Hover over the level indicator to view the score
						intervals for each level
					</Text>
					<ProgressBar score={data?.totalScore} />
				</Box>
			</Flex>
		</Box>
	);
};
