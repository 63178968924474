import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface IPayload {
	courseId: number;
	sectionId: number;
	lessonId: number;
}
export const useDeleteVideo = () => {
	// const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		IPayload
	>({
		mutationFn: ({ courseId, sectionId, lessonId }) => {
			const formData = new FormData();

			formData.append('IsDeleted', true.toString());

			return Api.post(
				`/api/admin/courses/${courseId}/third-step/section/${sectionId}/lesson/${lessonId}/video`,
				formData,
			);
		},
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			} else {
				Alerter.success('Video successfully deleted');
			}
			// queryClient.invalidateQueries(['section', courseId, sectionId]);
		},
		onError: () => {
			Alerter.error('Error while deleting video');
		},
	});
	return {
		isLoading,
		onDeleteVideo: mutate,
	};
};
