import { useQuery } from 'react-query';
import { fetchCardsNumbers } from '../helpers';

export const useGetCardsNumbers = (searchString: string) => {
	return useQuery(
		['cards-numbers-autocomplete', searchString],
		() => fetchCardsNumbers(searchString),
		{ enabled: !!searchString },
	);
};
