import React from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';

// For some reason build fails if name is capitalized
interface IClearTableModalProps {
	isOpenConfirmation: boolean;
	onCloseConfirmation: () => void;
	deleteTableDataFn: () => Promise<void>;
}

export const ClearTableModal: React.FC<IClearTableModalProps> = ({
	isOpenConfirmation,
	onCloseConfirmation,
	deleteTableDataFn,
}) => {
	const cancelRef = React.useRef(null);
	return (
		<AlertDialog
			leastDestructiveRef={cancelRef}
			isOpen={isOpenConfirmation}
			onClose={onCloseConfirmation}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Clear logs
					</AlertDialogHeader>

					<AlertDialogBody>
						Are you sure? You can&apos;t undo this action
						afterwards.
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button onClick={onCloseConfirmation}>Cancel</Button>
						<Button
							colorScheme="red"
							onClick={() => deleteTableDataFn()}
							ml={3}>
							Clear logs
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
