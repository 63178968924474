import { InputText, InputTextArea } from '../../../../../../../components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TimelineFormValues } from '../../../../../../../validation';

export const CallForm: React.FC = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext<TimelineFormValues>();

	return (
		<>
			<InputText
				placeholder={'Enter topic'}
				{...register('topic')}
				isInvalid={!!errors.topic?.message}
			/>
			<InputTextArea
				placeholder={'Enter note'}
				{...register('callNote')}
				isInvalid={!!errors.callNote?.message}
			/>
		</>
	);
};
