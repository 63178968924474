import React, { useMemo } from 'react';
import { Tag } from '@chakra-ui/react';

interface IRiskTagProps {
	score?: number;
}

export const RiskTag: React.FC<IRiskTagProps> = ({ score }) => {
	const getColorAndLabel = (score?: number) => {
		if (score === undefined) {
			return {
				label: 'None',
				bgColor: 'lightGray100',
				textColor: 'lightGray200',
			};
		}

		switch (true) {
			case score <= 34:
				return {
					label: 'Low',
					bgColor: 'lightGreen',
					textColor: 'white',
				};
			case score <= 64:
				return {
					label: 'Medium',
					bgColor: 'dashboardYellow',
					textColor: 'white',
				};
			case score > 64:
				return {
					label: 'High',
					bgColor: 'dashboardRed',
					textColor: 'white',
				};
			default:
				return {
					label: 'None',
					bgColor: 'lightGray100',
					textColor: 'lightGray200',
				};
		}
	};

	const { label, bgColor, textColor } = useMemo(
		() => getColorAndLabel(score),
		[score],
	);

	return (
		<Tag
			bg={bgColor}
			color={textColor}
			padding="5px 10px"
			borderRadius="30px"
			fontSize="14px"
			fontWeight={600}>
			{label}
		</Tag>
	);
};
