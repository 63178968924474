import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { BREADCRUMBS_ITEMS_USER, modifyBreadcrumbs } from './helper';
import { BreadCrumbs, Loader } from '../../components';
import { useGetUserById, useGetUsersCommunity } from './hooks';
import {
	BillingHistory,
	CourseAnalytics,
	MainInfo,
	Community,
	RiskScore,
} from './Tabs';

const TABS = [
	'Main info',
	'Risk Score',
	'Billing History',
	'Course progress analytics',
	'Community',
];

const UserDetails: React.FC = () => {
	const params = useParams();

	const {
		data: user,
		refetch,
		isLoading,
	} = useGetUserById(params.id ? +params.id : 0);

	const { data: usersCommunity, isLoading: isUsersCommunityLoading } =
		useGetUsersCommunity(Number(params?.id));

	if (isLoading || isUsersCommunityLoading) {
		return <Loader centerHeight={'100vh'} spinnerSize={'md'} />;
	}

	return (
		<Box overflow="hidden">
			<Box mb={'25px'}>
				<BreadCrumbs
					items={modifyBreadcrumbs(
						BREADCRUMBS_ITEMS_USER,
						`${user?.firstName} ${user?.lastName}`,
					)}
				/>
			</Box>
			<Tabs position="relative" variant="unstyled">
				<TabList
					p={'10px'}
					bg={'white'}
					gap={'5px'}
					borderRadius={'10px'}
					display={'inline-flex'}>
					{TABS.map((tab, index) => (
						<Tab
							_selected={{
								bg: 'blueText',
								color: 'white',
								border: 'none',
								borderRadius: '8px',
							}}
							fontSize={'14px'}
							fontWeight={'500'}
							borderRadius={'8px'}
							key={index}>
							{tab}
						</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel px={'0'}>
						<MainInfo
							user={user}
							refetch={refetch}
							dmsToTomCruzCount={
								usersCommunity?.value?.dmMessagesCount
							}
						/>
					</TabPanel>
					<TabPanel px="0">
						<RiskScore userId={params.id ? +params.id : 0} />
					</TabPanel>
					<TabPanel px={'0'}>
						<BillingHistory transactions={user?.nmiTransactions} />
					</TabPanel>
					<TabPanel px={'0'}>
						<CourseAnalytics
							name={`${user?.firstName} ${user?.lastName}`}
							email={user?.email}
							numberOfLogins={user?.loginCount}
							lastLoginDate={user?.lastLoginDate}
							data={user?.courseAnalytics || []}
							documentLink={user?.documentLink}
						/>
					</TabPanel>
					<TabPanel px={'0'}>
						<Community
							userEmail={user?.email}
							usersCommunityData={usersCommunity?.value}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
export default UserDetails;
