import React from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	InputProps,
	FormControlProps,
	InputRightElement,
	FormLabelProps,
	InputLeftElementProps,
} from '@chakra-ui/react';

interface IInputProps extends InputProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	leftIcon?: JSX.Element;
	rightIcon?: JSX.Element;
	width?: string;
	onClickRightIcon?: () => void;
	labelFontSize?: string;
	leftIconProps?: InputLeftElementProps;
}

export const InputText = React.forwardRef<null, IInputProps>(
	(
		{
			errorMsg,
			labelFontSize = 'xl',
			label,
			leftIcon,
			rightIcon,
			formControlProps,
			width = 'auto',
			formLabelProps,
			onClickRightIcon,
			leftIconProps,
			...rest
		},
		ref,
	) => {
		return (
			<FormControl
				w={{ base: '100%', md: width }}
				isInvalid={!!errorMsg}
				{...formControlProps}>
				{label ? (
					<FormLabel
						fontSize={labelFontSize}
						mb="8px"
						color={'darkBlue'}
						fontWeight={700}
						{...formLabelProps}>
						{label}
					</FormLabel>
				) : null}
				<InputGroup>
					{leftIcon ? (
						<InputLeftElement h="100%" {...leftIconProps}>
							{leftIcon}
						</InputLeftElement>
					) : null}
					<Input
						border={'none'}
						height="66px"
						fontSize={'16px'}
						color={'darkBlue'}
						fontWeight={'500'}
						_placeholder={{ color: 'lightGray' }}
						borderRadius="10px"
						bg="background"
						{...rest}
						ref={ref}
					/>
					{rightIcon ? (
						<InputRightElement
							onClick={onClickRightIcon}
							h={'100%'}>
							{rightIcon}
						</InputRightElement>
					) : null}
				</InputGroup>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);
InputText.displayName = 'InputText';
