import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationError, InferType } from 'yup';
import { formatFileSize } from '../utils';

const firstStepSchema = yup.object().shape({
	title: yup.string().required(ErrorMessages.REQUIRED),
	description: yup.string().required(ErrorMessages.REQUIRED),
	mainCourseImage: yup.mixed<File>().when('isMainImgUploaded', {
		is: true,
		then: schema => schema.notRequired(),
		otherwise: schema =>
			schema
				.test('required', ErrorMessages.REQUIRED, data => {
					return !!data;
				})
				.test(function (data) {
					const size = data?.size;
					if (size && size > 5e6) {
						return new ValidationError(
							`5 MB max, but you have ${formatFileSize(size)}`,
							undefined,
							'mainCourseImage',
						);
					}
					return true;
				}),
	}),
	isMainImgUploaded: yup.bool().oneOf([true], 'Upload is required'),
	previewImage: yup.mixed<File>().when('isPreviewImgUploaded', {
		is: true,
		then: schema => schema.notRequired(),
		otherwise: schema =>
			schema
				.test('required', ErrorMessages.REQUIRED, data => {
					return !!data;
				})
				.test(function (data) {
					const size = data?.size;
					if (size && size > 5e6) {
						return new ValidationError(
							`5 MB max, but you have ${formatFileSize(size)}`,
							undefined,
							'previewImage',
						);
					}
					return true;
				}),
	}),
	isPreviewImgUploaded: yup.bool().oneOf([true], 'Upload is required'),
});

export type FirstStepFormValues = InferType<typeof firstStepSchema>;

export const firstStepResolver = yupResolver(firstStepSchema);
