export interface BillingTransaction {
	firstName: string;
	lastName: string;
	address1: string;
	address2: string;
	email: string;
	phone: string;
	cellPhone: string;
	ccNumber: string;
	ccExp: string;
	ccType: string;
	actions: {
		amount: number;
		responseText: string; //status
		success: boolean;
		date: string; //date
		type: string;
	}[];
	product: {
		amount: number; //amount
		description: string;
	};

	transactionType: string;
	orderId: string;
}
export const formatBillingAmount = (amount: number) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(amount);
};

export const transactionsSum = (
	transactions: BillingTransaction[] | undefined,
) => {
	if (!transactions) return 0;

	const successTransactions = [];

	for (let i = 0; i < transactions.length; i++) {
		if (
			transactions[i].actions[transactions[i].actions.length - 1].success
		) {
			successTransactions.push(
				transactions[i].actions[transactions[i].actions.length - 1],
			);
		}
	}
	return successTransactions.reduce(
		(acc, currentValue) => acc + currentValue.amount,
		0,
	);
};
