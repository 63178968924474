import React from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { EditIcon } from '../../../../../assets/icons';
import { CustomCheckbox, InputTextArea } from '../../../../../components';
import { colors } from '../../../../../theme/colors';

interface ICompletionMessagePreview {
	setActiveStep: (value: number) => void;
	completionMessage?: string | null;
	isChecked?: boolean;
}

export const CompletionMessagePreview: React.FC<ICompletionMessagePreview> = ({
	setActiveStep,
	completionMessage,
	isChecked,
}) => {
	return (
		<Box padding="20px" borderRadius="10px" bg="white">
			<Flex align="center" columnGap="20px">
				<CustomCheckbox
					size="32px"
					isChecked={isChecked}
					onChange={() => setActiveStep(2)}
				/>
				<Text
					fontSize="20px"
					lineHeight="24px"
					fontWeight={700}
					color={colors.darkBlue}>
					Send a message to the user after the course is completed
				</Text>
				{isChecked ? (
					<IconButton
						w="32px"
						minW="32px"
						h="32px"
						p="6px"
						aria-label="Edit message"
						icon={<EditIcon fillColor={colors.lightGreen} />}
						onClick={() => setActiveStep(2)}
					/>
				) : null}
			</Flex>
			{isChecked && completionMessage?.length ? (
				<Box mt="10px">
					<InputTextArea
						label=""
						value={completionMessage || ''}
						placeholder={'Message'}
						resize="none"
						isReadOnly={true}
					/>
				</Box>
			) : null}
		</Box>
	);
};
