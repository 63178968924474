import React, { useCallback } from 'react';
import { FieldWrapper } from './index';
import {
	Flex,
	Text,
	BoxProps,
	Button,
	Tooltip,
	VStack,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

interface IFileInput extends BoxProps {
	placeholder?: string;
	label: string;
	accept?: Record<string, string[]>;
	errorMsg?: string;
	onSetFile?: (file?: File) => void;
	onSetFiles?: (files?: File[]) => void;
	isMultiple?: boolean;
	isDisabled?: boolean;
	tooltipMsg?: string;
	typesPlaceholder?: string;
}
export const FileInput: React.FC<IFileInput> = ({
	placeholder = 'Drop the image...',
	label,
	accept,
	errorMsg,
	onSetFile,
	isMultiple = false,
	onSetFiles,
	isDisabled = false,
	tooltipMsg,
	typesPlaceholder,
	...rest
}) => {
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (isMultiple) {
			onSetFiles?.(acceptedFiles);
		} else {
			onSetFile?.(acceptedFiles[0]);
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		accept: accept,
		multiple: isMultiple,
		disabled: isDisabled,
	});

	const isError = !!errorMsg;

	const border = isError ? '1px solid red' : '2px dashed #BDCDE9';

	return (
		<FieldWrapper {...rest}>
			<Text
				fontWeight={'700'}
				fontSize={'xl'}
				mb="8px"
				color={'darkBlue'}>
				{label}
			</Text>
			<Tooltip
				isDisabled={!isDisabled}
				label={tooltipMsg}
				aria-label={'a tooltip'}>
				<Flex
					mb={'6px'}
					justify={'center'}
					align={'center'}
					height={'70px'}
					border={border}
					borderRadius={'10px'}
					bg={
						isDragActive ? 'rgba(204, 213, 227, 0.7)' : 'background'
					}
					{...getRootProps()}>
					<input {...getInputProps()} />
					<VStack spacing={'0px'}>
						<Text fontWeight={'500'} color={'#7E788F'}>
							{placeholder}
						</Text>
						{typesPlaceholder ? (
							<Text
								fontSize={'14px'}
								fontWeight={'500'}
								color={'#7E788F'}>
								{typesPlaceholder}
							</Text>
						) : null}
					</VStack>
				</Flex>
			</Tooltip>

			{isError ? (
				<Text fontSize={'sm'} color={'red.500'}>
					{errorMsg}
				</Text>
			) : null}
			<Button
				isDisabled={isDisabled}
				variant={'link'}
				onClick={open}
				fontWeight={'500'}
				color={'inactiveBlue'}>
				Select a file...
			</Button>
		</FieldWrapper>
	);
};
