import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const ItemWrapper: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<Box bg={'background'} borderRadius={'10px'} p={'20px'}>
			{children}
		</Box>
	);
};
