import { useMutation, useQueryClient } from 'react-query';
import { ApiResponse } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface Payload {
	authorId: number;
	formData: FormData;
}
export const useEditAuthor = () => {
	const queryClient = useQueryClient();
	const { isLoading, mutate } = useMutation<
		ApiResponse<number>,
		unknown,
		Payload
	>({
		mutationFn: payload =>
			Api.post(
				`/api/admin/courses/authors/${payload.authorId}`,
				payload.formData,
			),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			} else {
				queryClient.invalidateQueries('authors');
			}
		},
		onError: () => {
			Alerter.error('Error while editing author');
		},
	});

	return {
		isLoading,
		onEditAuthor: mutate,
	};
};
