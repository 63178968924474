import React from 'react';
import { EditIcon } from '../../../../../assets/icons';
import { Box, IconButton, Image, Skeleton, Text } from '@chakra-ui/react';
import { FieldWrapper } from '../../../components';

interface IProps {
	src?: string;
	setActiveStep: (value: number) => void;
}
export const PreviewCourseImage: React.FC<IProps> = ({
	src,
	setActiveStep,
}) => {
	return (
		<FieldWrapper>
			<Text fontWeight={'700'} color={'darkBlue'} fontSize={'xl'}>
				Preview Course image
			</Text>
			<Box display={'inline-block'} mt={'10px'} pos={'relative'}>
				<Image
					maxH={'220px'}
					borderRadius={'16px'}
					src={src}
					fallback={
						<Skeleton
							h={'220px'}
							borderRadius={'10px'}
							w={'400px'}
						/>
					}
				/>
				<IconButton
					pos={'absolute'}
					top={'20px'}
					right={'20px'}
					ml={'20px'}
					onClick={() => {
						setActiveStep(0);
					}}
					aria-label={'edit section'}
					icon={<EditIcon fillColor={'#08BB4B'} />}
				/>
			</Box>
		</FieldWrapper>
	);
};
