import React from 'react';
import { HStack, IconButton, Text } from '@chakra-ui/react';
import { CloseIcon } from '../../../../../assets/icons';
import { VerificationUser } from '../../types';

interface IUserItemProps {
	user: VerificationUser;
	removeUserFromSelected: (user: VerificationUser) => void;
}
export const UserItem: React.FC<IUserItemProps> = ({
	user,
	removeUserFromSelected,
}) => {
	const { email, communityStatus } = user;
	return (
		<HStack gap={0} px="10px" mb="5px">
			<Text fontSize="14px" fontWeight={600}>
				{email}
			</Text>
			<Text fontSize="12px" borderRadius="20px" p="10px" bg="dropBlue">
				{communityStatus ? 'Approved' : 'Rejected'}
			</Text>
			<IconButton
				onClick={() => removeUserFromSelected(user)}
				aria-label="remove"
				icon={<CloseIcon size="14" color="#F35050" />}
			/>
		</HStack>
	);
};
