import React from 'react';
import { Button, Tooltip, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { InputText } from '../../../../../../components';
import { greenButton } from '../../../../../../theme/elementStyles';
import { changePasswordResolver } from '../../../../../../validation';
import { useChangeUserPassword } from '../../../../hooks';

interface ChangePasswordFormProps {
	onClose: VoidFunction;
	userId: number;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
	onClose,
	userId,
}) => {
	const changeUserPasswordMutation = useChangeUserPassword();

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors, isValid },
	} = useForm<{ password: string }>({
		resolver: changePasswordResolver,
	});

	const onCloseAction = () => {
		onClose();
		reset();
	};

	const onSubmit = (data: { password: string }) => {
		const { password } = data;

		changeUserPasswordMutation.mutate(
			{
				userId,
				password,
			},
			{
				onSuccess: data => {
					if (!data?.success) {
						return;
					}
					onCloseAction();
				},
			},
		);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<VStack spacing="20px">
				<InputText
					{...register('password')}
					formControlProps={{
						px: '2px',
						pt: '2px',
					}}
					width="100%"
					h="56px"
					bg="white"
					placeholder="Enter password"
					borderRadius="8px"
					_focusVisible={{
						zIndex: 1,
						borderColor: 'transparent',
						boxShadow: '0 0 0 2px #08BB4B',
					}}
					errorMsg={errors.password?.message}
				/>
				<VStack w="100%" spacing="10px">
					<Tooltip
						label={
							isValid
								? undefined
								: 'Password must have at least one symbol, one uppercase, one lowercase, one digit and minimum 8 characters length'
						}
						padding="10px"
						bgColor="lightBlue"
						fontSize="12px"
						borderRadius="5px"
						placement="auto"
						hasArrow={true}>
						<Button
							{...greenButton}
							type="submit"
							h="40px"
							w="100%"
							borderRadius="8px"
							fontWeight={600}
							isLoading={changeUserPasswordMutation.isLoading}
							isDisabled={!isValid}
							_hover={{
								bg: 'greenButton.600',
								_disabled: {
									bg: 'lightGray100',
									color: 'lightGray200',
								},
							}}
							_disabled={{
								bg: 'lightGray100',
								color: 'lightGray200',
							}}>
							Set
						</Button>
					</Tooltip>
					<Button
						{...greenButton}
						variant="ghost"
						h="40px"
						w="100%"
						fontWeight={600}
						borderWidth="2px"
						borderColor="lightGreen"
						color="lightBlue"
						borderRadius="8px"
						onClick={onCloseAction}>
						Cancel
					</Button>
				</VStack>
			</VStack>
		</form>
	);
};
