import React, { PropsWithChildren } from 'react';
import { Center, Text, CenterProps } from '@chakra-ui/react';

interface IUnavailablePlaceholderProps extends PropsWithChildren {
	containerProps?: CenterProps;
}

export const UnavailablePlaceholder: React.FC<IUnavailablePlaceholderProps> = ({
	children,
	containerProps,
}) => (
	<Center bg="background" borderRadius="10px" h="120px" {...containerProps}>
		<Text color="lightGray" fontSize="14px" fontWeight={500}>
			{children}
		</Text>
	</Center>
);
