import React from 'react';

export const ActivityMarker: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="12" fill={color} />
		<path
			d="M17.3327 8L9.99935 15.3333L6.66602 12"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
