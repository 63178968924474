import React, { useEffect, useState } from 'react';
import { Courses } from './Courses';
import { useDisclosure } from '@chakra-ui/react';
import {
	DeleteCourseModal,
	PublishCourseModal,
	UnpublishCourseModal,
	UpdateHighlightCourseModal,
} from './components';
import {
	useCreateCourse,
	useGetCourses,
	useUpdateCoursePosition,
} from './queries';
import { CourseItemType } from './types';

const CoursesContainer: React.FC = () => {
	const [idToDeleteCourse, setIdToDeleteCourse] = useState(0);
	const [idToPublishCourse, setIdToPublishCourse] = useState(0);
	const [idToUnpublishedCourse, setIdToUnpublishedCourse] = useState(0);
	const [courseToUpdateHighlight, setCourseToUpdateHighlight] =
		useState<CourseItemType | null>(null);
	const [editing, setEditing] = useState(false);
	const [courseIdsOrder, setCourseIdsOrder] = useState<number[]>([]);

	const { isLoading: createLoading, onCreateCourse } = useCreateCourse();
	const { onUpdatePosition } = useUpdateCoursePosition();

	const { isLoading, courses } = useGetCourses();
	const [coursesDnd, setCoursesDnd] = useState<CourseItemType[]>([]);

	const {
		isOpen: isOpenDeleteModal,
		onClose: onCloseDeleteModal,
		onOpen: onOpenDeleteModal,
	} = useDisclosure();

	const {
		isOpen: isOpenPublishModal,
		onClose: onClosePublishModal,
		onOpen: onOpenPublishModal,
	} = useDisclosure();

	const {
		isOpen: isOpenUnpublishModal,
		onClose: onCloseUnpublishModal,
		onOpen: onOpenUnpublishModal,
	} = useDisclosure();

	useEffect(() => {
		if (courses) {
			setCoursesDnd(courses);

			const courseIds = courses.map(course => course.id);
			setCourseIdsOrder(courseIds);
		}
	}, [courses]);

	useEffect(() => {
		if (idToDeleteCourse) {
			onOpenDeleteModal();
		}
	}, [idToDeleteCourse]);

	useEffect(() => {
		if (idToPublishCourse) {
			onOpenPublishModal();
		}
	}, [idToPublishCourse]);

	useEffect(() => {
		if (idToUnpublishedCourse) {
			onOpenUnpublishModal();
		}
	}, [idToUnpublishedCourse]);

	const onHoverItem = (draggedItemId: number, newOrder: number) => {
		const newItems = [...coursesDnd];
		const draggedItem = newItems.find(item => item.id === draggedItemId);
		if (draggedItem) {
			newItems.splice(
				newOrder,
				0,
				newItems.splice(newItems.indexOf(draggedItem), 1)[0],
			);
			setCoursesDnd(newItems);
		}
	};

	const onDropItem = (draggedItemId: number, newOrder: number) => {
		if (courseIdsOrder[newOrder] === draggedItemId) return;

		const courseIds = coursesDnd.map(item => item.id);
		setCourseIdsOrder(courseIds);

		onUpdatePosition(courseIds);
	};

	const onEditToggle = () => setEditing(prev => !prev);

	return (
		<>
			<Courses
				courses={coursesDnd}
				isLoading={isLoading}
				createLoading={createLoading}
				onCreateCourse={onCreateCourse}
				setIdToDeleteCourse={setIdToDeleteCourse}
				setIdToPublishCourse={setIdToPublishCourse}
				setIdToUnpublishedCourse={setIdToUnpublishedCourse}
				setCourseToUpdateHighlight={setCourseToUpdateHighlight}
				onEditToggle={onEditToggle}
				onHoverItem={onHoverItem}
				onDropItem={onDropItem}
				isEditing={editing}
			/>
			<DeleteCourseModal
				id={idToDeleteCourse}
				isOpen={isOpenDeleteModal}
				onClose={() => {
					onCloseDeleteModal();
					setIdToDeleteCourse(0);
				}}
			/>
			<PublishCourseModal
				id={idToPublishCourse}
				isOpen={isOpenPublishModal}
				onClose={() => {
					onClosePublishModal();
					setIdToPublishCourse(0);
				}}
			/>
			<UnpublishCourseModal
				isOpen={isOpenUnpublishModal}
				onClose={() => {
					onCloseUnpublishModal();
					setIdToUnpublishedCourse(0);
				}}
				id={idToUnpublishedCourse}
			/>
			<UpdateHighlightCourseModal
				isOpen={!!courseToUpdateHighlight}
				onClose={() => setCourseToUpdateHighlight(null)}
				data={courseToUpdateHighlight}
			/>
		</>
	);
};
export default CoursesContainer;
