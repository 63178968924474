import { extendTheme, theme as base } from '@chakra-ui/react';
import { colors } from './colors';
import { Progress, Text } from './components';

export const theme = extendTheme({
	colors,
	fonts: {
		body: `Inter, ${base.fonts.body}`,
	},
	components: {
		Progress,
		Text,
	},
});
