import React from 'react';
import {
	Avatar,
	Box,
	Center,
	CloseButton,
	Flex,
	IconButton,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';
import { LinkItems } from '../../../constants';
import { NavItem } from './NavItem';
import { LogoutIcon } from '../../../assets/icons';
import { useAuth } from 'oidc-react';
import { ICurrentUser } from '../../../types';
import { NavAccordion } from './NavAccordion';

interface IMenuContent {
	onClose: () => void;
	isLoading: boolean;
	data?: ICurrentUser;
}
export const MenuContent: React.FC<IMenuContent> = ({
	onClose,
	isLoading,
	data,
}) => {
	const fullName = `${data?.firstName} ${data?.lastName}`;

	const { signOut } = useAuth();
	return (
		<Flex
			direction="column"
			align="space-between"
			px={'20px'}
			py={8}
			bg="lightBlue"
			transition=".3s"
			w={{ base: 'full' }}
			overflowX="hidden"
			h="100dvh"
			pos="fixed">
			<Flex justifyContent="space-between" alignItems="center">
				<Flex alignItems={'center'}>
					<Text color="white" fontWeight={500} fontSize="25px">
						S8
					</Text>
					<Text
						mx={'6px'}
						borderRadius={'10px'}
						background={'lightGreen'}
						color="white"
						fontWeight={700}
						px={'10px'}
						py={'4px'}
						fontSize="25px">
						Pro
					</Text>
					<Text color="white" fontWeight={500} fontSize="25px">
						Admin
					</Text>
				</Flex>
				<CloseButton
					color="white"
					display={{ base: 'flex' }}
					onClick={onClose}
				/>
			</Flex>
			<VStack mt={'32px'} flexGrow={1}>
				{LinkItems.map(item => {
					if (item.text === 'Community') {
						return (
							<NavAccordion key={item.id} menuExpanded={true}>
								{item.innerLinks?.map(link => {
									return (
										<NavItem
											key={link.text}
											icon={link.icon}
											link={link.link}
											isAccordionItem={true}
											menuExpanded={true}>
											Verification
										</NavItem>
									);
								})}
							</NavAccordion>
						);
					}
					return (
						<NavItem
							key={item.text}
							icon={item.icon}
							link={item.link}
							menuExpanded={true}>
							{item.text}
						</NavItem>
					);
				})}
			</VStack>

			<Box>
				{isLoading ? (
					<Center>
						<Spinner size="lg" color="white" />
					</Center>
				) : (
					<Flex>
						<Avatar name={fullName} boxSize={'50px'} />
						<Box ml={'15px'}>
							<Text color="white" fontSize="16px">
								{fullName}
							</Text>
							<Text color="gray.400" fontSize="12px">
								{data?.email}
							</Text>
						</Box>

						<IconButton
							ml={'auto'}
							onClick={signOut}
							background={'none'}
							_hover={{
								background: 'blackAlpha.500',
							}}
							_active={{
								background: 'blackAlpha.500',
							}}
							aria-label={'sign-out'}
							icon={<LogoutIcon />}
						/>
					</Flex>
				)}
			</Box>
		</Flex>
	);
};
