import apiBase, { API_CHAT, API_IDENTITY } from '../../api/api.base';
import { Alerter } from '../../utils/Alerter';
import {
	CourseAnalyticsItem,
	TimelineFileItem,
	TimelineImageItem,
	TimelineItemType,
	TimelineTypeEnum,
	UnionTimelineAttachment,
	UserCommunity,
	UserDetails,
} from './types';
import { ApiResponse, PermissionGroup } from '../../types';
import { BillingTransaction } from './Tabs/Billing/helper';
import { TimelineFormValues } from '../../validation';
import { File } from 'node:buffer';
import dayjs from 'dayjs';

export const BREADCRUMBS_ITEMS_USER = [
	{
		label: 'Home',
		route: '/',
	},
	{
		label: 'Kamilla Harris',
		route: null,
	},
];

export const fetchUserById = async (id: number): Promise<UserDetails> => {
	if (!id) {
		Alerter.error('Invalid user ID');
	}
	const response: ApiResponse<UserDetails> = await API_IDENTITY.get(
		`/api/admin/users/${id}`,
		{},
	);

	if (response.success) {
		const userTransactionsAndCoursesResponse: ApiResponse<{
			nmiTransactions: BillingTransaction[];
			courseAnalytics: CourseAnalyticsItem[];
		}> = await apiBase.get(`/api/admin/users/${response.value.id}`, {
			Email: response.value.email,
		});

		if (userTransactionsAndCoursesResponse.success) {
			response.value.courseAnalytics =
				userTransactionsAndCoursesResponse.value.courseAnalytics;
			response.value.nmiTransactions =
				userTransactionsAndCoursesResponse.value.nmiTransactions;
			return response.value;
		}
	}
	return response.value;
};

export const modifyBreadcrumbs = (
	crumbs: typeof BREADCRUMBS_ITEMS_USER,
	username: string,
) => {
	const crumbsCopy = [...crumbs];
	crumbsCopy[1].label = username;
	return crumbsCopy;
};

export const filterCurrentPermissions = (
	currentGroupId: number,
	permissions: PermissionGroup[],
) => {
	return permissions.filter(group => group.id === currentGroupId);
};

const getValueName = (type: TimelineTypeEnum) => {
	switch (type) {
		case TimelineTypeEnum.Call:
			return 'CallMessage';
		case TimelineTypeEnum.Email:
			return 'EmailMessage';
		case TimelineTypeEnum.DirectMessage:
			return 'DirectMessage';
		case TimelineTypeEnum.Note:
			return 'NoteMessage';
		default:
			return '';
	}
};

export const getTimelinePayload = (
	values: TimelineFormValues,
	userId: number,
	file: any,
	timelineId?: number | null,
) => {
	const formData = new FormData();
	const { type, date, timeFrom, timeTo, imageIdsToDelete, fileIdsToDelete } =
		values;

	if (file) {
		if (file.type === 'application/pdf') {
			formData.append('Files', file);
		} else {
			formData.append('Images', file);
		}
	}

	if (imageIdsToDelete.length) {
		values.imageIdsToDelete.forEach(it => {
			formData.append('ImageIdsToDelete', String(it));
		});
	}
	if (fileIdsToDelete.length) {
		values.fileIdsToDelete.forEach(it => {
			formData.append('FileIdsToDelete', String(it));
		});
	}
	formData.append('Type', String(values.type));

	if (!timelineId) {
		formData.append('UserId', String(userId));
	}

	if (values.type !== TimelineTypeEnum.Note) {
		if (date) {
			formData.append(
				`${getValueName(values.type as number)}.Date`,
				dayjs(date).format('YYYY-MM-DD'),
			);
			if (timeFrom?.value) {
				formData.append(
					`${getValueName(values.type as number)}.TimeFrom`,
					dayjs(`${date} ${values.timeFrom?.value}`).format('HH:mm'),
				);
			}
			if (timeTo?.value) {
				formData.append(
					`${getValueName(values.type as number)}.TimeTo`,
					dayjs(`${date} ${values.timeTo?.value}`).format('HH:mm'),
				);
			}
		}
	}
	if (type === TimelineTypeEnum.Call) {
		formData.append('CallMessage.Topic', values.topic);
		formData.append('CallMessage.Note', values.callNote);
	}
	if (type === TimelineTypeEnum.Email) {
		formData.append('EmailMessage.Email', values.email);
		formData.append('EmailMessage.Subject', values.subject);
		formData.append('EmailMessage.BodyText', values.bodyText);
	}
	if (type === TimelineTypeEnum.DirectMessage) {
		formData.append('DirectMessage.Message', values.message);
	}
	if (type === TimelineTypeEnum.Note) {
		formData.append('NoteMessage.Note', values.note);
	}

	return {
		payload: formData,
		userId,
		timelineId: timelineId || 0,
	};
};

export const mergeAndSortAttachments = (
	files: TimelineFileItem[],
	images: TimelineImageItem[],
): UnionTimelineAttachment[] => {
	const combinedArray: UnionTimelineAttachment[] = [...files, ...images];

	combinedArray.sort((a, b) => {
		const dateA = dayjs(a.date).valueOf();
		const dateB = dayjs(b.date).valueOf();
		return dateA - dateB;
	});

	return combinedArray;
};

const getTimeOptions = (value: string | null) => {
	return value
		? {
			value: dayjs(`${dayjs().format('YYYY-MM-DD')} ${value}`).format(
				'hh:mm A',
			),
			label: dayjs(`${dayjs().format('YYYY-MM-DD')} ${value}`).format(
				'hh:mm A',
			),
		  }
		: null;
};

export const getTimelineValues = (item: TimelineItemType) => {
	return {
		type: item.type,
		date: item?.date ? dayjs(item.date).format('YYYY-MM-DD') : '',
		timeFrom: getTimeOptions(item.timeFrom),
		timeTo: getTimeOptions(item.timeTo),
		topic: item.content?.topic || '',
		callNote: item.content?.note || '',
		note: item.content?.note || '',
		message: item.content?.message || '',
		email: item.content?.email || '',
		subject: item.content?.subject || '',
		bodyText: item.content?.bodyText || '',
		imageIdsToDelete: [],
		fileIdsToDelete: [],
	};
};
