import { API_CHAT, API_IDENTITY } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { Alerter } from '../../../utils/Alerter';
import { VerificationUser } from './types';

export const fetchVerificationUsers = async (
	currentPage: number,
	pageSize: number,
	orderId: number,
	searchByStatus: string,
	searchTerm: string,
): Promise<{ users: VerificationUser[]; totalCount: number }> => {
	const response = await API_IDENTITY.get('api/admin/community/users', {
		currentPage,
		pageSize,
		newestUsersFirst: orderId === 1,
		status: searchByStatus,
		searchString: searchTerm || undefined,
	});

	return response.value;
};

export const changeUserVerificationStatusRequest = async (
	userIds: number[],
	communityAllow: boolean,
) => {
	const response = await API_IDENTITY.post(
		'api/admin/users/change-community-statuses',
		{
			userIds,
			communityAllow,
		},
	);

	if (response.success) {
		Alerter.success('Status updated');
	} else {
		Alerter.error('Error status updating');
	}
	return response;
};

export const updateUserChannelsBanStatusRequest = async (
	userId: number,
	isBanned: boolean,
) => {
	const response = await API_IDENTITY.patch('api/admin/users/ban-channels', {
		userId,
		isBanned,
		deleteMessage: false,
	});

	return response;
};

export const checkCommunityUserExistence = async (
	userId: number,
): Promise<ApiResponse<boolean>> => {
	const response: ApiResponse<boolean> = await API_CHAT.get(
		`/api/admin/user/${userId}/config-exist`,
		{},
	);
	return response;
};
