import { Controller, useFormContext } from 'react-hook-form';
import { TimelineFormValues } from '../../../../../../../validation';
import { CustomSelect, DatePicker } from '../../../../../../../components';
import { DatePickerTrigger } from './DatePickerTrigger';
import { HStack } from '@chakra-ui/react';
import { timeOptions } from '../../../../../constants';
import React from 'react';

export const TimelineDateTime: React.FC = () => {
	const { control, watch } = useFormContext<TimelineFormValues>();

	const [date, timeFrom] = watch(['date', 'timeFrom']);
	return (
		<>
			<Controller
				render={({
					field: { value, onChange },
					fieldState: { error },
				}) => (
					<DatePicker
						onSetValue={onChange}
						errMsg={error?.message}
						customPopoverTrigger={<DatePickerTrigger />}
						value={value}
						placeholder={'Due date'}
					/>
				)}
				name={'date'}
				control={control}
			/>
			<HStack spacing={'10px'}>
				<Controller
					render={({
						field: { value, onChange },
						fieldState: { error },
					}) => (
						<CustomSelect
							isDisabled={!date}
							errMsg={error?.message}
							onChange={onChange}
							value={value}
							menuPosition={'fixed'}
							options={timeOptions}
							placeholder={'Time from'}
						/>
					)}
					name={'timeFrom'}
					control={control}
				/>
				<Controller
					render={({
						field: { value, onChange },
						fieldState: { error },
					}) => (
						<CustomSelect
							isDisabled={!date || !timeFrom}
							errMsg={error?.message}
							onChange={onChange}
							value={value}
							menuPosition={'fixed'}
							options={timeOptions}
							placeholder={'Time to'}
						/>
					)}
					name={'timeTo'}
					control={control}
				/>
			</HStack>
		</>
	);
};
