import { useState } from 'react';
import { Alerter } from '../../../../utils/Alerter';
import {
	checkCommunityUserExistence,
	updateUserChannelsBanStatusRequest,
} from '../helpers';

interface ReturnType {
	isLoading: boolean;
	onUpdateChannelsBanStatus: (
		userId: number,
		banned: boolean,
		onSuccess?: () => void,
		onError?: () => void,
	) => void;
}

export const useUpdateChannelsBanStatus = (refetch: () => void): ReturnType => {
	const [isLoading, setIsLoading] = useState(false);

	const onUpdateChannelsBanStatus = async (
		userId: number,
		banned: boolean,
		onSuccess?: () => void,
		onError?: () => void,
	) => {
		try {
			setIsLoading(true);

			const userExist = await checkCommunityUserExistence(userId);
			if (!userExist?.value) {
				onError?.();
				Alerter.error('The user does not exist in the community');
				return;
			}

			const result = await updateUserChannelsBanStatusRequest(
				userId,
				banned,
			);

			if (result.success) {
				refetch();
				onSuccess?.();
				Alerter.success(
					`User ${banned ? 'suspended' : 'unsuspended'} in channels`,
				);
			} else {
				onError?.();
				Alerter.error(
					result?.errors?.[0].message ||
						'Error while updating user channels suspend status',
				);
			}
		} catch (error) {
			console.log('Error while updating user channels suspend status');
		} finally {
			setIsLoading(false);
		}
	};

	return {
		isLoading,
		onUpdateChannelsBanStatus,
	};
};
