import React from 'react';
import { Box, Collapse, useDisclosure } from '@chakra-ui/react';

interface ICollapseWrapper {
	TopElement: React.FC<{ onOpen: () => void }>;
	BottomElement: React.FC<{ onClose: () => void }>;
	defaultIsOpen?: boolean;
}
export const CollapseWrapper: React.FC<ICollapseWrapper> = ({
	TopElement,
	BottomElement,
	defaultIsOpen,
}) => {
	const { onOpen, isOpen, onClose } = useDisclosure({
		defaultIsOpen,
	});
	return (
		<Box>
			<Collapse in={!isOpen}>
				<TopElement onOpen={onOpen} />
			</Collapse>
			<Collapse in={isOpen}>
				<BottomElement onClose={onClose} />
			</Collapse>
		</Box>
	);
};
