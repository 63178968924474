import React from 'react';
import { Th, Thead, Tr } from '@chakra-ui/react';
import { CustomCheckbox, RenderTh } from '../../../../../components';
import { colors } from '../../../../../theme/colors';

interface ITableHeaderProps {
	selectAllHandler: () => void;
	isAllSelected: boolean;
}
export const TableHeader: React.FC<ITableHeaderProps> = ({
	selectAllHandler,
	isAllSelected,
}) => (
	<Thead>
		<Tr>
			<Th
				bgColor={colors.dropBlue}
				borderRight="1px solid white"
				w="50px"
				maxW="50px"
				style={{ maxWidth: '50px' }}
				px="13px">
				<CustomCheckbox
					isChecked={isAllSelected}
					onChange={selectAllHandler}
				/>
			</Th>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Display Name"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="15%"
				color={colors.darkBlue}
				value="Sign In Date"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="30%"
				color={colors.darkBlue}
				value="Email"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="220px"
				color={colors.darkBlue}
				value="Status of the verification"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
				textTransform="none"
				textAlign="center"
			/>
			<RenderTh
				width="180px"
				color={colors.darkBlue}
				value="Channel Suspend"
				bgColor={colors.dropBlue}
				borderRightColor={colors.dropBlue}
				letterSpacing="0px"
				textTransform="none"
				textAlign="center"
			/>
		</Tr>
	</Thead>
);

{
	/* <RenderTh
				color={colors.darkBlue}
				value="Avatar"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				color={colors.darkBlue}
				value="Nickname"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/> */
}
