import { ModalCloseButton, ModalHeader, chakra } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { TimelineTypeEnum } from '../../../../../constants';

interface IViewHeader {
	type: number;
	name: string;
}

export const HeaderView: React.FC<IViewHeader> = ({ type, name }) => {
	const getType = useCallback(() => {
		switch (type) {
			case TimelineTypeEnum.Call:
				return 'call';
			case TimelineTypeEnum.Email:
				return 'email';
			case TimelineTypeEnum.Note:
				return 'note';
			case TimelineTypeEnum.DirectMessage:
				return 'DM';
			default:
				return '';
		}
	}, [type]);
	return (
		<>
			<ModalHeader
				px="20px"
				fontSize="20px"
				fontWeight="600"
				lineHeight="24px">
				{name}{' '}
				<chakra.span fontWeight={500} color={'lightText'}>
					added {getType()}
				</chakra.span>
			</ModalHeader>
			<ModalCloseButton color="lightBlue" bg={'background'} />
		</>
	);
};
