export const CircleArrowsIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M14.1665 4.27084C15.9343 5.55878 17.0832 7.64506 17.0832 9.99967C17.0832 13.9117 13.9119 17.083 9.99984 17.083H9.58317M5.83317 15.7285C4.06539 14.4406 2.9165 12.3543 2.9165 9.99967C2.9165 6.08766 6.08782 2.91634 9.99984 2.91634H10.4165M10.8332 18.6663L9.1665 16.9997L10.8332 15.333M9.1665 4.66634L10.8332 2.99967L9.1665 1.33301"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
