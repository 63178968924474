import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CheckIcon } from '../../../../../../assets/icons';
import { LessonItem } from '../../../../types';

export const Lesson: React.FC<LessonItem> = ({
	name,
	position,
	isCompleted,
}) => {
	return (
		<Flex justifyContent="flex-start" alignItems="center" mb="15px">
			<Flex
				justifyContent="center"
				alignItems="center"
				bg={isCompleted ? 'lightGreen' : 'background'}
				h="32px"
				w="32px"
				minW="32px"
				borderRadius="4px">
				<CheckIcon color={isCompleted ? 'white' : '#BDCDE9'} />
			</Flex>
			<Box ml="10px">
				<Text fontSize="14px">Lesson {position}</Text>
				<Text fontSize="16px" fontWeight={700}>
					{name}
				</Text>
			</Box>
		</Flex>
	);
};
