import React, { ReactNode, useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ButtonProps,
} from '@chakra-ui/react';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
	title?: string;
	acceptButtonProps?: ButtonProps;
	cancelButtonProps?: ButtonProps;
	acceptButtonText?: string;
	cancelButtonText?: string;
	acceptCallback: () => void;
	isCentered?: boolean;
	size?: string;
}

export const AlertDialogWrapper: React.FC<IProps> = ({
	isOpen,
	onClose,
	children,
	title,
	cancelButtonText = 'No',
	acceptButtonText = 'Yes',
	acceptButtonProps,
	cancelButtonProps,
	acceptCallback,
	isCentered = true,
	size = 'md',
}) => {
	const cancelRef = useRef(null);

	return (
		<AlertDialog
			isCentered={isCentered}
			size={size}
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent p={'10px 0px'}>
					{title ? (
						<AlertDialogHeader fontSize="18px" fontWeight={600}>
							{title}
						</AlertDialogHeader>
					) : null}

					<AlertDialogBody>{children}</AlertDialogBody>

					<AlertDialogFooter justifyContent={'center'}>
						<Button
							h={'59px'}
							w={'150px'}
							borderRadius={'10px'}
							color={'darkBlue'}
							variant={'outline'}
							border={'2px solid #10162C'}
							onClick={acceptCallback}
							{...acceptButtonProps}>
							{acceptButtonText}
						</Button>
						<Button
							ml={'20px'}
							h={'59px'}
							w={'150px'}
							colorScheme="darkButton"
							borderRadius={'10px'}
							ref={cancelRef}
							onClick={onClose}
							{...cancelButtonProps}>
							{cancelButtonText}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
