export const PrevArrowIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.6665 10L3.33317 10M3.33317 10L8.33317 15M3.33317 10L8.33317 5"
				stroke="#10162C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
