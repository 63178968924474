import { FC } from 'react';
import { TableColumnHeaderProps, Th } from '@chakra-ui/react';

interface RenderThProps extends TableColumnHeaderProps {
	value: string | number;
	width?: string;
}

export const RenderTh: FC<RenderThProps> = ({
	value,
	width = '16%',
	...props
}) => {
	return (
		<Th
			textTransform={'capitalize'}
			fontWeight={'600'}
			fontSize={'16px'}
			w={width}
			color="white"
			p={'16px 16px'}
			borderRight="1px solid white"
			{...props}>
			{value}
		</Th>
	);
};
