import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { AuthorItemType } from '../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useGetAuthors = () => {
	const { isFetching, data } = useQuery<
		ApiResponse<{
			authors: AuthorItemType[];
		}>
	>({
		queryKey: 'authors',
		queryFn: async () => Api.get('/api/admin/courses/authors', {}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while get authors');
		},
	});

	return {
		isLoading: isFetching,
		authors: data?.value.authors,
	};
};
