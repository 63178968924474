import React, { useEffect, useState } from 'react';
import { Box, Center, Spinner, VStack } from '@chakra-ui/react';
import {
	FieldWrapper,
	FileInput,
	NextButton,
	PreviewFileUpload,
} from '../../components';
import { InputText, InputTextArea } from '../../../../components';
import { useCourseContext } from '../../context';
import { useForm, Controller } from 'react-hook-form';
import { FirstStepFormValues, firstStepResolver } from '../../../../validation';
import {
	useEditFirstStep,
	useGetFirstStep,
	useUploadFile,
} from '../../queries';
import { allowImageMime } from '../../constants';

interface IProps {
	goToNext: VoidFunction;
}

export const FirstStepTab: React.FC<IProps> = ({ goToNext }) => {
	const { id: courseId, setCourseData } = useCourseContext();

	const [mainImgUrl, setMainImgUrl] = useState('');
	const [previewImgUrl, setPreviewImgUrl] = useState('');
	const [uploadProgressMain, setUploadProgressMain] = useState(0);
	const [uploadProgressPreview, setUploadProgressPreview] = useState(0);

	const { isLoading: isLoadingMain, mutate: uploadMainImg } = useUploadFile();
	const { isLoading: isLoadingPreview, mutate: uploadPreviewImg } =
		useUploadFile();

	const { data, isLoading } = useGetFirstStep(courseId);

	const { isLoading: editLoading, onEditFirstStep } = useEditFirstStep();

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
		setValue,
		clearErrors,
		watch,
	} = useForm<FirstStepFormValues>({
		resolver: firstStepResolver,
		defaultValues: {
			isMainImgUploaded: false,
			isPreviewImgUploaded: false,
			title: '',
			description: '',
		},
		values: {
			isMainImgUploaded: !!data?.imageLink,
			isPreviewImgUploaded: !!data?.previewImageLink,
			title: data?.title ? data.title : '',
			description: data?.description ? data.description : '',
		},
	});

	const mainImgFile = watch('mainCourseImage');
	const previewImgFile = watch('previewImage');
	const isPreviewImgUploaded = watch('isPreviewImgUploaded');
	const isMainImgUploaded = watch('isMainImgUploaded');

	const onUploadMainImg = async () => {
		try {
			if (mainImgFile && courseId) {
				const formData = new FormData();
				formData.append('Image', mainImgFile);

				uploadMainImg(
					{
						url: `/api/admin/courses/${courseId}/first-step/image`,
						data: formData,
						onUpload: event => {
							if (event.total) {
								setUploadProgressMain(
									Math.round(
										(100 * event.loaded) / event.total,
									),
								);
							}
						},
					},
					{
						onSuccess: data => {
							if (
								typeof data === 'string' &&
								data.includes('data: Completed')
							) {
								setValue('isMainImgUploaded', true, {
									shouldValidate: true,
								});
							}
						},
					},
				);
			}
		} catch (err) {
			console.log(err, 'ERR');
		}
	};

	const onUploadPreviewImg = async () => {
		try {
			if (previewImgFile && courseId) {
				const formData = new FormData();
				formData.append('Image', previewImgFile);

				uploadPreviewImg(
					{
						url: `/api/admin/courses/${courseId}/first-step/preview-image`,
						data: formData,
						onUpload: event => {
							if (event.total) {
								setUploadProgressPreview(
									Math.round(
										(100 * event.loaded) / event.total,
									),
								);
							}
						},
					},
					{
						onSuccess: data => {
							if (
								typeof data === 'string' &&
								data.includes('data: Completed')
							) {
								setValue('isPreviewImgUploaded', true, {
									shouldValidate: true,
								});
							}
						},
					},
				);
			}
		} catch (err) {
			console.log(err, 'ERR');
		}
	};

	const onSubmit = (values: FirstStepFormValues) => {
		const { title, description } = values;

		setCourseData({
			title,
			description,
		});
		if (isDirty) {
			const formData = new FormData();

			formData.append('Title', values.title);
			formData.append('Description', values.description);

			onEditFirstStep(
				{
					courseId,
					formData,
				},
				{
					onSuccess: data => {
						if (data.success) {
							goToNext();
						}
					},
				},
			);
		} else {
			goToNext();
		}
	};

	const onRemoveMainImg = () => {
		setValue('mainCourseImage', undefined, { shouldValidate: true });
		setValue('isMainImgUploaded', false, { shouldValidate: true });
		URL.revokeObjectURL(mainImgUrl);
		setMainImgUrl('');
		setUploadProgressMain(0);
	};

	const onRemovePreviewImg = () => {
		setValue('previewImage', undefined, { shouldValidate: true });
		setValue('isPreviewImgUploaded', false, { shouldValidate: true });
		URL.revokeObjectURL(previewImgUrl);
		setPreviewImgUrl('');
		setUploadProgressPreview(0);
	};

	useEffect(() => {
		if (mainImgFile && !errors.mainCourseImage?.message) {
			setMainImgUrl(URL.createObjectURL(mainImgFile));
			onUploadMainImg();
		}
	}, [mainImgFile, errors.mainCourseImage]);

	useEffect(() => {
		if (previewImgFile && !errors.previewImage?.message) {
			setPreviewImgUrl(URL.createObjectURL(previewImgFile));
			onUploadPreviewImg();
		}
	}, [previewImgFile, errors.previewImage]);

	useEffect(() => {
		if (data?.imageLink) {
			setMainImgUrl(data.imageLink);
		}
		if (data?.previewImageLink) {
			setPreviewImgUrl(data.previewImageLink);
		}
	}, [data]);

	return (
		<Box>
			{isLoading ? (
				<Center height={'60vh'}>
					<Spinner size={'xl'} />
				</Center>
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<VStack align={'stretch'} spacing={'20px'}>
						<FieldWrapper>
							<InputText
								label={'Course Title'}
								placeholder={'Title'}
								{...register('title')}
								errorMsg={errors.title?.message}
							/>
						</FieldWrapper>
						<FieldWrapper>
							<InputTextArea
								label={'Course Description'}
								placeholder={'Description'}
								{...register('description')}
								errorMsg={errors.description?.message}
							/>
						</FieldWrapper>
						{mainImgUrl ? (
							<PreviewFileUpload
								isUploaded={isMainImgUploaded}
								// errMsg={errors.isMainImgUploaded?.message}
								isLoading={isLoadingMain}
								fileName={mainImgFile?.name}
								uploadProgress={uploadProgressMain}
								// onUploadFile={onUploadMainImg}
								onRemove={onRemoveMainImg}
								src={mainImgUrl}
								label={'Main Course image upload'}
							/>
						) : (
							<Controller
								render={({ fieldState }) => {
									return (
										<FileInput
											typesPlaceholder={
												'(Only .png .bmp .jpeg .jpg .gif images will be accepted)'
											}
											accept={allowImageMime}
											onSetFile={(value?: File) => {
												setValue(
													'mainCourseImage',
													value,
													{
														shouldValidate: true,
													},
												);
												clearErrors('mainCourseImage');
											}}
											errorMsg={fieldState.error?.message}
											label={'Main Course image upload'}
										/>
									);
								}}
								name={'mainCourseImage'}
								control={control}
							/>
						)}
						{previewImgUrl ? (
							<PreviewFileUpload
								isUploaded={isPreviewImgUploaded}
								// errMsg={errors.isPreviewImgUploaded?.message}
								// onUploadFile={onUploadPreviewImg}
								fileName={previewImgFile?.name}
								uploadProgress={uploadProgressPreview}
								isLoading={isLoadingPreview}
								src={previewImgUrl}
								onRemove={onRemovePreviewImg}
								label={'Preview Course image upload'}
							/>
						) : (
							<Controller
								render={({ fieldState }) => (
									<FileInput
										typesPlaceholder={
											'(Only .png .bmp .jpeg .jpg .gif images will be accepted)'
										}
										accept={allowImageMime}
										errorMsg={fieldState.error?.message}
										onSetFile={(value?: File) => {
											setValue('previewImage', value, {
												shouldValidate: true,
											});
											clearErrors('previewImage');
										}}
										label={'Preview Course image upload'}
									/>
								)}
								name={'previewImage'}
								control={control}
							/>
						)}

						<NextButton
							isLoading={editLoading}
							isDisabled={editLoading}
							type={'submit'}
						/>
					</VStack>
				</form>
			)}
		</Box>
	);
};
