import React from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { EditIcon } from '../../../../../assets/icons';
import { FieldWrapper } from '../../../components';

interface ICollapseHeader {
	title: string;
	onOpen?: () => void;
	name: string;
	variant?: 'section' | 'lesson';
}
export const CollapseHeader: React.FC<ICollapseHeader> = ({
	title,
	onOpen,
	name,
	variant = 'section',
}) => {
	return (
		<Box
			bg={variant === 'section' ? 'white' : 'background'}
			p={'20px'}
			borderRadius={'10px'}>
			<Flex align={'center'}>
				<Text fontWeight={'700'} fontSize={'xl'} color={'lightBlue'}>
					{title}
				</Text>
				<IconButton
					bg={variant === 'section' ? 'gray.100' : 'white'}
					onClick={onOpen}
					ml={'20px'}
					aria-label={'Edit'}
					icon={<EditIcon fillColor={'#08BB4B'} />}
				/>
			</Flex>
			<FieldWrapper
				mt={'10px'}
				bg={variant === 'section' ? 'background' : 'white'}>
				<Text fontWeight={'500'}>{name}</Text>
			</FieldWrapper>
		</Box>
	);
};
