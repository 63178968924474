import React from 'react';
import { SectionWrapper } from '../MainInfo/components/SectionWrapper';
import {
	Avatar,
	Box,
	Divider,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Text,
	Thead,
	Tr,
	VStack,
} from '@chakra-ui/react';
import { RenderTd, RenderTh } from '../../../../components';
import { UserCommunity } from '../../types';
import { labelTextStyles, textStyles } from '../../constants';

interface CommunityProps {
	userEmail?: string;
	usersCommunityData?: UserCommunity;
}

export const Community: React.FC<CommunityProps> = ({
	userEmail,
	usersCommunityData,
}) => {
	return (
		<Box maxW={'720px'}>
			<SectionWrapper
				borderRadius={'8px'}
				borderBottomLeftRadius={'0'}
				borderBottomRightRadius={'0'}>
				<Avatar
					boxSize={'100px'}
					src={usersCommunityData?.avatarUrl || ''}
				/>
				<VStack alignItems={'start'} mt={'15px'}>
					<Flex gap={'10px'}>
						<Text {...labelTextStyles}>Nickname:</Text>
						<Text {...textStyles}>
							{usersCommunityData?.nickName
								? usersCommunityData.nickName
								: '-'}
						</Text>
					</Flex>
					<Flex gap={'10px'}>
						<Text {...labelTextStyles}>Display Name:</Text>
						<Text {...textStyles}>
							{usersCommunityData?.displayName
								? usersCommunityData.displayName
								: '-'}
						</Text>
					</Flex>
					<Flex gap={'10px'}>
						<Text {...labelTextStyles}>Email:</Text>
						<Text {...textStyles}>
							{userEmail ? userEmail : '-'}
						</Text>
					</Flex>
					<Flex gap={'10px'} flexDirection={'column'}>
						<Text {...labelTextStyles}>About:</Text>
						<Text
							whiteSpace={'pre-wrap'}
							{...textStyles}
							fontWeight={'500'}>
							{usersCommunityData?.about
								? usersCommunityData.about
								: '-'}
						</Text>
					</Flex>
				</VStack>
			</SectionWrapper>
			<Divider />
			<SectionWrapper
				borderRadius={'8px'}
				borderTopLeftRadius={'0'}
				borderTopRightRadius={'0'}>
				<Text color={'darkBlue'} fontWeight={'700'} fontSize={'20px'}>
					Activity Data
				</Text>
				<TableContainer
					mt={'12px'}
					maxW={'285px'}
					borderRadius={'10px'}
					border={'1px solid #1C2959'}>
					<Table variant="simple" size={'sm'}>
						<Thead>
							<Tr bg={'lightBlue'}>
								<RenderTh
									borderRightColor={'#1C2959'}
									value={'Messages In The Community'}
								/>
							</Tr>
						</Thead>
						<Tbody bg={'#FFFFFF'}>
							<Tr>
								<RenderTd
									textAlign={'center'}
									value={String(
										usersCommunityData?.nickName
											? usersCommunityData?.channelMessagesCount
											: 'N/A',
									)}
								/>
							</Tr>
						</Tbody>
					</Table>
				</TableContainer>
			</SectionWrapper>
		</Box>
	);
};
