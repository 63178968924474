/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
	Box,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Button,
	Flex,
	Center,
	Spinner,
	Select,
	Input,
	InputGroup,
	InputLeftElement,
	HStack,
	IconButton,
	Tooltip,
} from '@chakra-ui/react';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
} from 'react-query';
import {
	CustomCheckbox,
	ExtendedPagination,
	RenderTd,
	RenderTh,
	RiskTag,
} from '../../components';
import { IUser, UserRiskLevelEnum } from './types';
import { InviteModal } from './modals';
import { usePermissionGroupsStore } from '../../store';
import { normalizeDateInUsFormat } from '../../utils/normalizeDateInUsFormat';
import { colors } from '../../theme/colors';
import { EditIcon, SearchIcon } from '../../assets/icons';
import { ReinviteUsersModal } from './modals/ReinviteUsers/ReinviteUsersModal';
import { toolTip } from '../../theme/elementStyles';
import { Link } from 'react-router-dom';
import {
	INVITATION_STATUS_OPTIONS,
	RISK_STATUS_OPTIONS,
	SEARCH_BY_OPTIONS,
} from './constants';

interface IUsersProps {
	pageSize: number;
	currentPage: number;
	fullName: string;
	email: string;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setPageSize: Dispatch<SetStateAction<number>>;
	setFullName: Dispatch<SetStateAction<string>>;
	setEmail: Dispatch<SetStateAction<string>>;
	users?: IUser[];
	totalCount?: number;
	isLoading: boolean;
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<any, unknown>>;
	userIdsForDeletion: number[];
	handleUserIdForDeletion: (id: number) => void;
	allUsersSelected: boolean;
	handleAllUsersForDeletion: () => void;
	onOpen: () => void;
	setPermissionId: Dispatch<SetStateAction<string | undefined>>;
	usersDataForDeletion: IUser[];
	setInviteStatus: Dispatch<SetStateAction<string | undefined>>;
	setUsersDataForDeletion: Dispatch<SetStateAction<IUser[]>>;
	setUserIdsForDeletion: Dispatch<SetStateAction<number[]>>;
	setRiskLevelId: Dispatch<SetStateAction<string | undefined>>;
}

export const Users: React.FC<IUsersProps> = ({
	pageSize,
	currentPage,
	fullName,
	setCurrentPage,
	setFullName,
	setPageSize,
	users,
	isLoading,
	refetch,
	totalCount,
	userIdsForDeletion,
	handleUserIdForDeletion,
	allUsersSelected,
	handleAllUsersForDeletion,
	onOpen,
	setEmail,
	email,
	setPermissionId,
	usersDataForDeletion,
	setInviteStatus,
	setUsersDataForDeletion,
	setUserIdsForDeletion,
	setRiskLevelId,
}) => {
	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [searchByEmail, setSearchByEmail] = useState(false);

	const { items } = usePermissionGroupsStore();

	//change status modal open/close state controller
	useEffect(() => {
		if (searchByEmail) {
			setFullName('');
		} else {
			setEmail('');
		}
	}, [searchByEmail]);

	return (
		<Box bg={colors.background} p={{ base: '10px', md: '20px' }}>
			<Flex
				direction={{ base: 'column', md: 'row' }}
				align="center"
				gap={'5px'}
				justifyContent="space-between">
				<InputGroup
					minW={{ base: '100%', md: '200px' }}
					maxW={{ base: '100%', md: '200px' }}>
					<InputLeftElement h="46px" alignItems="center">
						<SearchIcon />
					</InputLeftElement>
					<Input
						value={searchByEmail ? email : fullName}
						onChange={e => {
							if (searchByEmail) {
								setEmail(e.target.value);
							} else {
								setFullName(e.target.value);
							}
						}}
						height="46px"
						placeholder="Search"
						color={colors.darkBlue}
						fontWeight={500}
						bgColor="white"
						border={`1px solid ${colors.dropBlue}`}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						borderRadius="10px"
						_placeholder={{
							color: colors.lightGray,
						}}
					/>
				</InputGroup>
				<Flex
					w={{ base: '100%', md: '100%' }}
					p={{ base: '10px 0px 0px 0px', md: '10px' }}
					columnGap="15px">
					<Select
						maxW={{ base: '100%', md: '150px' }}
						onChange={e => {
							if (e.target.value === '1') {
								setSearchByEmail(false);
							} else {
								setSearchByEmail(true);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						{SEARCH_BY_OPTIONS.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>
					<Select
						maxW={{ base: '100%', md: '200px' }}
						defaultValue="0"
						onChange={e => {
							if (e.target.value === '0') {
								setPermissionId(undefined);
							} else {
								setPermissionId(e.target.value);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						<option value={'0'}>All Permissions</option>
						{items.map(elem => (
							<option key={elem.id} value={elem.id.toString()}>
								{elem.name}
							</option>
						))}
					</Select>
					<Select
						maxW={{ base: '100%', md: '220px' }}
						onChange={e => {
							if (e.target.value === '0') {
								setInviteStatus(undefined);
							} else {
								setInviteStatus(e.target.value);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						{INVITATION_STATUS_OPTIONS.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>
					<Select
						maxW={{ base: '100%', md: '220px' }}
						onChange={e => {
							if (e.target.value === '0') {
								setRiskLevelId(undefined);
							} else {
								setRiskLevelId(e.target.value);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						{RISK_STATUS_OPTIONS.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>
				</Flex>

				<HStack
					w={{ base: '100%', md: 'auto' }}
					flexGrow={'1'}
					mt={{ base: '10px', md: 0 }}>
					<Button
						hidden={!userIdsForDeletion.length}
						justifySelf={'end'}
						colorScheme="red"
						w={{ base: '100%', md: 'auto' }}
						onClick={onOpen}>
						Delete users
					</Button>
					<Box w={{ base: '100%', md: 'auto' }}>
						<Button
							justifySelf={'end'}
							colorScheme="lightBlueButton"
							w={{ base: '100%', md: 'auto' }}
							mt={{ base: '10px', md: 0 }}
							onClick={() => setInviteModalOpen(true)}>
							{!usersDataForDeletion.length
								? 'Invite user(s)'
								: 'Reinvite user(s)'}
						</Button>
					</Box>
				</HStack>
			</Flex>
			<Box>
				<TableContainer
					mt="20px"
					border="1px solid"
					borderColor={colors.dropBlue}
					borderRadius="10px"
					boxShadow="none">
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th
									bgColor={colors.dropBlue}
									borderRight="1px solid white"
									w="50px"
									px="13px">
									<CustomCheckbox
										isChecked={allUsersSelected}
										onChange={() =>
											handleAllUsersForDeletion()
										}
									/>
								</Th>
								{/* <RenderTh
									color={colors.darkBlue}
									value="Id"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/> */}
								<RenderTh
									color={colors.darkBlue}
									value="Email"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="First Name"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Last Name"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Invitation Sent Date"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Invitation Status"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Pandadoc Status"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Permission"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Risk Score"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
									textAlign="center"
									minW="114px"
									w="114px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Action"
									borderRightColor={colors.dropBlue}
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
							</Tr>
						</Thead>
						<Tbody>
							{isLoading ? (
								<Tr bg="white">
									<Td colSpan={10}>
										<Center>
											<Spinner />
										</Center>
									</Td>
								</Tr>
							) : (
								users?.map((user, index) => {
									const lastItem = users.length - 1 === index;
									const riskScore =
										user.riskLevel ===
										UserRiskLevelEnum.NONE
											? undefined
											: user.totalScore;
									return (
										<Tr key={index} bg="white">
											<Td
												w="50px"
												px="13px"
												py="9px"
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												borderRight={`1px solid ${colors.dropBlue}`}>
												<CustomCheckbox
													isChecked={userIdsForDeletion.includes(
														user.id,
													)}
													onChange={() =>
														handleUserIdForDeletion(
															user.id,
														)
													}
												/>
											</Td>
											<RenderTd
												value={user.email}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
												maxW="320px"
												isTruncated={true}
											/>
											<RenderTd
												value={user.firstName}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={user.lastName}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={
													user.invitationDate
														? normalizeDateInUsFormat(
																user.invitationDate,
														  )
														: '-'
												}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={user.status}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={
													user.isAgreementSigned
														? 'Yes'
														: 'No'
												}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>

											<RenderTd
												value={
													items.find(
														elem =>
															elem.id ===
															user.permissionId,
													)?.name || '-'
												}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<Td
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												borderRight="1px solid"
												borderRightColor={
													colors.dropBlue
												}
												p={0}>
												<Center>
													<RiskTag
														score={riskScore}
													/>
												</Center>
											</Td>
											<Td
												py="9px"
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}>
												<Flex
													gap="5px"
													justifyContent="center">
													<Tooltip
														{...toolTip}
														placement={'left-start'}
														label={'Edit user'}>
														<Link
															to={`/user/${user.id}`}>
															<IconButton
																aria-label="Edit user"
																bgColor="lightBlue"
																w="30px"
																minW="30px"
																h="30px"
																_hover={{}}
																_active={{}}
																_focusVisible={{}}>
																<EditIcon
																	width={20}
																	height={20}
																	fillColor="white"
																/>
															</IconButton>
														</Link>
													</Tooltip>
												</Flex>
											</Td>
										</Tr>
									);
								})
							)}
						</Tbody>
					</Table>
				</TableContainer>
				<ExtendedPagination
					currentPage={currentPage}
					gotoPage={val => setCurrentPage(val)}
					pageSize={pageSize}
					setPageSize={val => {
						setPageSize(val);
						setCurrentPage(1);
					}}
					totalCount={totalCount ? totalCount : 0}
					currentItemsLength={users?.length}
					perPageTextProps={{
						color: colors.lightGray,
						fontWeight: 500,
						fontSize: '16px',
					}}
					pagesTextProps={{
						color: colors.darkBlueTable[100],
						fontWeight: 500,
						fontSize: '16px',
					}}
					selectProps={{
						backgroundColor: 'white',
						color: colors.darkBlue,
						fontWeight: 500,
						border: 'none',
						boxShadow: '0px 0px 3px 0px rgba(139, 139, 139, 0.10)',
						minW: '74px',
						borderRadius: '10px',
						h: '46px',
					}}
					defaultPaginationProps={{
						mx: '0px',
						mt: '40px',
					}}
				/>
			</Box>
			<InviteModal
				isOpen={!usersDataForDeletion.length && inviteModalOpen}
				onClose={() => setInviteModalOpen(false)}
				refetch={refetch}
			/>
			<ReinviteUsersModal
				isOpen={usersDataForDeletion.length ? inviteModalOpen : false}
				onClose={() => setInviteModalOpen(false)}
				usersDataForDeletion={usersDataForDeletion}
				setUsersDataForDeletion={setUsersDataForDeletion}
				setUserIdsForDeletion={setUserIdsForDeletion}
			/>
		</Box>
	);
};
