export const CommunityIcon = () => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.2 12.7L5.43227 15.5023C5.04621 15.8932 4.85318 16.0887 4.68726 16.1024C4.54331 16.1144 4.40237 16.0565 4.30836 15.9469C4.2 15.8205 4.2 15.5458 4.2 14.9964V13.5925C4.2 13.0996 3.79637 12.7429 3.30868 12.6715V12.6715C2.12837 12.4986 1.2014 11.5716 1.02851 10.3913C1 10.1967 1 9.96446 1 9.5V5.32C1 3.80786 1 3.05179 1.29428 2.47423C1.55314 1.96619 1.96619 1.55314 2.47423 1.29428C3.05179 1 3.80786 1 5.32 1H11.98C13.4921 1 14.2482 1 14.8258 1.29428C15.3338 1.55314 15.7469 1.96619 16.0057 2.47423C16.3 3.05179 16.3 3.80786 16.3 5.32V9.1M16.3 19L14.3412 17.6382C14.0659 17.4468 13.9282 17.351 13.7784 17.2832C13.6454 17.223 13.5056 17.1791 13.362 17.1527C13.2003 17.1229 13.0326 17.1229 12.6972 17.1229H11.08C10.0719 17.1229 9.56786 17.1229 9.18282 16.9267C8.84413 16.7541 8.56876 16.4787 8.39619 16.14C8.2 15.755 8.2 15.251 8.2 14.2429V11.98C8.2 10.9719 8.2 10.4679 8.39619 10.0828C8.56876 9.74413 8.84413 9.46876 9.18282 9.29619C9.56786 9.1 10.0719 9.1 11.08 9.1H16.12C17.1281 9.1 17.6321 9.1 18.0172 9.29619C18.3559 9.46876 18.6312 9.74413 18.8038 10.0828C19 10.4679 19 10.9719 19 11.98V14.4229C19 15.2616 19 15.6809 18.863 16.0117C18.6803 16.4527 18.3299 16.8032 17.8888 16.9858C17.558 17.1229 17.1387 17.1229 16.3 17.1229V19Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
