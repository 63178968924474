export const HighlightIcon: React.FC<{
	color?: string;
	width?: string;
	height?: string;
}> = ({ width = '20px', height = '20px', color = 'white' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M17.256 4.85453L14.3102 1.91037C14.1549 1.7548 13.9443 1.66696 13.7245 1.66602C13.5047 1.66509 13.2934 1.75113 13.1368 1.90537L5.9135 9.0162C5.8009 9.12764 5.72205 9.2686 5.686 9.42287L4.83266 13.1204L3.3335 14.9995H5.69016L6.64183 14.0587L9.63183 13.3687C9.78183 13.3337 9.91933 13.2579 10.0293 13.1504L17.2518 6.03787C17.3302 5.96067 17.3925 5.86872 17.4352 5.76732C17.4779 5.66592 17.5 5.55708 17.5004 5.44707C17.5008 5.33706 17.4794 5.22806 17.4375 5.12636C17.3955 5.02466 17.3338 4.93228 17.256 4.85453ZM9.4485 11.382L7.681 9.61537L13.7168 3.67287L15.4835 5.43953L9.4485 11.382ZM3.3335 16.6662H16.6668V18.3329H3.3335V16.6662Z"
				fill={color}
			/>
		</svg>
	);
};
