/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { DatePicker } from '../../../components';
import { colors } from '../../../theme/colors';
import Select from 'react-select';
import { SearchIcon } from '../../../assets/icons';
import { useDebounce } from '../../../hooks/useDebounce';
import { useGetCardsNumbers } from '../hooks/useGetCardNumbers';
import { PickedCard } from './PickedCard';

interface IHeaderProps {
	date: string | null;
	setDate: (value: string | null) => void;
	// cardNumber: SelectSingleValue | null;
	// setCardNumber: (value: SelectSingleValue | null) => void;
	pickedCards: string[];
	addCard: (cards: string) => void;
	removeCard: (cardToRemove: string) => void;
}
export const Header: React.FC<IHeaderProps> = ({
	date,
	setDate,
	// cardNumber,
	// setCardNumber,
	pickedCards,
	addCard,
	removeCard,
}) => {
	const [search, setSearch] = useState('');

	const cardSearch = useDebounce(search, 500);
	const { data: cardsNumbers, isLoading } = useGetCardsNumbers(cardSearch);

	const cardsNumbersToAutocomplete = cardsNumbers?.cardDigits?.map(
		cardNumber => ({
			value: cardNumber,
			label: cardNumber,
		}),
	);

	return (
		<Box>
			<Flex
				direction={{ base: 'column-reverse', md: 'row' }}
				gap="10px"
				justifyContent="flex-start"
				alignItems={{ base: 'flex-start', md: 'center' }}>
				<Box w="270px">
					<Flex
						borderWidth={1}
						borderColor="dropBlue"
						borderRadius="10px">
						<Flex
							bg="white"
							pl="15px"
							borderLeftRadius="10px"
							justifyContent="center"
							alignItems="center">
							<SearchIcon />
						</Flex>
						<Box w="full">
							<Select
								filterOption={() => true}
								isSearchable={true}
								styles={{
									control: baseStyles => ({
										...baseStyles,
										width: '100%',
										height: '46px',
										borderBottomRightRadius: '10px',
										borderTopRightRadius: '10px',
										paddingRight: '10px',
										paddingLeft: '5px',
										border: 'none',
										boxShadow: 'none',
									}),
									menu: baseStyles => ({
										...baseStyles,
										borderRadius: '10px',
										padding: '8px',
										width: '270px',
										marginLeft: '-40px',
									}),
									option: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: '5px',
										background: state.isSelected
											? colors.lightBlue
											: 'transparent',
									}),
								}}
								components={{
									IndicatorSeparator: null,
									DropdownIndicator: null,
								}}
								isLoading={isLoading}
								inputValue={search}
								onInputChange={setSearch}
								value={null}
								onChange={option => {
									if (option) {
										addCard(option.value as string);
									}
								}}
								options={cardsNumbersToAutocomplete || []}
								placeholder="Last 4 Credit Card Digits"
							/>
						</Box>
					</Flex>
				</Box>
				<DatePicker value={date} onSetValue={setDate} />
			</Flex>
			<HStack mt="15px" flexWrap="wrap">
				{pickedCards.map((card: string, index: number) => {
					return (
						<PickedCard
							key={index}
							cardNumber={card}
							onRemove={removeCard}
						/>
					);
				})}
			</HStack>
		</Box>
	);
};
