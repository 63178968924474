import { useEffect, useState } from 'react';
import { queryClient } from '../../../../main';
import { changeUserVerificationStatusRequest } from '../helpers';

interface ReturnType {
	isLoading: boolean;
	onChangeVerificationStatus: (
		userIds: number[],
		communityAllow: boolean,
		onClose?: () => void,
	) => void;
}

export const useChangeVerificationStatus = (
	refetch: () => void,
	onSuccessCb: VoidFunction,
): ReturnType => {
	const [isLoading, setIsLoading] = useState(false);

	const onChangeVerificationStatus = async (
		userIds: number[],
		communityAllow: boolean,
		onClose?: () => void,
	) => {
		setIsLoading(true);
		const result = await changeUserVerificationStatusRequest(
			userIds,
			communityAllow,
		);

		if (result) {
			refetch();
			onClose?.();
			onSuccessCb();
		}
	};

	const isFetching = queryClient.isFetching('users-verification-list');

	useEffect(() => {
		isFetching && setIsLoading(false);
	}, [isFetching]);

	return {
		isLoading,
		onChangeVerificationStatus,
	};
};
