import { Box, Text } from '@chakra-ui/react';

interface IViewField {
	label: string;
	value: string | null;
}

export const FieldView: React.FC<IViewField> = ({ label, value }) => {
	return (
		<>
			{value ? (
				<Box>
					<Text variant={'bodyMedium'} color={'lightText'}>
						{label}
					</Text>
					<Text mt={'5px'}>{value}</Text>
				</Box>
			) : null}
		</>
	);
};
