import { InputTextArea } from '../../../../../../../components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TimelineFormValues } from '../../../../../../../validation';

export const NoteForm: React.FC = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext<TimelineFormValues>();

	return (
		<InputTextArea
			placeholder={'Enter note'}
			{...register('note')}
			isInvalid={!!errors.note?.message}
		/>
	);
};
