import { useQuery } from 'react-query';
import { ApiResponse, PermissionGroup } from '../../../types';
import { usePermissionGroupsStore } from '../../../store';
import { API_IDENTITY } from '../../../api/api.base';

export const useFetchPermissionGroups = () => {
	const { setItems } = usePermissionGroupsStore();
	return useQuery<ApiResponse<{ groups: PermissionGroup[] }>>(
		'permission-groups',
		() => API_IDENTITY.get('/api/admin/permissions', {}),
		{
			onSuccess(data) {
				if (data.success) {
					setItems(data.value.groups);
				}
			},
		},
	);
};
