import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import API from '../../api/api.base';
import { queryClient } from '../../main';
import { Logs } from './Logs';

const fetchLogs = async (
	currentPage: number,
	pageSize: number,
	link: string,
) => {
	const response = await API.get(
		`${link}?CurrentPage=${currentPage}&PageSize=${pageSize}`,
		{},
	);

	return response.value;
};

const fetchDeleteLogs = async (url: string) => {
	return await API.delete(`${url}`);
};

export const LogsContainer = () => {
	const [pageSize, setPageSize] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [link, setLink] = useState<string>('/api/admin/logging/info');
	const { isLoading, data, refetch } = useQuery(
		['logs', currentPage, pageSize],
		() => fetchLogs(currentPage, pageSize, link),
	);

	// state for controlling button state whether it is checked or on
	const [currentMenuTab, setCurrentMenuTab] = useState<number>(0);

	useEffect(() => {
		if (currentMenuTab) {
			// in other words, if user on errors tab, that condition works
			setLink('/api/admin/logging/errors');
		} else {
			setLink('/api/admin/logging/info');
		}
	}, [currentMenuTab]);

	// useEffect(() => {
	// 	console.log(data);
	// 	//console.log(Math.ceil(9 / pageSize))
	// }, [data]);

	useEffect(() => {
		queryClient.removeQueries('logs');
		refetch();
	}, [currentPage, pageSize, link]);

	const deleteLogs = async (url: string) => {
		const result = await fetchDeleteLogs(url);
		refetch();
		return result;
	};

	return (
		<Logs
			deleteLogs={deleteLogs}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			pageSize={pageSize}
			setPageSize={setPageSize}
			currentMenuTab={currentMenuTab}
			setCurrentMenuTab={setCurrentMenuTab}
			data={data}
			isLoading={isLoading}
		/>
	);
};
