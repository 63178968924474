import React from 'react';
import { LessonItemType } from '../../../types';
import { LessonForm } from './LessonForm';
import { CollapseWrapper } from './CollapseWrapper';
import { CollapseHeader } from './CollapseHeader';

interface ILessonItem {
	countName: string;
	item: LessonItemType;
	sectionId: number;
	courseId: number;
}
export const LessonItem: React.FC<ILessonItem> = ({
	countName,
	item,
	sectionId,
	courseId,
}) => {
	return (
		<CollapseWrapper
			defaultIsOpen={!item.name}
			TopElement={props => (
				<CollapseHeader
					variant={'lesson'}
					title={countName}
					name={item.name || 'Draft Lesson'}
					{...props}
				/>
			)}
			BottomElement={props => (
				<LessonForm
					courseId={courseId}
					item={item}
					sectionId={sectionId}
					countName={countName}
					{...props}
				/>
			)}
		/>
	);
};
