import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationError, InferType } from 'yup';
import { formatFileSize } from '../utils';

const createAuthorSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	description: yup.string().required(ErrorMessages.REQUIRED),
	image: yup.mixed<File>().when('isImageUploaded', {
		is: true,
		then: schema => schema.notRequired(),
		otherwise: schema =>
			schema
				.test('required', ErrorMessages.REQUIRED, data => {
					return !!data;
				})
				.test(function (data) {
					const size = data?.size;
					if (size && size > 5e6) {
						return new ValidationError(
							`5 MB max, but you have ${formatFileSize(size)}`,
							undefined,
							'image',
						);
					}
					return true;
				}),
	}),
	isImageUploaded: yup.bool().oneOf([true]).notRequired(),
});

export type CreateAuthorForm = InferType<typeof createAuthorSchema>;

export const createAuthorResolver = yupResolver(createAuthorSchema);
