import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Users } from './Users';
import { filterUniqueObjects, mainCheckboxStatus } from './helper';
import { IUser } from './types';
import { DeleteUsersModal } from './modals';
import { useGetUsers } from './hooks';
import { filterInvitationPendingStatus } from '../../utils/filterInvitationPendingStatus';

export const UsersContainer: React.FC = () => {
	// ============================== FETCHING USERS ==========================
	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [permissionId, setPermissionId] = useState<string | undefined>();
	const [inviteStatus, setInviteStatus] = useState<string | undefined>();
	const [riskLevelId, setRiskLevelId] = useState<string | undefined>();

	const { refetch, isLoading, data } = useGetUsers(
		fullName,
		email,
		pageSize,
		currentPage,
		permissionId,
		inviteStatus,
		riskLevelId,
	);

	// const [id, setId] = useState<number>(0);

	// =========================== DELETING USERS =====================================
	const [userIdsForDeletion, setUserIdsForDeletion] = useState<number[]>([]);
	const [usersDataForDeletion, setUsersDataForDeletion] = useState<IUser[]>(
		[],
	);

	const [allUsersSelected, setAllUsersSelected] = useState<boolean>(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const handleUserIdForDeletion = (id: number) => {
		if (allUsersSelected) {
			setAllUsersSelected(false);
		}
		const [...tempArray] = userIdsForDeletion;
		const [...tempArray2] = usersDataForDeletion;
		if (userIdsForDeletion.includes(id)) {
			const index = tempArray.indexOf(id);
			tempArray.splice(index, 1);
			setUserIdsForDeletion(tempArray);
			if (data?.users) {
				for (let i = 0; i < tempArray2.length; i++) {
					if (tempArray2[i].id === id) {
						tempArray2.splice(i, 1);
						break;
					}
				}
				setUsersDataForDeletion(tempArray2);
			}
		} else {
			tempArray.push(id);
			setUserIdsForDeletion(tempArray);
			if (data?.users) {
				tempArray2.push(data?.users.filter(item => item.id === id)[0]);
				setUsersDataForDeletion(tempArray2);
			}
		}
	};

	const handleAllUsersForDeletion = () => {
		if (data?.users) {
			const [...tempUsersList]: IUser[] = data.users;
			const [...tempArray] = userIdsForDeletion;
			const [...tempArray2] = usersDataForDeletion;

			if (allUsersSelected) {
				for (let i = 0; i < tempUsersList.length; i++) {
					if (userIdsForDeletion.includes(tempUsersList[i].id)) {
						// ??? why 0
						const index = tempArray.indexOf(0);
						tempArray.splice(index, 1);
						tempArray2.splice(index, 1);
					}
				}
				setUserIdsForDeletion(tempArray);
				setUsersDataForDeletion(tempArray2);
				setAllUsersSelected(false);
			} else {
				for (let i = 0; i < tempUsersList.length; i++) {
					tempArray.push(tempUsersList[i].id);
					if (data?.users) {
						tempArray2.push(data?.users[i]);
					}
				}
				setAllUsersSelected(true);
				setUserIdsForDeletion([...new Set(tempArray)]);
				setUsersDataForDeletion([...filterUniqueObjects(tempArray2)]);
			}
		}
	};

	/*useEffect(() => {
        console.log(userIdsForDeletion)
        console.log(usersDataForDeletion)
    }, [userIdsForDeletion])*/

	// KEEPING CHECKBOX WHICH IS RESPONSIBLE
	// FOR SELECTING ALL USERS UP TO DATE
	useEffect(() => {
		if (data?.users) {
			mainCheckboxStatus(
				userIdsForDeletion,
				data.users,
				allUsersSelected,
				setAllUsersSelected,
			);
		}
	}, [data?.users, userIdsForDeletion]);

	// FETCHING USERS ON PAGE CHANGE
	useEffect(() => {
		/*queryClient.removeQueries('usersList')*/
		refetch();
	}, [currentPage, pageSize, fullName, email, permissionId, inviteStatus]);

	useEffect(() => {
		setCurrentPage(1);
	}, [fullName, email, permissionId, inviteStatus, riskLevelId]);

	return (
		<>
			<Users
				setInviteStatus={setInviteStatus}
				totalCount={data?.totalCount}
				refetch={refetch}
				isLoading={isLoading}
				users={data?.users}
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				setPageSize={setPageSize}
				pageSize={pageSize}
				setFullName={setFullName}
				fullName={fullName}
				email={email}
				setEmail={setEmail}
				userIdsForDeletion={userIdsForDeletion}
				handleUserIdForDeletion={handleUserIdForDeletion}
				allUsersSelected={allUsersSelected}
				handleAllUsersForDeletion={handleAllUsersForDeletion}
				onOpen={onOpen}
				setPermissionId={setPermissionId}
				usersDataForDeletion={filterInvitationPendingStatus(
					usersDataForDeletion,
				)}
				setUsersDataForDeletion={setUsersDataForDeletion}
				setUserIdsForDeletion={setUserIdsForDeletion}
				setRiskLevelId={setRiskLevelId}
			/>
			<DeleteUsersModal
				setUserIdsForDeletion={setUserIdsForDeletion}
				setUsersDataForDeletion={setUsersDataForDeletion}
				userIdsForDeletion={userIdsForDeletion}
				refetch={refetch}
				usersDataForDeletion={usersDataForDeletion}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	);
};
