import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	Tabs,
	TabList,
	TabPanels,
	TabPanel,
} from '@chakra-ui/react';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
} from 'react-query';
import { InviteMultipleUsers, InviteSingleUserTab } from './tabs';
import { CustomTab } from './components';

interface IInviteModalProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<any, unknown>>;
}

export const InviteModal: React.FC<IInviteModalProps> = ({
	isOpen,
	onClose,
	refetch,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader pb={0}>Invite</ModalHeader>
				<ModalCloseButton />
				<Tabs>
					<TabList
						justifyContent="center"
						alignItems="center"
						border="none">
						<CustomTab>One user</CustomTab>
						<CustomTab>Multiple users</CustomTab>
					</TabList>
					<TabPanels>
						<TabPanel p="0" pt="20px">
							<InviteSingleUserTab
								onClose={onClose}
								refetch={refetch}
							/>
						</TabPanel>
						<TabPanel p="0" pt="20px">
							<InviteMultipleUsers
								onClose={onClose}
								refetch={refetch}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</ModalContent>
		</Modal>
	);
};
