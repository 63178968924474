import { FC, ReactNode } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	Text,
} from '@chakra-ui/react';
import { CommunityIcon } from '../../../assets/icons';

interface NavAccordionProps {
	children?: ReactNode;
	menuExpanded: boolean;
}

export const NavAccordion: FC<NavAccordionProps> = ({
	children,
	menuExpanded,
}) => {
	const showIconOnly = menuExpanded ? 'initial' : 'none';
	return (
		<Accordion allowMultiple={true} width={menuExpanded ? '100%' : 'auto'}>
			<AccordionItem border={'none'} borderRadius={'10px'}>
				{({ isExpanded }) => (
					<>
						<h2>
							<AccordionButton
								borderRadius={`10px 10px ${
									isExpanded && menuExpanded
										? '0 0'
										: '10px 10px'
								}`}
								p={5}
								_hover={{ bg: 'blackAlpha.400' }}
								// bg={isExpanded ? 'blackAlpha.400' : 'lightBlue'}
								bg={
									menuExpanded
										? isExpanded
											? 'blackAlpha.400'
											: 'lightBlue'
										: 'lightBlue'
								}
								justifyContent={'center'}
								gap={'26px'}
								alignItems={'center'}>
								<CommunityIcon />
								<Flex
									display={showIconOnly}
									flex="1"
									textAlign="start"
									alignItems={'center'}
									pt={'4px'}
									transition={'.4s'}>
									<Text
										fontSize={'16px'}
										fontWeight={'700'}
										whiteSpace="nowrap"
										color="white">
										Community
									</Text>
								</Flex>
								<AccordionIcon
									display={showIconOnly}
									bg="transparent"
									color="white"
									boxSize={7}
								/>
							</AccordionButton>
						</h2>
						<AccordionPanel
							borderRadius={'0 0 10px 10px'}
							bg={menuExpanded ? 'blackAlpha.400' : 'transparent'}
							px={menuExpanded ? 3 : 0}
							pb={3}>
							<Flex gap={'8px'} flexDirection={'column'}>
								{children}
							</Flex>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</Accordion>
	);
};
