import { ItemWrapper } from './ItemWrapper';
import { ItemHeader } from './itemHeader';
import { ItemContent } from './ItemContent';
import { useDisclosure } from '@chakra-ui/react';
import { TimelineViewModal } from '../TimelineViewModal';
import { TimelineItemType } from '../../../../../types';
import { useTimelineStore } from '../../../../../store';

interface ITimelineItem {
	item: TimelineItemType;
	currentUserId: number;
	userId: number;
}

export const TimelineItem: React.FC<ITimelineItem> = ({
	item,
	currentUserId,
	userId,
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const setTimelineId = useTimelineStore(state => state.setTimelineId);

	const attachmentCount = item.images.length + item.files.length;

	const isOwn = currentUserId === item.adminId;

	return (
		<>
			<ItemWrapper>
				<ItemHeader
					onEdit={() => {
						setTimelineId(item.id);
					}}
					timelineId={item.id}
					userId={userId}
					isOwn={isOwn}
					createdAt={item.createdAt}
					adminName={item.adminName}
					changedAt={item.changedAt}
					type={item.type}
					onOpenModal={onOpen}
				/>
				<ItemContent
					attachmentCount={attachmentCount}
					type={item.type}
					content={item.content}
					date={item.date}
					timeTo={item.timeTo}
					timeFrom={item.timeFrom}
				/>
			</ItemWrapper>
			<TimelineViewModal
				isOpen={isOpen}
				onClose={onClose}
				item={item}
				isOwn={isOwn}
			/>
		</>
	);
};
