import React, { useState } from 'react';
import { Box, Center, IconButton, Text } from '@chakra-ui/react';
import { CustomProgress } from '../../../../../components';
import { Section } from './Section/Section';
import { AnimatePresence, motion } from 'framer-motion';
import { ANIMATION_DURATION } from '../constants';
import { CourseAnalyticsItem, SectionItem } from '../../../types';
import { ChevronDown } from '../../../../../assets/icons';

export const CoursesItem: React.FC<CourseAnalyticsItem> = ({
	name,
	sections,
	progress,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const sortedSections = sections.sort(
		(a: SectionItem, b: SectionItem) => a.position - b.position,
	);

	return (
		<Box p="20px" borderRadius="10px" bg="white">
			<Text color="blueText" fontSize="18px" fontWeight={700}>
				{name}
			</Text>
			<CustomProgress
				mt="20px"
				mb="20px"
				progress={progress}
				showProgress={true}
			/>
			<AnimatePresence mode="wait">
				{isExpanded ? (
					<motion.div
						key="course_details"
						initial={{ height: 0 }}
						animate={{ height: 'auto' }}
						exit={{ height: 0 }}
						transition={{ duration: ANIMATION_DURATION }}
						style={{ overflow: 'hidden' }}>
						<Box>
							{sortedSections.map(
								(item: SectionItem, index: number) => {
									return <Section key={index} {...item} />;
								},
							)}
						</Box>
					</motion.div>
				) : null}
			</AnimatePresence>
			<Center>
				<IconButton
					onClick={() => setIsExpanded(prevState => !prevState)}
					bg="none"
					aria-label="expand"
					icon={<ChevronDown />}
					transform={isExpanded ? 'rotate(180deg)' : undefined}
					transition={`${ANIMATION_DURATION}s`}
				/>
			</Center>
		</Box>
	);
};
