import { VStack } from '@chakra-ui/react';
import { FileItemView } from './FileItemView';
import { UnionTimelineAttachment } from '../../../../../types';

interface IFileViewWrapper {
	items: UnionTimelineAttachment[];
	onRemove?: (id: number, type: number, indexItem: number) => void;
	canRemove?: boolean;
}

export const FileViewList: React.FC<IFileViewWrapper> = ({
	items,
	onRemove,
	canRemove = false,
}) => {
	return (
		<VStack spacing={'5px'} align={'stretch'}>
			{items.map((it, index) => (
				<FileItemView
					canRemove={canRemove}
					item={it}
					key={`item-${index}`}
					onRemove={() => {
						onRemove?.(it.id, it.type, index);
					}}
				/>
			))}
		</VStack>
	);
};
