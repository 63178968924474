import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { API_IDENTITY } from '../../../api/api.base';
import { RiskScoreResponse } from '../types';

export const useGetUserRiskScore = (id: number) => {
	return useQuery<ApiResponse<RiskScoreResponse | null>>(
		['user-risk-score', id],
		() => API_IDENTITY.get(`/api/admin/users/${id}/risk-score`, {}),
		{},
	);
};
