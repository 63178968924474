import { useMutation } from 'react-query';
import { ApiResponse } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface Payload {
	courseId: number;
	sectionId: number;
	lessonId: number;
	formData: FormData;
}
export const useEditImageLesson = () => {
	const { isLoading, mutate } = useMutation<
		ApiResponse<number>,
		unknown,
		Payload
	>({
		mutationFn: ({ courseId, sectionId, lessonId, formData }) => {
			return Api.post(
				`/api/admin/courses/${courseId}/third-step/section/${sectionId}/lesson/${lessonId}/images`,
				formData,
			);
		},
		onSuccess: data => {
			if (data.success) {
				Alerter.success(data.messages[0].message);
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while deleting image');
		},
	});

	return {
		isLoading,
		mutate,
	};
};
