import { Box, BoxProps, Button } from '@chakra-ui/react';
import React from 'react';
import { PaperclipIcon } from '../../../../../../../assets/icons';
import { Alerter } from '../../../../../../../utils/Alerter';
import { formatFileSize } from '../../../../../../../utils';

interface IFilePicker extends BoxProps {
	setValue: (value: File) => void;
	isDisabled: boolean;
}

const MAX_IMAGE_SIZE = 25 * 1024 * 1024;

export const FilePicker: React.FC<IFilePicker> = ({
	setValue,
	isDisabled,
	...rest
}) => {
	const inputRef = React.useRef<HTMLInputElement>(null);
	const handleClick = () => {
		inputRef.current?.click();
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			if (file.size > MAX_IMAGE_SIZE) {
				Alerter.error(
					`The image size must not exceed 25 MB, but you have ${formatFileSize(
						file.size,
					)}`,
				);
				return;
			}
			setValue(file);
		}
	};
	return (
		<Box {...rest}>
			<Button
				isDisabled={isDisabled}
				color={'inactiveBlue'}
				onClick={handleClick}
				variant={'link'}
				size={'sm'}
				leftIcon={<PaperclipIcon />}>
				Attach File
			</Button>
			<input
				type="file"
				accept=".jpg,.jpeg,.png,.pdf"
				ref={inputRef}
				onChange={handleChange}
				style={{ display: 'none' }}
			/>
		</Box>
	);
};
