import React, { useCallback, useMemo } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	VStack,
} from '@chakra-ui/react';
import { HeaderView } from './HeaderView';
import { TimelineTypeEnum } from '../../../../../constants';
import { CallView } from './CallView';
import { NoteView } from './NoteView';
import { EmailView } from './EmailView';
import { DMView } from './DMView';
import { TimelineItemType } from '../../../../../types';
import { DateView } from './DateView';
import { greenButton } from '../../../../../../../theme/elementStyles';
import { mergeAndSortAttachments } from '../../../../../helper';
import { useTimelineStore } from '../../../../../store';
import { FileViewList } from '../FileViewList';

interface ITimelineModal {
	isOpen: boolean;
	onClose: VoidFunction;
	item: TimelineItemType;
	isOwn: boolean;
}

export const TimelineViewModal: React.FC<ITimelineModal> = ({
	isOpen,
	onClose,
	item,
	isOwn,
}) => {
	const headerName = isOwn ? 'You' : item.adminName;

	const setTimelineId = useTimelineStore(state => state.setTimelineId);

	const items = useMemo(() => {
		return mergeAndSortAttachments(item.files, item.images);
	}, [item.images.length, item.files.length]);

	const getContent = useCallback(() => {
		switch (item.type) {
			case TimelineTypeEnum.Call:
				return (
					<CallView
						date={item.date}
						timeFrom={item.timeFrom}
						note={item.content.note}
						topic={item.content.topic}
					/>
				);
			case TimelineTypeEnum.Note:
				return <NoteView note={item.content.note} />;
			case TimelineTypeEnum.Email:
				return (
					<EmailView
						timeFrom={item.timeFrom}
						email={item.content.email}
						date={item.date}
						bodyText={item.content.bodyText}
						subject={item.content.subject}
					/>
				);
			case TimelineTypeEnum.DirectMessage:
				return (
					<DMView
						date={item.date}
						timeFrom={item.timeFrom}
						message={item.content.message}
					/>
				);
			default:
				return null;
		}
	}, [item.type]);

	return (
		<Modal
			scrollBehavior={'inside'}
			onClose={onClose}
			closeOnOverlayClick={false}
			isOpen={isOpen}>
			<ModalOverlay />
			<ModalContent bg="white" maxW="440px">
				<HeaderView type={item.type} name={headerName} />
				<ModalBody p="0px 20px 20px 20px">
					<VStack align={'stretch'} spacing={'15px'}>
						<DateView date={item.createdAt} />
						{getContent()}
						{items.length ? <FileViewList items={items} /> : null}
						{isOwn ? (
							<Button
								onClick={() => {
									onClose();
									setTimelineId(item.id);
								}}
								w={'50%'}
								alignSelf={'flex-end'}
								{...greenButton}
								type={'submit'}>
								Edit
							</Button>
						) : null}
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
