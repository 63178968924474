import React from 'react';
import { CloseButton, Flex, FlexProps, Text } from '@chakra-ui/react';
import { FileIcon } from '../../../assets/icons';

interface IAttachmentItem extends FlexProps {
	isRemoved?: boolean;
	onRemove?: () => void;
	name: string;
}
export const AttachmentItem: React.FC<IAttachmentItem> = ({
	name,
	isRemoved = true,
	onRemove,
	...rest
}) => {
	return (
		<Flex
			justify={'space-between'}
			align={'center'}
			p={'10px'}
			borderRadius={'10px'}
			bg={'background'}
			{...rest}>
			<Flex align={'center'}>
				<FileIcon />
				<Text ml={'10px'} isTruncated={true} fontWeight={'500'}>
					{name}
				</Text>
			</Flex>
			{isRemoved ? <CloseButton bg={'white'} onClick={onRemove} /> : null}
		</Flex>
	);
};
