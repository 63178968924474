import { FieldView } from './FieldView';
import dayjs from 'dayjs';
import { USDateFormat } from '../../../../../../../constants';

interface IEmailView {
	email: string | null;
	subject: string | null;
	bodyText: string | null;
	date: string | null;
	timeFrom: string | null;
}

export const EmailView: React.FC<IEmailView> = ({
	email,
	subject,
	date,
	bodyText,
	timeFrom,
}) => {
	return (
		<>
			<FieldView label={'Email'} value={email} />
			<FieldView label={'Subject'} value={subject} />
			<FieldView label={'Body text'} value={bodyText} />
			<FieldView
				label={'Due date'}
				value={date ? dayjs(date).format(USDateFormat) : null}
			/>
			<FieldView
				label={'Time from:'}
				value={
					timeFrom
						? dayjs(`${date} ${timeFrom}`).format('hh:mm A')
						: null
				}
			/>
		</>
	);
};
