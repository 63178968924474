import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordValidation } from '../constants';

export const changePasswordSchema = yup.object().shape({
	password: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.matches(PasswordValidation, ErrorMessages.INVALID_PASSWORD),
});

export const changePasswordResolver = yupResolver(changePasswordSchema);
