import { create } from 'zustand';

type StoreType = {
	isOpen: boolean;
	timelineId: number | null;
};

type Actions = {
	onOpen: VoidFunction;
	onClose: VoidFunction;
	setTimelineId: (id: number) => void;
};

const INITIAL_STATE: StoreType = {
	isOpen: false,
	timelineId: null,
};

export const useTimelineStore = create<Actions & StoreType>(set => ({
	...INITIAL_STATE,
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false, timelineId: null }),
	setTimelineId: value => set({ timelineId: value, isOpen: true }),
}));
