import React from 'react';
import { SectionWrapper } from '../SectionWrapper';
import { Divider, VStack } from '@chakra-ui/react';
import { UserCredentials } from './UserCredentials';
import { PandadockFile } from './PandadockFile';

interface IMainInfoSection {
	email?: string;
	phoneNumber?: string | null;
	invitationDate?: string;
	id?: number;
	firstName?: string;
	lastName?: string;
	invitationResendDate?: string;
	isDocumentCompleted: boolean;
	documentLink?: string;
}

export const MainInfoSection: React.FC<IMainInfoSection> = ({
	email,
	phoneNumber,
	invitationDate,
	id,
	firstName,
	lastName,
	invitationResendDate,
	isDocumentCompleted,
	documentLink,
}) => {
	return (
		<SectionWrapper borderRadius={'10px'}>
			<VStack align={'stretch'} spacing={'15px'}>
				<UserCredentials
					id={id}
					lastName={lastName}
					firstName={firstName}
					email={email}
					invitationDate={invitationDate}
					phoneNumber={phoneNumber}
					invitationResendDate={invitationResendDate}
				/>
				<Divider />
				<PandadockFile
					documentLink={documentLink}
					isDocumentCompleted={Boolean(isDocumentCompleted)}
				/>
			</VStack>
		</SectionWrapper>
	);
};
