//gives you normal date instead of date in ISO 8601 format
export const getNormalDate = (dateISO: string) => {
	const date = new Date(dateISO);
	const day = date.getUTCDate().toString().padStart(2, '0');
	const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
	const year = date.getUTCFullYear().toString();
	const hours = date.getUTCHours().toString().padStart(2, '0');
	const minutes = date.getUTCMinutes().toString().padStart(2, '0');
	const seconds = date.getUTCSeconds().toString().padStart(2, '0');
	const dateString = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
	return dateString;
};
