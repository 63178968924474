import { useMutation } from 'react-query';
import { ApiResponseEmpty, UpdatePermissionGroupPayload } from '../../../types';
import { Alerter } from '../../../utils/Alerter';
import { API_IDENTITY } from '../../../api/api.base';
import { queryClient } from '../../../main';

export const useUpdatePermissionGroup = (onClose: VoidFunction) => {
	return useMutation<ApiResponseEmpty, unknown, UpdatePermissionGroupPayload>(
		{
			mutationFn: payload =>
				API_IDENTITY.put(`/api/admin/permissions/${payload.id}`, {
					name: payload.name,
					claimsIds: payload.claimsIds,
				}),
			onSuccess: data => {
				if (!data.success) {
					Alerter.error(data.errors?.[0]?.message);
				} else {
					Alerter.success(data.messages?.[0].message);
					queryClient.invalidateQueries('permission-groups');
					onClose();
				}
			},
			onError: () => {
				Alerter.error('Error while editing permission group');
			},
		},
	);
};
