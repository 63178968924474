import 'virtual:svg-icons-register';
import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
import { OidcAuth } from './components/OidcAuth';
import { theme } from './theme';

// Create a client
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

if (import.meta.env.VITE_MODE !== 'development') {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DCN,
		integrations: [
			new Sentry.BrowserTracing({}),
			new Sentry.Replay({ block: ['iframe'] }),
		],
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

createRoot(document.getElementById('root') as HTMLElement).render(
	<QueryClientProvider client={queryClient}>
		<ChakraProvider theme={theme}>
			<BrowserRouter>
				<OidcAuth>
					<App />
				</OidcAuth>
			</BrowserRouter>
		</ChakraProvider>
		<ReactQueryDevtools initialIsOpen={false} />
	</QueryClientProvider>,
);
