import { Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { CloseIcon } from '../../../../../../../assets/icons';
import { formatFileSize } from '../../../../../../../utils';

interface IFileItem {
	onRemove: VoidFunction;
	item?: File;
}

export const FileItem: React.FC<IFileItem> = ({ onRemove, item }) => {
	return (
		<Flex
			h={'37px'}
			px={'8px'}
			borderRadius={'5px'}
			bg={'background'}
			align={'center'}>
			<Text
				flex={1}
				color={'inactiveBlue'}
				noOfLines={1}
				fontWeight={600}
				variant={'bodyMedium'}>
				{item?.name}
			</Text>
			<HStack spacing={'16px'}>
				<Text variant={'bodyMedium'} color={'lightText'}>
					{formatFileSize(item?.size || 0)}
				</Text>
				<IconButton
					onClick={onRemove}
					variant={'ghost'}
					size={'xs'}
					aria-label={'remove-file'}
					icon={<CloseIcon size={'12'} color={'#4A5991'} />}
				/>
			</HStack>
		</Flex>
	);
};
