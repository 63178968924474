import { useMutation, useQueryClient } from 'react-query';
import { ApiResponse, ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';
import { SectionItemType } from '../types';

interface Payload {
	courseId: number;
	isCourseCompletedMessage: boolean;
	courseCompletedMessage?: string;
}
export const useUpdateCourseCompletionMessage = (
	onSuccessAction?: () => void,
) => {
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		Payload
	>({
		mutationFn: payload => {
			const { courseId, ...body } = payload;
			return Api.put(
				`/api/admin/courses/${courseId}/completed-message`,
				body,
			);
		},
		onSuccess: (
			data,
			{ courseId, isCourseCompletedMessage, courseCompletedMessage },
		) => {
			if (!data.success) {
				Alerter.error(data.errors[0]?.message);
			} else {
				Alerter.success(data.messages[0]?.message);

				onSuccessAction?.();

				queryClient.setQueryData<
					| ApiResponse<{
							sections: SectionItemType[];
							courseCompletedMessage?: string | null;
							isCourseCompletedMessage: boolean;
					  }>
					| undefined
				>(['sections', courseId], data => {
					if (data) {
						return {
							...data,
							value: {
								...data.value,
								courseCompletedMessage: courseCompletedMessage,
								isCourseCompletedMessage:
									isCourseCompletedMessage,
							},
						};
					}
				});
			}
		},
		onError: () => {
			Alerter.error('Error while editing author');
		},
	});

	return {
		isLoading,
		onUpdateCourseCompletionMessage: mutate,
	};
};
