import React from 'react';
import dayjs from 'dayjs';
import { Box, Text } from '@chakra-ui/react';
import { USDateFormat } from '../../../../../../constants';

interface UserCredentialsProps {
	email?: string;
	phoneNumber?: string | null;
	invitationDate?: string;
	id?: number;
	firstName?: string;
	lastName?: string;
	invitationResendDate?: string;
}

const CredentialItem: React.FC<{ label: string; value?: string }> = ({
	label,
	value = '-',
}) => (
	<Box>
		<Text color={'lightText'}>{label}</Text>
		<Text color={'darkBlue'} fontWeight={600} variant={'bodyBig'}>
			{value}
		</Text>
	</Box>
);

export const UserCredentials: React.FC<UserCredentialsProps> = ({
	lastName,
	firstName,
	invitationDate,
	phoneNumber,
	id,
	email,
	invitationResendDate,
}) => {
	return (
		<>
			<CredentialItem label={'ID:'} value={String(id)} />
			<CredentialItem
				label={'Full Name:'}
				value={`${firstName} ${lastName}`}
			/>
			<CredentialItem
				label={'Invitation Sent Date:'}
				value={
					invitationDate
						? dayjs(invitationDate).format(USDateFormat)
						: '-'
				}
			/>
			<CredentialItem
				label={'Invitation Resend Date:'}
				value={
					invitationResendDate
						? dayjs(invitationResendDate).format(USDateFormat)
						: '-'
				}
			/>
			<CredentialItem label={'Email:'} value={email} />
			<CredentialItem
				label={'Phone Number:'}
				value={phoneNumber ? `+1 ${phoneNumber}` : '-'}
			/>
		</>
	);
};
