import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { CustomProgress } from '../../../../../../components';
import { Lesson } from './Lesson';
import { LessonItem, SectionItem } from '../../../../types';

export const Section: React.FC<SectionItem> = ({
	name,
	position,
	lessons,
	completedLessonsCount,
}) => {
	const lessonsCount = lessons.length;
	const sectionProgress = (completedLessonsCount / lessonsCount) * 100;

	const sortedLessons = lessons.sort(
		(a: LessonItem, b: LessonItem) => a.position - b.position,
	);

	return (
		<Box>
			<Text fontSize="14px">Section {position}</Text>
			<Text fontSize="16px" textDecoration="underline" fontWeight={700}>
				{name}
			</Text>
			<Text mt="10px" mb="5px" fontSize="14px" color="lightGray">
				Completed {completedLessonsCount}/{lessonsCount} lesson
			</Text>
			<CustomProgress progress={sectionProgress} />
			<Box mt="10px" px="15px" py="10px">
				{sortedLessons.map((item: LessonItem, index: number) => {
					return <Lesson key={index} {...item} />;
				})}
			</Box>
		</Box>
	);
};
