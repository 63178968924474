import React from 'react';
import { Td, Tr, HStack, Tooltip, IconButton, Text } from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import { PermissionGroup } from '../../../types';
import { RenderTd } from '../../../components';
import { colors } from '../../../theme/colors';

interface ITableRowProps extends PermissionGroup {
	onEditOpen: VoidFunction;
	onDelete: (value: number) => void;
	lastItem?: boolean;
}

export const TableRow: React.FC<ITableRowProps> = ({
	claimsIds,
	id,
	isDeletable,
	name,
	onEditOpen,
	onDelete,
	changedAt,
	createdAt,
	lastItem,
}) => {
	return (
		<Tr bg="white">
			<RenderTd
				value={id}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="14px 16px"
			/>
			<RenderTd
				value={name}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="14px 16px"
			/>
			<RenderTd
				value={dayjs(createdAt).format('MM/DD/YY')}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="14px 16px"
			/>
			<RenderTd
				value={dayjs(changedAt).format('MM/DD/YY')}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="14px 16px"
			/>
			<RenderTd
				value={claimsIds?.map(elem => elem.name).join(', ')}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="14px 16px"
				whiteSpace="initial"
			/>
			<Td
				isNumeric={true}
				py="14px"
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}>
				{/* ID 3 is reserved for admin group that can't be edited */}
				{id !== 3 ? (
					<HStack>
						<Tooltip label={'Edit group'}>
							<IconButton
								aria-label="Edit group"
								colorScheme="facebook"
								w="30px"
								bgColor={colors.lightBlue}
								minW="30px"
								h="30px"
								onClick={onEditOpen}>
								<EditIcon />
							</IconButton>
						</Tooltip>
						{isDeletable ? (
							<Tooltip label="Delete group">
								<IconButton
									aria-label="Delete group"
									colorScheme="red"
									w="30px"
									minW="30px"
									h="30px"
									onClick={() => {
										onDelete(id);
									}}>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						) : null}
					</HStack>
				) : (
					<Text textAlign="left">-</Text>
				)}
			</Td>
		</Tr>
	);
};
