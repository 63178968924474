import { colors } from './colors';

export const blueButton = {
	colorScheme: 'lightBlueButton',
	fontWeight: 500,
	color: 'white',
	height: '56px',
	borderRadius: '10px',
};

export const greenButton = {
	colorScheme: 'greenButton',
	fontWeight: 500,
	color: 'white',
	height: '56px',
	borderRadius: '10px',
};

export const toolTip = {
	padding: '10px',
	bgColor: '#1C2959',
	top: '-30px',
	right: '-6px',
	fontSize: '12px',
	borderRadius: '5px',
};

export const whiteButton = {
	colorScheme: 'white',
	fontWeight: 500,
	color: colors.lightBlue,
	background: 'white',
	height: '38px',
	borderRadius: '50px',
};
