import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { PrevArrowIcon } from '../../../assets/icons';

export const PrevButton: React.FC<ButtonProps> = props => {
	return (
		<Button
			size={'lg'}
			leftIcon={<PrevArrowIcon />}
			fontWeight={'700'}
			bg={'transparent'}
			{...props}>
			Previous Step
		</Button>
	);
};
