import React from 'react';
import {
	Box,
	TableContainer,
	Table,
	Tr,
	Td,
	Center,
	Spinner,
	Tbody,
} from '@chakra-ui/react';

import { TableRow } from './TableRow';
import { TableHeader } from './TableHeader';
import { colors } from '../../../../theme/colors';
import { ExtendedPagination } from '../../../../components';

interface ITransactionsHistoryTableProps {
	isLoading: boolean;
	transactions?: any[];
	totalCount?: number;
	pageSize: number;
	currentPage: number;
	setCurrentPage: (page: number) => void;
	setPageSize: (pageSize: number) => void;
}
export const TransactionsHistoryTable: React.FC<
	ITransactionsHistoryTableProps
> = ({
	isLoading,
	totalCount,
	setCurrentPage,
	setPageSize,
	pageSize,
	currentPage,
	transactions,
}) => (
	<Box mt="25px">
		<Box p="20px" bg="white" borderRadius="10px">
			<TableContainer
				border="1px solid"
				borderColor={colors.dropBlue}
				borderRadius="10px"
				boxShadow="none">
				<Table variant="simple">
					<TableHeader />
					<Tbody>
						{isLoading ? (
							<Tr bg="white">
								<Td colSpan={8}>
									<Center>
										<Spinner />
									</Center>
								</Td>
							</Tr>
						) : (
							transactions?.map(
								(transaction: any, index: number) => {
									const lastItem =
										transactions.length - 1 === index;
									return (
										<TableRow
											key={index}
											lastItem={lastItem}
											transaction={transaction}
										/>
									);
								},
							)
						)}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
		<ExtendedPagination
			currentPage={currentPage}
			gotoPage={setCurrentPage}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalCount={totalCount || 0}
			currentItemsLength={transactions?.length}
			perPageTextProps={{
				color: colors.lightGray,
				fontWeight: 500,
				fontSize: '16px',
			}}
			pagesTextProps={{
				color: colors.darkBlueTable[100],
				fontWeight: 500,
				fontSize: '16px',
			}}
			selectProps={{
				backgroundColor: 'white',
				color: colors.darkBlue,
				fontWeight: 500,
				border: 'none',
				boxShadow: '0px 0px 3px 0px rgba(139, 139, 139, 0.10)',
				minW: '74px',
				borderRadius: '10px',
				h: '46px',
			}}
			defaultPaginationProps={{
				mx: '0px',
				mt: '40px',
			}}
		/>
	</Box>
);
