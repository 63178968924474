import React, { Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';
import {
	CreateCourse,
	UsersContainer,
	EditCourse,
	PermissionGroups,
	LogsContainer,
	CoursesContainer,
	CommunityVerification,
	TransactionHistory,
	UserDetails,
} from './pages';
import { DashboardLayout } from './components';

import { CourseContextProvider } from './pages/Course/context';
import { useFetchPermissionGroups } from './pages/PermissionGroups/queries';
import './index.css';

const App: React.FC = () => {
	useFetchPermissionGroups();

	return (
		<Routes>
			<Route path="/" element={<DashboardLayout />}>
				<Route index={true} element={<UsersContainer />} />
				<Route path="/logs" element={<LogsContainer />} />
				<Route
					path="/permission-groups"
					element={<PermissionGroups />}
				/>

				<Route
					path="/courses/create-course"
					element={
						<CourseContextProvider>
							<CreateCourse />
						</CourseContextProvider>
					}
				/>
				<Route
					path="/courses/edit-course/:id"
					element={
						<CourseContextProvider>
							<EditCourse />
						</CourseContextProvider>
					}
				/>
				<Route
					path="/courses"
					element={
						<Suspense>
							<CoursesContainer />
						</Suspense>
					}
				/>
				<Route
					path="/user/:id"
					element={
						<Suspense>
							<UserDetails />
						</Suspense>
					}
				/>
				<Route
					path="/transaction-history"
					element={
						<Suspense>
							<TransactionHistory />
						</Suspense>
					}
				/>
				<Route
					path="/community/verification"
					element={
						<Suspense>
							<CommunityVerification />
						</Suspense>
					}
				/>
			</Route>
		</Routes>
	);
};

export default App;
