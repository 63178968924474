import React from 'react';
import {
	Button,
	Center,
	Collapse,
	Spinner,
	Stack,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { CourseDescriptionBox, NextButton, PrevButton } from '../../components';
import { PlusIcon } from '../../../../assets/icons';
import { useGetSections } from '../../queries';
import { useCourseContext } from '../../context';
import {
	CreateSection,
	CourseCompletionMessage,
	SectionItem,
} from './components';

interface ThirdStep {
	goToNext: () => void;
	goToPrevious: () => void;
	setActiveStep: (value: number) => void;
}
export const ThirdStepTab: React.FC<ThirdStep> = ({
	goToNext,
	goToPrevious,
	setActiveStep,
}) => {
	const { id: courseId } = useCourseContext();

	const {
		isLoading: sectionsLoading,
		sections,
		courseCompletedMessage,
		isCourseCompletedMessage,
	} = useGetSections(courseId);

	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<VStack spacing={'20px'} align={'stretch'} mt={'30px'}>
			<CourseDescriptionBox setActiveStep={setActiveStep} />
			{sectionsLoading ? (
				<Center height={'200px'}>
					<Spinner size={'xl'} />
				</Center>
			) : (
				sections?.map((it, index) => (
					<SectionItem
						key={it.id}
						item={it}
						count={index + 1}
						courseId={courseId}
					/>
				))
			)}

			<Collapse in={isOpen}>
				<CreateSection onClose={onClose} courseId={courseId} />
			</Collapse>

			<Button
				isDisabled={isOpen}
				onClick={onOpen}
				size={'lg'}
				colorScheme={'lightBlueButton'}
				rightIcon={<PlusIcon />}>
				Add New Section
			</Button>

			<CourseCompletionMessage
				message={courseCompletedMessage}
				isChecked={isCourseCompletedMessage}
				courseId={courseId}
				dataLoading={sectionsLoading}
			/>

			<Stack direction={'row'} spacing={'20px'}>
				<PrevButton w={'full'} onClick={goToPrevious} />
				<NextButton w={'full'} onClick={goToNext} />
			</Stack>
		</VStack>
	);
};
