import { useMutation, useQueryClient } from 'react-query';
import { ApiResponse } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useCreateAuthor = () => {
	const queryClient = useQueryClient();
	const { isLoading, mutate } = useMutation<
		ApiResponse<number>,
		unknown,
		FormData
	>({
		mutationFn: payload =>
			Api.post('/api/admin/courses/authors/create', payload),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			} else {
				queryClient.invalidateQueries('authors');
			}
		},
		onError: () => {
			Alerter.error('Error while creating author');
		},
	});

	return {
		isLoading,
		onCreateAuthor: mutate,
	};
};
