import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	Box,
	Button,
	Flex,
	HStack,
	IconButton,
	Text,
	VStack,
} from '@chakra-ui/react';
import {
	LeftPaginationArrowIcon,
	TrashIcon,
} from '../../../../../assets/icons';
import {
	AttachmentItem,
	FileInput,
	PreviewFileUpload,
} from '../../../components';
import { InputText } from '../../../../../components';
import { allowImageMime, allowVideoMime } from '../../../constants';
import { useForm, Controller } from 'react-hook-form';
import {
	AddLessonFormValues,
	addLessonResolver,
} from '../../../../../validation/add-lesson.schema';
import {
	useDeleteLesson,
	useDeleteVideo,
	useEditImageLesson,
	useEditLesson,
	useUploadFile,
} from '../../../queries';
import { AttachmentItemType, LessonItemType } from '../../../types';
import { getFileNameWithExtension } from '../../../../../utils';
import { Editor } from '../../../../../components/Editor/Editor';
import { AttachmentsList } from './AttachmentsList';
import { Alerter } from '../../../../../utils/Alerter';

interface ILessonForm {
	onClose?: () => void;
	countName: string;
	sectionId: number;
	item: LessonItemType;
	courseId: number;
}
export const LessonForm: React.FC<ILessonForm> = ({
	onClose,
	countName,
	sectionId,
	item,
	courseId,
}) => {
	const [attachments, setAttachments] = useState<
		{
			id: string;
			file: File;
		}[]
	>([]);
	const [externalAttachments, setExternalAttachments] = useState<
		AttachmentItemType[]
	>(item.attachments);

	const [attachmentsIdToDelete, setAttachmentsIdToDelete] = useState<
		Array<number>
	>([]);

	const [videoUrl, setVideoUrl] = useState(
		item?.videoLink ? item.videoLink : '',
	);
	const [previewImgUrl, setPreviewImgUrl] = useState(
		item?.imagePreviewLink ? item.imagePreviewLink : '',
	);

	const [imgUrl, setImgUrl] = useState(item?.imageLink ? item.imageLink : '');

	const [uploadProgressVideo, setUploadProgressVideo] = useState(0);

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
		watch,
	} = useForm<AddLessonFormValues>({
		resolver: addLessonResolver,
		defaultValues: {
			description: '',
			name: '',
			attachmentsToDelete: [],
			isVideoUploaded: false,
			newAttachments: [],
			isPreviewImageUploaded: false,
			isImageUploaded: false,
		},
		values: {
			description: item?.description ? item.description : '',
			name: item?.name ? item.name : '',
			attachmentsToDelete: [],
			isVideoUploaded: !!item?.videoLink,
			newAttachments: [],
			isImageUploaded: !!item?.imageLink,
			isPreviewImageUploaded: !!item?.imagePreviewLink,
		},
	});

	const { isLoading: isLoadingUpload, mutate: uploadVideo } = useUploadFile();

	const { isLoading: editingLoading, onEditLesson } = useEditLesson();

	const { isLoading: deleteLoading, onDeleteLesson } = useDeleteLesson();

	const { isLoading: deleteVideoLoading, onDeleteVideo } = useDeleteVideo();

	const { isLoading: editImageLoading, mutate: onEditImage } =
		useEditImageLesson();

	const imageFile = watch('image');
	const previewImageFile = watch('previewImage');
	const videoFile = watch('videoFile');
	const isVideoUploaded = watch('isVideoUploaded');
	const isImageUploaded = watch('isImageUploaded');
	const isPreviewImageUploaded = watch('isPreviewImageUploaded');

	const isDisabledImage = useMemo(() => {
		return (
			!!videoFile ||
			isVideoUploaded ||
			!!previewImageFile ||
			isPreviewImageUploaded
		);
	}, [videoFile, isVideoUploaded, previewImageFile, isPreviewImageUploaded]);

	const isDisabledVideo = useMemo(() => {
		return !!imageFile || isImageUploaded;
	}, [imageFile, isImageUploaded]);
	const onSubmit = (values: AddLessonFormValues) => {
		if (
			!values.isVideoUploaded &&
			!values.isImageUploaded &&
			!values.isPreviewImageUploaded
		) {
			Alerter.error(
				'Please upload video and video image preview or image to save lesson',
			);
			return;
		}
		if (values.isVideoUploaded && !values.isPreviewImageUploaded) {
			Alerter.error('Please upload video image preview to save lesson');
			return;
		}
		if (values.isPreviewImageUploaded && !values.isVideoUploaded) {
			Alerter.error('Please upload video to save lesson');
			return;
		}
		const formData = new FormData();

		if (values.attachmentsToDelete?.length) {
			values.attachmentsToDelete.forEach(it => {
				formData.append('AttachmentsToDelete', it.toString());
			});
		}

		if (values.newAttachments) {
			Array.from(values.newAttachments).forEach(it => {
				formData.append('NewAttachments', it);
			});
		}

		formData.append('Name', values.name);
		formData.append('Description', values.description);

		onEditLesson(
			{
				courseId: courseId,
				sectionId: sectionId,
				lessonId: item.id,
				formData,
			},
			{
				onSuccess: data => {
					if (data.success) {
						onClose?.();
						// setAttachmentsIdToDelete([]);
						// setValue('attachmentsToDelete', []);
					}
				},
			},
		);
	};

	const onRemoveVideo = useCallback(() => {
		if (isVideoUploaded) {
			onDeleteVideo(
				{
					courseId: courseId,
					sectionId,
					lessonId: item.id,
				},
				{
					onSuccess: data => {
						if (data.success) {
							setValue('isVideoUploaded', false);
							setVideoUrl('');
							setUploadProgressVideo(0);
							setValue('videoFile', undefined);
						}
					},
				},
			);
		} else {
			setValue('videoFile', undefined);
			URL.revokeObjectURL(videoUrl);
			setVideoUrl('');
			setUploadProgressVideo(0);
		}
	}, [isVideoUploaded]);

	const onRemovePreviewImg = useCallback(() => {
		if (isPreviewImageUploaded) {
			const formData = new FormData();
			formData.append('IsPreviewImageDeleted', String(true));
			onEditImage(
				{
					courseId,
					sectionId,
					lessonId: item.id,
					formData,
				},
				{
					onSuccess: data => {
						if (data.success) {
							setValue('isPreviewImageUploaded', false);
							setPreviewImgUrl('');
							setValue('previewImage', undefined);
						}
					},
				},
			);
		} else {
			setValue('previewImage', undefined);
			URL.revokeObjectURL(previewImgUrl);
			setPreviewImgUrl('');
		}
	}, [isPreviewImageUploaded]);

	const onRemoveImg = useCallback(() => {
		if (isImageUploaded) {
			const formData = new FormData();
			formData.append('IsImageDeleted', true.toString());
			onEditImage(
				{
					courseId,
					sectionId,
					lessonId: item.id,
					formData,
				},
				{
					onSuccess: data => {
						if (data.success) {
							setValue('isImageUploaded', false);
							setImgUrl('');
							setValue('image', undefined);
						}
					},
				},
			);
		} else {
			setValue('image', undefined);
			URL.revokeObjectURL(imgUrl);
			setImgUrl('');
		}
	}, [isImageUploaded]);

	const onUploadVideo = useCallback(async () => {
		try {
			if (videoFile && courseId) {
				const formData = new FormData();
				formData.append('Video', videoFile);

				uploadVideo(
					{
						url: `/api/admin/courses/${courseId}/third-step/section/${sectionId}/lesson/${item.id}/video`,
						data: formData,
						onUpload: event => {
							if (event.total) {
								setUploadProgressVideo(
									Math.round(
										(100 * event.loaded) / event.total,
									),
								);
							}
						},
					},
					{
						onSuccess: data => {
							if (
								typeof data === 'string' &&
								data.includes('data: Completed')
							) {
								setValue('isVideoUploaded', true);
							}
						},
					},
				);
			}
		} catch (err) {
			console.log(err, 'ERR');
		}
	}, [videoFile, courseId]);

	const onUploadImage = useCallback(async () => {
		try {
			if (imageFile && courseId) {
				const formData = new FormData();
				formData.append('Image', imageFile);
				onEditImage(
					{
						courseId,
						sectionId,
						lessonId: item.id,
						formData,
					},
					{
						onSuccess: data => {
							if (data.success) {
								setValue('isImageUploaded', true);
							}
						},
					},
				);
			}
		} catch (err) {
			console.log(err, 'ERR');
		}
	}, [imageFile, courseId]);

	const onUploadPreviewImage = useCallback(async () => {
		try {
			if (previewImageFile && courseId) {
				const formData = new FormData();
				formData.append('PreviewImage', previewImageFile);
				onEditImage(
					{
						courseId,
						sectionId,
						lessonId: item.id,
						formData,
					},
					{
						onSuccess: data => {
							if (data.success) {
								setValue('isPreviewImageUploaded', true);
							}
						},
					},
				);
			}
		} catch (err) {
			console.log(err, 'ERR');
		}
	}, [previewImageFile, courseId]);

	const onAddAttachments = useCallback((files: File[]) => {
		const data = files.map(it => ({ id: uuidv4(), file: it }));
		setAttachments(prev => [...prev, ...data]);
	}, []);

	useEffect(() => {
		if (videoFile) {
			setVideoUrl(URL.createObjectURL(videoFile));
			onUploadVideo();
		}
	}, [videoFile]);

	useEffect(() => {
		if (previewImageFile && !errors.previewImage?.message) {
			setPreviewImgUrl(URL.createObjectURL(previewImageFile));
			onUploadPreviewImage();
		}
	}, [previewImageFile, errors.previewImage]);

	useEffect(() => {
		if (imageFile && !errors.image?.message) {
			setImgUrl(URL.createObjectURL(imageFile));
			onUploadImage();
		}
	}, [imageFile, errors.image]);

	useEffect(() => {
		if (attachments.length) {
			const data = attachments.map(it => it.file);
			setValue('newAttachments', data);
		}
	}, [attachments]);

	useEffect(() => {
		if (attachmentsIdToDelete.length) {
			setValue('attachmentsToDelete', attachmentsIdToDelete);
		}
	}, [attachmentsIdToDelete]);

	// useEffect(() => {
	// 	if (item.attachments.length !== 0) {
	// 		setExternalAttachments(item.attachments);
	// 	}
	// }, [item.attachments]);

	return (
		<Box p={'20px'} bg={'background'} borderRadius={'10px'}>
			<Flex align={'center'} justify={'space-between'}>
				<Text fontSize={'xl'} color={'lightGray'} fontWeight={700}>
					{countName}
				</Text>
				<HStack>
					<Button
						onClick={() => {
							if (courseId) {
								onDeleteLesson({
									courseId: courseId,
									sectionId,
									lessonId: item.id,
								});
							}
						}}
						isDisabled={deleteLoading}
						isLoading={deleteLoading}
						colorScheme={'lightBlueButton'}
						rightIcon={<TrashIcon />}>
						Delete Lesson
					</Button>
					<IconButton
						onClick={onClose}
						aria-label={'close form'}
						bg={'white'}
						icon={
							<Box transform={'rotate(-90deg)'}>
								<LeftPaginationArrowIcon />
							</Box>
						}
					/>
				</HStack>
			</Flex>
			<form onSubmit={handleSubmit(onSubmit)}>
				<VStack
					borderRadius={'10px'}
					mt={'20px'}
					align={'stretch'}
					spacing={'20px'}
					bg={'white'}
					p={'20px'}>
					<InputText
						label={'Video title'}
						placeholder={'Name'}
						errorMsg={errors.name?.message}
						{...register('name')}
					/>
					<Controller
						render={({ field: { ref, ...rest }, fieldState }) => {
							return (
								<Editor
									{...rest}
									errMsg={fieldState.error?.message}
									label={'Video Description'}
									placeholder={'Description'}
								/>
							);
						}}
						name={'description'}
						control={control}
					/>
					{videoUrl ? (
						<PreviewFileUpload
							isLoading={isLoadingUpload || deleteVideoLoading}
							fileName={videoFile?.name}
							uploadProgress={uploadProgressVideo}
							isUploaded={isVideoUploaded}
							// onUploadFile={onUploadVideo}
							p={'0px'}
							variant={'video'}
							src={videoUrl}
							onRemove={onRemoveVideo}
						/>
					) : (
						<Controller
							render={({ fieldState }) => (
								<FileInput
									typesPlaceholder={
										'(Only .mp4 .webm videos will be accepted)'
									}
									isDisabled={isDisabledVideo}
									tooltipMsg={
										'Please remove image to add video'
									}
									errorMsg={fieldState.error?.message}
									p={'0px'}
									placeholder={'Drop the video...'}
									accept={allowVideoMime}
									label={'Uploading Video'}
									onSetFile={value => {
										setValue('videoFile', value, {
											shouldValidate: true,
										});
									}}
								/>
							)}
							name={'videoFile'}
							control={control}
						/>
					)}
					{previewImgUrl ? (
						<PreviewFileUpload
							label={'Video Preview image'}
							isLoading={editImageLoading}
							isUploaded={isPreviewImageUploaded}
							// onUploadFile={onUploadPreviewImage}
							p={'0px'}
							src={previewImgUrl}
							onRemove={onRemovePreviewImg}
						/>
					) : (
						<Controller
							render={({ fieldState }) => (
								<FileInput
									typesPlaceholder={
										'(Only .png .bmp .jpeg .jpg .gif images will be accepted)'
									}
									isDisabled={isDisabledVideo}
									tooltipMsg={
										'Please remove image to add video image preview'
									}
									errorMsg={fieldState.error?.message}
									p={'0px'}
									accept={allowImageMime}
									label={'Video Preview image'}
									onSetFile={value => {
										setValue('previewImage', value, {
											shouldValidate: true,
										});
									}}
								/>
							)}
							name={'previewImage'}
							control={control}
						/>
					)}
					<FileInput
						p={'0px'}
						isMultiple={true}
						onSetFiles={files => {
							if (files?.length) {
								onAddAttachments(files);
							}
						}}
						placeholder={'Drop file here...'}
						label={'Attachments (500mb max)'}
					/>
					<AttachmentsList
						items={attachments}
						keyExtractor={item => item.id}
						renderItem={item => (
							<AttachmentItem
								onRemove={() => {
									setAttachments(prev =>
										prev?.filter(it => it.id !== item.id),
									);
								}}
								key={item.id}
								name={item.file.name}
							/>
						)}
					/>
					<AttachmentsList
						items={externalAttachments}
						keyExtractor={item => item.id.toString()}
						renderItem={item => (
							<AttachmentItem
								onRemove={() => {
									setAttachmentsIdToDelete(prev => [
										...prev,
										item.id,
									]);
									setExternalAttachments(prev =>
										prev?.filter(it => it.id !== item.id),
									);
								}}
								name={getFileNameWithExtension(item.itemLink)}
							/>
						)}
					/>
					{imgUrl ? (
						<PreviewFileUpload
							label={'Upload image instead of a video'}
							isLoading={editImageLoading}
							isUploaded={isImageUploaded}
							// onUploadFile={onUploadImage}
							p={'0px'}
							src={imgUrl}
							onRemove={onRemoveImg}
						/>
					) : (
						<Controller
							render={({ fieldState }) => (
								<FileInput
									typesPlaceholder={
										'(Only .png .bmp .jpeg .jpg .gif images will be accepted)'
									}
									tooltipMsg={
										'Please remove video or video image preview to add image'
									}
									isDisabled={isDisabledImage}
									errorMsg={fieldState.error?.message}
									p={'0px'}
									accept={allowImageMime}
									label={'Upload image instead of a video'}
									onSetFile={value => {
										setValue('image', value, {
											shouldValidate: true,
										});
									}}
								/>
							)}
							name={'image'}
							control={control}
						/>
					)}
					<Button
						isLoading={editingLoading}
						type={'submit'}
						borderColor={'lightGreen'}
						color={'lightGreen'}
						size={'lg'}
						variant={'outline'}>
						{'Save Lesson'}
					</Button>
				</VStack>
			</form>
		</Box>
	);
};
