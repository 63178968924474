import { FieldView } from './FieldView';
import dayjs from 'dayjs';
import { USDateFormat } from '../../../../../../../constants';

interface ICallView {
	note: string | null;
	date: string | null;
	timeFrom: string | null;
	topic: string | null;
}

export const CallView: React.FC<ICallView> = ({
	note,
	date,
	timeFrom,
	topic,
}) => {
	return (
		<>
			<FieldView label={'Topic'} value={topic} />
			<FieldView label={'note'} value={note} />
			<FieldView
				label={'Due date'}
				value={date ? dayjs(date).format(USDateFormat) : null}
			/>
			<FieldView
				label={'Time from'}
				value={
					timeFrom
						? dayjs(`${date} ${timeFrom}`).format('hh:mm A')
						: null
				}
			/>
		</>
	);
};
