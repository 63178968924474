import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Text,
	Button,
	HStack,
	IconButton,
	Box,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { UserItem } from './components/UserItem';
import { VerificationUser } from '../types';

const radioButtons = [
	{
		value: 1,
		label: 'Disabled',
	},
	{
		value: 2,
		label: 'Active',
	},
];

interface ISetVerificationStatus {
	isOpen: boolean;
	updateStatusLoading: boolean;
	selectedUsers: VerificationUser[];
	onOpen: () => void;
	onClose: () => void;
	onChangeVerificationStatus: (
		userIds: number[],
		communityAllow: boolean,
		onClose?: () => void,
	) => void;
	removeUserFromSelected: (user: VerificationUser) => void;
}
export const SetVerificationStatusModal: React.FC<ISetVerificationStatus> = ({
	isOpen,
	onClose,
	selectedUsers,
	updateStatusLoading,
	onChangeVerificationStatus,
	removeUserFromSelected,
}) => {
	// const [radioValue, setRadioValue] = useState<number | string>(1);

	const usersIds = selectedUsers.map(user => user.id);
	// const isSuspended = radioValue === 1;

	return (
		<Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<HStack justifyContent="space-between">
						<Text fontSize="20px">Set verification status</Text>
						<IconButton
							onClick={onClose}
							aria-label="close-modal"
							icon={<CloseIcon />}
						/>
					</HStack>
				</ModalHeader>
				<ModalBody>
					<Text fontWeight={600} fontSize="16px" my="10px">
						Users
					</Text>
					<Text mb="5px" color="lightText" fontSize="16px">
						Check the current status of users, it will be reversed:
					</Text>
					{selectedUsers?.length ? (
						<Box borderRadius="10px" p="10px" bg="background">
							{selectedUsers?.map((user, index) => {
								return (
									<UserItem
										removeUserFromSelected={
											removeUserFromSelected
										}
										key={index}
										user={user}
									/>
								);
							})}
						</Box>
					) : null}
					{/* <Text fontWeight={600} fontSize="16px" my="10px">
						Status
					</Text>
					<Text mb="5px" color="lightText" fontSize="16px">
						Set verification status for all users:
					</Text>
					<CustomRadioButtonGroup
						items={radioButtons}
						value={radioValue}
						setValue={setRadioValue}
						px="10px"
					/> */}
				</ModalBody>
				<ModalFooter as={HStack} justifyContent="space-between">
					<Button
						flex={1}
						h="56px"
						colorScheme="lightBlueButton"
						onClick={onClose}>
						Cancel
					</Button>
					<Button
						isLoading={updateStatusLoading}
						isDisabled={updateStatusLoading}
						flex={1}
						h="56px"
						colorScheme="greenButton"
						onClick={() =>
							onChangeVerificationStatus(usersIds, true, onClose)
						}>
						Approve
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
