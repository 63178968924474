import { Dispatch, SetStateAction } from 'react';
import { API_IDENTITY } from '../../api/api.base';
import { Alerter } from '../../utils/Alerter';
import { IUser } from './types';

export const roles: { [key: number]: string } = {
	1: 'Admin',
	2: 'User',
	0: '-',
} as const;

export const nextRole: { [key: number]: number } = {
	1: 2,
	2: 1,
};

export const returnUserStatus = (currentStatus: boolean): string => {
	return currentStatus ? 'Disabled' : 'Active';
};

export const returnUserRole = (currentRoleId: number | null): string | null => {
	if (currentRoleId) {
		switch (currentRoleId) {
			case 1:
				return 'User';
			case 2:
				return 'Admin';
			default:
				return 'No such role';
		}
	}
	return null;
};

export function isFileTypeChecker(value: any): value is File {
	return value instanceof File;
}

export const uploadMultipleUsers = async (formData: FormData) => {
	const response = await API_IDENTITY.post(
		'/api/admin/users/invite-from-file',
		formData,
	);

	if (response.success) {
		Alerter.success(response.messages[0].message);
	} else {
		Alerter.error(response.errors[0].message);
	}

	return response;
};

export const changePermissionReq = async (
	userId: number,
	newPermissionId: number,
	isNotified: boolean,
) => {
	const response = await API_IDENTITY.post(
		'/api/admin/users/update-permission',
		{
			userId: userId,
			permissionId: newPermissionId,
			isNotified: isNotified,
		},
	);

	if (response.success) {
		Alerter.success(response.messages[0].message);
	} else {
		Alerter.error(response.errors[0].message);
	}
	return response;
};

export const reinviteUserReq = async (id: number) => {
	const response = await API_IDENTITY.post(
		`/api/admin/users/${id}/reinvite`,
		{},
	);
	if (response.success) {
		Alerter.success(response.messages[0].message);
	} else {
		Alerter.error(response.errors[0].message);
	}
	return response;
};

export const reinviteUsersReq = async (userIds: number[]) => {
	const response = await API_IDENTITY.post('/api/admin/users/reinvite', {
		userIds: userIds,
	});
	if (response.success) {
		Alerter.success(response.messages[0].message);
	} else {
		Alerter.error(response.errors[0].message);
	}
	return response;
};

export const deleteUsersRequest = async (
	userIds: number[],
	onSuccessAction?: () => void,
) => {
	const response = await API_IDENTITY.delete('/api/admin/users/delete', {
		userIds: userIds,
	});

	if (response.success) {
		onSuccessAction?.();
		Alerter.success(response.messages[0].message);
	} else {
		Alerter.error(response.errors[0].message);
	}
	return response;
};

export const changeUserStatusRequest = async (
	id: number,
	newStatus: boolean,
) => {
	const response = await API_IDENTITY.post('/api/admin/users/change-state', {
		userId: id,
		isSuspended: newStatus,
	});

	if (response.success) {
		Alerter.success(response.messages[0].message);
	} else {
		Alerter.error(response.errors[0].message);
	}
	return response;
};

export const changeUserRoleRequest = async (id: number, roleId: number) => {
	const response = await API_IDENTITY.post('/api/admin/users/update', {
		userId: id,
		roleId: roleId,
	});

	if (response.success) {
		Alerter.success(response.messages[0].message);
	} else {
		Alerter.error(response.errors[0].message);
	}
	return response;
};

export const fetchUsers = async (
	currentPage?: number,
	pageSize?: number,
	fullName?: string,
	email?: string,
	permissionId?: string,
	inviteStatus?: string,
	riskLevel?: string,
): Promise<{ users: IUser[]; totalCount: number }> => {
	const response = await API_IDENTITY.get('/api/admin/users', {
		currentPage: currentPage ? currentPage : undefined,
		pageSize: pageSize ? pageSize : undefined,
		fullName: fullName ? fullName : undefined,
		email: email ? email : undefined,
		permissionId,
		statusId: inviteStatus,
		riskLevel,
	});

	return response.value;
};

export const mainCheckboxStatus = (
	userIdsForDeletion: number[],
	usersList: IUser[],
	allUsersSelected: boolean,
	setAllUsersSelected: Dispatch<SetStateAction<boolean>>,
) => {
	/*if(!userIdsForDeletion.length) return;*/
	const usersListIds = usersList.map(user => user.id);
	for (let i = 0; i < usersListIds.length; i++) {
		const temp = userIdsForDeletion.includes(usersListIds[i]);
		if (!temp) {
			allUsersSelected ? setAllUsersSelected(false) : null;
			return;
		}
	}
	if (!usersList.length) {
		setAllUsersSelected(false);
		return;
	}
	setAllUsersSelected(true);
};

// unique objects
export function filterUniqueObjects(usersList: IUser[]): IUser[] {
	const uniqueArray = [];

	for (let i = 0; i < usersList.length; i++) {
		let isUnique = true;

		for (let j = i + 1; j < usersList.length; j++) {
			if (isEqual(usersList[i], usersList[j])) {
				isUnique = false;
				break;
			}
		}

		if (isUnique) {
			uniqueArray.push(usersList[i]);
		}
	}

	return uniqueArray;
}

function isEqual(obj1: IUser, obj2: IUser): boolean {
	// Implement your own comparison logic here
	// This example assumes shallow equality check
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false; // Different number of keys
	}

	for (const key of keys1) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		if (obj1[key] !== obj2[key]) {
			return false; // Different values for a key
		}
	}

	return true; // Objects are equal
}
