import React from 'react';
import { Box } from '@chakra-ui/react';
import { usePDF } from 'react-to-pdf';
import { CourseAnalyticsItem } from '../../types';
import { AnalyticsDetails, CoursesList, PDFDocument } from './components';

interface ICourseAnalyticsProps {
	data: CourseAnalyticsItem[];
	name?: string;
	email?: string;
	lastLoginDate?: string;
	numberOfLogins?: number;
	documentLink?: string | null;
}

export const CourseAnalytics: React.FC<ICourseAnalyticsProps> = ({
	data,
	name,
	email,
	lastLoginDate,
	numberOfLogins,
	documentLink,
}) => {
	const { toPDF, targetRef } = usePDF({ filename: 'course_analytics.pdf' });
	return (
		<Box>
			<Box position="absolute" left="10000px">
				<Box ref={targetRef}>
					<PDFDocument
						email={email}
						name={name}
						lastLoginDate={lastLoginDate}
						numberOfLogins={numberOfLogins}
						courses={data}
					/>
				</Box>
			</Box>
			<AnalyticsDetails
				toPDF={toPDF}
				email={email}
				name={name}
				lastLoginDate={lastLoginDate}
				numberOfLogins={numberOfLogins}
				documentLink={documentLink}
			/>
			<CoursesList courses={data} />
		</Box>
	);
};
