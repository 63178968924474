import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import { API_IDENTITY } from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';
import { queryClient } from '../../../main';

export const useDeletePermissionGroup = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: payload =>
			API_IDENTITY.delete(`/api/admin/permissions/${payload}`),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
			} else {
				queryClient.invalidateQueries('permission-groups');
			}
		},
		onError: () => {
			Alerter.error('Error while deleting permission group');
		},
	});
};
