import React from 'react';
import { AlertDialogWrapper } from '../../../components';
import { Text } from '@chakra-ui/react';
import { useDeleteCourse } from '../queries';

interface IDeleteCourseModal {
	isOpen: boolean;
	onClose: () => void;
	id: number;
}
export const DeleteCourseModal: React.FC<IDeleteCourseModal> = ({
	isOpen,
	onClose,
	id,
}) => {
	const { isLoading, onDeleteCourse } = useDeleteCourse();

	return (
		<AlertDialogWrapper
			size={'xl'}
			isOpen={isOpen}
			onClose={onClose}
			acceptButtonProps={{
				isLoading: isLoading,
				isDisabled: isLoading,
			}}
			acceptCallback={() => {
				if (id) {
					onDeleteCourse(id, {
						onSuccess: () => {
							onClose();
						},
					});
				}
			}}>
			<Text
				fontWeight={700}
				color={'darkBlue'}
				fontSize={'2xl'}
				textAlign={'center'}>
				Are you sure you want to delete this course?
			</Text>
		</AlertDialogWrapper>
	);
};
