import React, { useEffect, useState } from 'react';
import {
	Button,
	HStack,
	IconButton,
	VStack,
	Box,
	Center,
	Spinner,
} from '@chakra-ui/react';
import { FieldWrapper, FileInput } from '../../../components';
import { InputText, InputTextArea } from '../../../../../components';
import {
	LeftPaginationArrowIcon,
	TrashIcon,
} from '../../../../../assets/icons';
import { Controller, useForm } from 'react-hook-form';
import {
	CreateAuthorForm,
	createAuthorResolver,
} from '../../../../../validation';
import {
	useCreateAuthor,
	useDeleteAuthor,
	useEditAuthor,
	useGetAuthorById,
} from '../../../queries';
import { allowImageMime } from '../../../constants';
import { PreviewAuthorPhoto } from './PreviewAuthorPhoto';

interface IAddAuthorForm {
	onClose: () => void;
	idToEdit: number;
}
export const AddAuthorForm: React.FC<IAddAuthorForm> = ({
	onClose,
	idToEdit,
}) => {
	const { data, isLoading: isAuthorLoading } = useGetAuthorById(idToEdit);

	const { isLoading: deleteLoading, onDeleteAuthor } = useDeleteAuthor();

	const { isLoading: editLoading, onEditAuthor } = useEditAuthor();

	const {
		reset,
		formState: { errors },
		handleSubmit,
		control,
		register,
		setValue,
		watch,
	} = useForm<CreateAuthorForm>({
		resolver: createAuthorResolver,
		defaultValues: {
			description: '',
			name: '',
			image: undefined,
			isImageUploaded: false,
		},
	});

	const [imagePreview, setImagePreview] = useState('');

	const { isLoading, onCreateAuthor } = useCreateAuthor();

	const imageFile = watch('image');

	const onRemoveImg = () => {
		setValue('image', undefined, { shouldValidate: true });
		setValue('isImageUploaded', false, { shouldValidate: true });
		URL.revokeObjectURL(imagePreview);
		setImagePreview('');
	};
	const onResetForm = () => {
		URL.revokeObjectURL(imagePreview);
		setImagePreview('');
		reset({
			description: '',
			name: '',
			image: undefined,
			isImageUploaded: false,
		});
		onClose();
	};

	const onSubmit = (values: CreateAuthorForm) => {
		const formData = new FormData();
		if (values.image) {
			formData.append('Image', values.image);
		}
		formData.append('Name', values.name);
		formData.append('Description', values.description);

		if (idToEdit) {
			onEditAuthor(
				{
					authorId: idToEdit,
					formData,
				},
				{
					onSuccess: () => {
						onResetForm();
					},
				},
			);
		} else {
			onCreateAuthor(formData, {
				onSuccess: () => {
					onResetForm();
				},
			});
		}
	};

	useEffect(() => {
		if (imageFile) {
			setImagePreview(URL.createObjectURL(imageFile));
		}
	}, [imageFile]);

	useEffect(() => {
		setImagePreview('');
		if (data) {
			reset({
				name: data.name,
				description: data.description,
				image: undefined,
				isImageUploaded: true,
			});
			setImagePreview(data.imageLink);
		}
	}, [data]);

	return (
		<>
			{isAuthorLoading ? (
				<Center mt={'20px'} height={'200px'}>
					<Spinner />
				</Center>
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<VStack mt={'20px'} align={'stretch'} spacing={'20px'}>
						<FieldWrapper position={'relative'}>
							<InputText
								label={'Author Name'}
								placeholder={'Enter'}
								errorMsg={errors.name?.message}
								{...register('name')}
							/>
							<HStack
								top={'10px'}
								right={'20px'}
								pos={'absolute'}>
								{!!idToEdit && (
									<Button
										isLoading={deleteLoading}
										onClick={() => {
											if (idToEdit) {
												onDeleteAuthor(idToEdit, {
													onSuccess: onResetForm,
												});
											}
										}}
										colorScheme={'lightBlueButton'}
										rightIcon={<TrashIcon />}>
										Delete Author
									</Button>
								)}
								<IconButton
									onClick={onResetForm}
									aria-label={'close form'}
									icon={
										<Box transform={'rotate(-90deg)'}>
											<LeftPaginationArrowIcon />
										</Box>
									}
								/>
							</HStack>
						</FieldWrapper>
						<FieldWrapper>
							<InputTextArea
								label={'About Author'}
								placeholder={'Enter'}
								errorMsg={errors.description?.message}
								{...register('description')}
							/>
						</FieldWrapper>
						{imagePreview ? (
							<PreviewAuthorPhoto
								imgUrl={imagePreview}
								onRemove={onRemoveImg}
								label={'Author Photo'}
							/>
						) : (
							<Controller
								render={({ fieldState }) => (
									<FileInput
										typesPlaceholder={
											'(Only .png .bmp .jpeg .jpg .gif images will be accepted)'
										}
										accept={allowImageMime}
										errorMsg={fieldState.error?.message}
										onSetFile={(value?: File) => {
											setValue('image', value, {
												shouldValidate: true,
											});
											setValue('isImageUploaded', true);
										}}
										label={'Author Photo'}
									/>
								)}
								name={'image'}
								control={control}
							/>
						)}
						<Button
							isDisabled={
								isLoading || deleteLoading || editLoading
							}
							isLoading={isLoading || editLoading}
							type={'submit'}
							borderColor={'#08BB4B'}
							color={'#08BB4B'}
							size={'lg'}
							variant={'outline'}>
							Save
						</Button>
					</VStack>
				</form>
			)}
		</>
	);
};
