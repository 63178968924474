import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';
import { TimelineTypeEnum } from '../pages/UserDetails/constants';

const selectSchema = yup
	.object()
	.shape({
		value: yup.mixed(),
		label: yup.string(),
	})
	.nullable();

const fileSchema = yup.array().of(
	yup.object().shape({
		file: yup.mixed<File>(),
	}),
);

const timelineSchema = yup.object().shape({
	type: yup
		.mixed()
		.oneOf(Object.values(TimelineTypeEnum))
		.required(ErrorMessages.REQUIRED),
	date: yup.string(),
	timeFrom: selectSchema,
	timeTo: selectSchema,
	// files: fileSchema,
	topic: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.when('type', {
			is: TimelineTypeEnum.Call,
			then: schema => schema,
			otherwise: schema => schema.notRequired(),
		}),
	callNote: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.when('type', {
			is: TimelineTypeEnum.Call,
			then: schema => schema,
			otherwise: schema => schema.notRequired(),
		}),
	note: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.when('type', {
			is: TimelineTypeEnum.Note,
			then: schema => schema,
			otherwise: schema => schema.notRequired(),
		}),
	message: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.when('type', {
			is: TimelineTypeEnum.DirectMessage,
			then: schema => schema,
			otherwise: schema => schema.notRequired(),
		}),
	email: yup
		.string()
		.email()
		.required()
		.when('type', {
			is: TimelineTypeEnum.Email,
			then: schema => schema,
			otherwise: schema => schema.notRequired(),
		}),
	subject: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.when('type', {
			is: TimelineTypeEnum.Email,
			then: schema => schema,
			otherwise: schema => schema.notRequired(),
		}),
	bodyText: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.when('type', {
			is: TimelineTypeEnum.Email,
			then: schema => schema,
			otherwise: schema => schema.notRequired(),
		}),
	imageIdsToDelete: yup.array().of(yup.number()).default([]),
	fileIdsToDelete: yup.array().of(yup.number()).default([]),
});

export type TimelineFormValues = yup.InferType<typeof timelineSchema>;

export const timelineResolver = yupResolver(timelineSchema);
