import React from 'react';
import { SectionItemType } from '../../../types';
import { SectionForm } from './SectionForm';
import { CollapseWrapper } from './CollapseWrapper';
import { CollapseHeader } from './CollapseHeader';

interface ISectionItem {
	item: SectionItemType;
	count: number;
	courseId: number;
}
export const SectionItem: React.FC<ISectionItem> = ({
	item,
	count,
	courseId,
}) => {
	return (
		<CollapseWrapper
			defaultIsOpen={item.lessons.length === 0}
			TopElement={props => (
				<CollapseHeader
					title={`Section ${count}`}
					name={item.name}
					{...props}
				/>
			)}
			BottomElement={props => (
				<SectionForm
					sectionId={item.id}
					courseId={courseId}
					{...props}
				/>
			)}
		/>
	);
};
