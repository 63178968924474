import React from 'react';
import { AlertDialogWrapper } from '../../../components';
import { Text } from '@chakra-ui/react';
import { usePublishCourse } from '../queries';

interface IPublishCourseModal {
	isOpen: boolean;
	onClose: () => void;
	id: number;
}
export const PublishCourseModal: React.FC<IPublishCourseModal> = ({
	isOpen,
	onClose,
	id,
}) => {
	const { isLoading, onPublish } = usePublishCourse();

	return (
		<AlertDialogWrapper
			size={'xl'}
			isOpen={isOpen}
			onClose={onClose}
			acceptButtonProps={{
				isLoading,
				isDisabled: isLoading,
			}}
			acceptCallback={() => {
				if (id) {
					onPublish(id, {
						onSuccess: onClose,
					});
				}
			}}>
			<Text
				fontWeight={700}
				color={'darkBlue'}
				fontSize={'2xl'}
				textAlign={'center'}>
				Are you sure you want to publish this course?
			</Text>
		</AlertDialogWrapper>
	);
};
