import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
	safeRateValue,
	safeParseNumber,
	getDegrees,
	getProgressBackground,
} from '../../../../../utils';
import { colors } from '../../../../../theme/colors';

type ICircleProgressBarProps = {
	progress: number;
	color?: string;
	labelTotal?: number;
	labelCurrent?: number;
};

export const CircleProgressBar: React.FC<ICircleProgressBarProps> = ({
	progress,
	color = colors.inactiveBlue,
	labelCurrent = 0,
	labelTotal = 0,
}) => {
	const score = safeRateValue(safeParseNumber(progress));
	const degrees = getDegrees(score);
	const progressBackground = getProgressBackground(degrees, color);

	return (
		<Box
			boxSize="144px"
			position="relative"
			borderRadius="50%"
			background={`conic-gradient(${progressBackground})`}
			_before={{
				content: '""',
				position: 'absolute',
				inset: '16px',
				backgroundColor: 'white',
				borderRadius: '50%',
			}}>
			<Box
				position="absolute"
				top="50%"
				left="50%"
				transform="translate(-50%, -50%)">
				<Text
					textAlign="center"
					fontSize="24px"
					fontWeight={500}
					color="darkBlue">
					{labelCurrent}/{labelTotal}
				</Text>
				<Text
					textAlign="center"
					color="darkBlue"
					fontSize="12px"
					fontWeight={600}
					mt="2px">
					Factors
				</Text>
			</Box>
		</Box>
	);
};
