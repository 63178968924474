import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { AnalyticsDetailsItem } from './AnalyticsDetailsItem';
import { DownloadIcon } from '../../../../../../assets/icons';
import { USDateFormat } from '../../../../../../constants';

interface IAnalyticsDetailsProps {
	name?: string;
	email?: string;
	lastLoginDate?: string;
	numberOfLogins?: number;
	documentLink?: string | null;
	toPDF: () => void;
}
export const AnalyticsDetails: React.FC<IAnalyticsDetailsProps> = ({
	name,
	email,
	lastLoginDate,
	numberOfLogins,
	toPDF,
}) => {
	return (
		<Box p="20px" borderRadius="10px" bg="white">
			<Flex
				gap={{ base: '10px', xl: '40px' }}
				flexDirection={{ base: 'column', xl: 'row' }}>
				<Box>
					<AnalyticsDetailsItem label="Email" value={email} />
					<AnalyticsDetailsItem label="Name" value={name} />
				</Box>
				<Box>
					<AnalyticsDetailsItem
						label="Last Login Date"
						value={
							lastLoginDate
								? dayjs(lastLoginDate)?.format(USDateFormat)
								: '-'
						}
					/>
					<AnalyticsDetailsItem
						label="Number of Logins"
						value={numberOfLogins}
					/>
				</Box>
				<Button
					onClick={() => toPDF()}
					leftIcon={<DownloadIcon />}
					colorScheme="greenButton">
					Download PDF
				</Button>
			</Flex>
		</Box>
	);
};
