import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface IPayload {
	courseId: number;
	sectionId: number;
}
export const useDeleteSection = () => {
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		IPayload
	>({
		mutationFn: payload =>
			Api.delete(
				`/api/admin/courses/${payload.courseId}/third-step/section/${payload.sectionId}`,
			),
		onSuccess: (data, { courseId }) => {
			if (data.success) {
				Alerter.success('Section successfully deleted');
				queryClient.invalidateQueries(['sections', courseId]);
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while deleting author');
		},
	});
	return {
		isLoading,
		onDeleteSection: mutate,
	};
};
