import React, { useEffect, useState } from 'react';
import { reinviteUserReq } from '../helper';

interface ReturnType {
	isLoading: boolean;
	setIdReinvite: React.Dispatch<React.SetStateAction<number>>;
}

export const useUserReinvitation = (): ReturnType => {
	const [isLoading, setIsLoading] = useState(false);
	const [id, setIdReinvite] = useState(0);

	async function startInviteUserReq() {
		const result = await reinviteUserReq(id);
		if (result) {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (id) {
			startInviteUserReq();
			setIsLoading(true);
			setIdReinvite(0);
		}
	}, [id]);

	return {
		isLoading,
		setIdReinvite,
	};
};
