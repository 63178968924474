import { useMutation, useQueryClient } from 'react-query';
import Api from '../../../api/api.base';
import { CreateSectionForm } from '../../../validation/create-section.schema';
import { Alerter } from '../../../utils/Alerter';
import { ApiResponse } from '../../../types';

interface IPayload {
	courseId: number;
	body: CreateSectionForm;
}
export const useCreateSection = () => {
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation<
		ApiResponse<number>,
		unknown,
		IPayload
	>({
		mutationFn: async payload =>
			Api.post(
				`/api/admin/courses/${payload.courseId}/third-step/section`,
				payload.body,
			),
		onSuccess: (data, { courseId }) => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			} else {
				queryClient.invalidateQueries(['sections', courseId]);
			}
		},
		onError: () => {
			Alerter.error('Error while creating section');
		},
	});

	return {
		isLoading,
		onCreateSection: mutate,
	};
};
