import React, { useEffect, useState } from 'react';
import { Flex, Td, Tr, Text, IconButton } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
	CustomCheckbox,
	CustomSwitch,
	RenderTd,
} from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { VerificationUser } from '../../types';
import {
	CheckIcon,
	CircleArrowsIcon,
	CloseIcon,
} from '../../../../../assets/icons';

interface ITableRowProps {
	updateStatusLoading: boolean;
	lastItem: boolean;
	selectedUsers: VerificationUser[];
	user: VerificationUser;
	onChangeVerificationStatus: (
		userIds: number[],
		communityAllow: boolean,
		onClose?: VoidFunction,
	) => void;
	addUserToSelected: (user: VerificationUser) => void;
	removeUserFromSelected: (user: VerificationUser) => void;
	onUpdateChannelsBanStatus: (
		userId: number,
		banned: boolean,
		onSuccess?: VoidFunction,
		onError?: VoidFunction,
	) => void;
	updateChannelBanLoading: boolean;
}
export const TableRow: React.FC<ITableRowProps> = ({
	lastItem,
	user,
	onChangeVerificationStatus,
	updateStatusLoading,
	addUserToSelected,
	removeUserFromSelected,
	selectedUsers,
	onUpdateChannelsBanStatus,
	updateChannelBanLoading,
}) => {
	const {
		id,
		fullName,
		signInDate,
		email,
		communityStatus,
		isChannelsBanned,
		roleId,
	} = user;

	const [showChangeStatusView, setShowChangeStatusView] = useState(false);
	const [showChangeBanStatusView, setShowChangeBanStatusView] =
		useState(false);
	const [isChecked, setIsChecked] = useState(communityStatus);
	const [isUserChannelsBanned, setIsUserChannelsBanned] = useState(
		!isChannelsBanned,
	); //inversion because of switch component and design

	const isSelected = selectedUsers.some(user => user.id === id);
	const isAdmin = roleId === 1;
	const isAvailableToBan = !isAdmin && communityStatus;

	useEffect(() => {
		setIsChecked(communityStatus);
	}, [communityStatus]);

	const onErrorUpdateChannelsBanStatus = () => {
		setShowChangeBanStatusView(false);
		setIsUserChannelsBanned(!isChannelsBanned);
	};

	return (
		<Tr bg="white">
			<Td
				w="50px"
				maxW="50px"
				px="13px"
				py="9px"
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				borderRight={`1px solid ${colors.dropBlue}`}>
				<CustomCheckbox
					isChecked={isSelected}
					onChange={() => {
						if (isSelected) {
							removeUserFromSelected(user);
						} else {
							addUserToSelected(user);
						}
					}}
				/>
			</Td>
			<RenderTd
				value={fullName}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={signInDate ? dayjs(signInDate).format('MM/DD/YY') : '-'}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<RenderTd
				value={email}
				borderRight={`1px solid ${colors.dropBlue}`}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				p="9px 16px"
			/>
			<Td
				w="220px"
				h="50px"
				p="10px"
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}
				borderRight={`1px solid ${colors.dropBlue}`}>
				<Flex
					gap={showChangeStatusView ? '5px' : '15px'}
					justifyContent="center"
					alignItems="center"
					w="200px"
					h="34px">
					{showChangeStatusView ? (
						<>
							<CustomSwitch
								setIsChecked={setIsChecked}
								isChecked={isChecked}
								leftLabel="Rejected"
								rightLabel="Approved"
							/>
							<IconButton
								bg="transparent"
								onClick={() => {
									setShowChangeStatusView(false);
									setIsChecked(communityStatus);
								}}
								minW="24px"
								h="24px"
								style={{ width: '24px', height: '24px' }}
								aria-label="show-controls"
								icon={<CloseIcon size="14" color="#F35050" />}
							/>
							<IconButton
								bg="transparent"
								onClick={() =>
									onChangeVerificationStatus(
										[id],
										isChecked,
										() => setShowChangeStatusView(false),
									)
								}
								isLoading={updateStatusLoading}
								isDisabled={updateStatusLoading}
								minW="24px"
								h="24px"
								aria-label="show-controls"
								icon={<CheckIcon color="#08BB4B" />}
							/>
						</>
					) : (
						<>
							<Text
								fontSize="16px"
								w="70px"
								textAlign="center"
								fontWeight={600}>
								{communityStatus ? 'Approved' : 'Rejected'}
							</Text>
							<IconButton
								onClick={() => setShowChangeStatusView(true)}
								minW="24px"
								h="24px"
								colorScheme="lightBlueButton"
								aria-label="show-controls"
								icon={<CircleArrowsIcon />}
							/>
						</>
					)}
				</Flex>
			</Td>
			<Td
				h="50px"
				p={isAvailableToBan ? '10px' : '0px'}
				bg={isAvailableToBan ? 'white' : colors.lightGray100}
				cursor={isAvailableToBan ? 'default' : 'not-allowed'}
				borderBottom={`1px solid ${
					lastItem ? 'transparent' : colors.dropBlue
				}`}>
				{isAvailableToBan ? (
					<Flex
						gap={showChangeBanStatusView ? '5px' : '15px'}
						justifyContent="center"
						alignItems="center"
						h="34px"
						w="160px">
						{showChangeBanStatusView ? (
							<>
								<CustomSwitch
									setIsChecked={setIsUserChannelsBanned}
									isChecked={isUserChannelsBanned}
									leftLabel="Yes"
									rightLabel="No"
									trackSize={45}
								/>
								<IconButton
									bg="transparent"
									onClick={() => {
										setShowChangeBanStatusView(false);
										setIsUserChannelsBanned(
											!isChannelsBanned,
										);
									}}
									minW="24px"
									h="24px"
									style={{ width: '24px', height: '24px' }}
									aria-label="show-controls"
									icon={
										<CloseIcon size="14" color="#F35050" />
									}
								/>
								<IconButton
									bg="transparent"
									onClick={() =>
										onUpdateChannelsBanStatus(
											id,
											!isUserChannelsBanned, // compensation for initial inversion
											() =>
												setShowChangeBanStatusView(
													false,
												),
											() =>
												onErrorUpdateChannelsBanStatus(),
										)
									}
									isLoading={updateChannelBanLoading}
									isDisabled={updateChannelBanLoading}
									minW="24px"
									h="24px"
									aria-label="show-controls"
									icon={<CheckIcon color="#08BB4B" />}
								/>
							</>
						) : (
							<>
								<Text
									fontSize="16px"
									w="30px"
									textAlign="center"
									fontWeight={600}>
									{isChannelsBanned ? 'Yes' : 'No'}
								</Text>
								<IconButton
									onClick={() =>
										setShowChangeBanStatusView(true)
									}
									minW="24px"
									h="24px"
									colorScheme="lightBlueButton"
									aria-label="show-controls"
									icon={<CircleArrowsIcon />}
								/>
							</>
						)}
					</Flex>
				) : (
					<Flex
						w="160px"
						justifyContent="center"
						alignItems="center"
						h="34px">
						<Text color={colors.lightGray200} fontWeight={500}>
							Unavailable
						</Text>
					</Flex>
				)}
			</Td>
		</Tr>
	);
};
