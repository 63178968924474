import { useQuery } from 'react-query';
import { fetchUsers } from '../helper';

export const useGetUsers = (
	fullName?: string,
	email?: string,
	pageSize?: number,
	currentPage?: number,
	permissionId?: string,
	inviteStatus?: string,
	riskLevel?: string,
) => {
	return useQuery(
		[
			'usersList',
			currentPage,
			pageSize,
			fullName,
			email,
			permissionId,
			inviteStatus,
			riskLevel,
		],
		() =>
			fetchUsers(
				currentPage,
				pageSize,
				fullName,
				email,
				permissionId,
				inviteStatus,
				riskLevel,
			),
	);
};
