import React from 'react';
import { Box, Text, Flex, Checkbox } from '@chakra-ui/react';
import { permissionGroups } from '../../../types';

interface IListOfAvailablePagesProps {
	selectedValues: number[];
	onSelect: (value: number) => void;
}

export const ListOfAvailablePages: React.FC<IListOfAvailablePagesProps> = ({
	selectedValues,
	onSelect,
}) => {
	return (
		<Box mt="15px">
			<Text color="darkBlue" fontSize="16px" fontWeight={700}>
				Available pages
			</Text>
			<Flex flexDirection="column" gap="16px" mt="24px">
				{permissionGroups.map(elem => {
					return (
						<Checkbox
							size="lg"
							colorScheme="greenButton"
							key={elem.id}
							isChecked={selectedValues.includes(elem.id)}
							onChange={ev => onSelect(elem.id)}>
							{elem.name}
						</Checkbox>
					);
				})}
			</Flex>
		</Box>
	);
};
