import { FC } from 'react';
import { Box, Button, Flex, HStack, Image, Text } from '@chakra-ui/react';
import pdLogo from '../../../../../../assets/images/pd-logo.png';
import { greenButton } from '../../../../../../theme/elementStyles';
import { DownloadIcon } from '@chakra-ui/icons';

interface PandadockFileProps {
	isDocumentCompleted: boolean;
	documentLink?: string;
}

export const PandadockFile: FC<PandadockFileProps> = ({
	isDocumentCompleted,
	documentLink,
}) => {
	return (
		<Box>
			<HStack spacing={'20px'}>
				<Image src={pdLogo} alt={''} />
				<HStack spacing={'10px'}>
					<Text color={'lightText'}>Status:</Text>
					<Text fontWeight={'700'}>
						{isDocumentCompleted ? 'Completed' : 'Not Completed'}
					</Text>
				</HStack>
			</HStack>

			<Flex mt={'10px'}>
				<Button
					{...greenButton}
					isDisabled={!documentLink}
					onClick={() => {
						if (documentLink) {
							window.open(documentLink, '_blank');
						}
					}}
					maxH={'40px'}
					leftIcon={<DownloadIcon />}>
					Download
				</Button>
			</Flex>
		</Box>
	);
};
