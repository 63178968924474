import { SectionWrapper } from '../SectionWrapper';
import { VStack } from '@chakra-ui/react';
import React from 'react';
import { TimelinePlaceholder } from './TimelinePlaceholder';
import { TimelineHeader } from './TimelineHeader';
import { TimelineItem } from './TimelineItem';
import { useParams } from 'react-router-dom';
import { useGetTimelineList } from '../../../../hooks';
import { TimelineSkeletonList } from './TimelineSkeletonList';
import { useTimelineStore } from '../../../../store';
import { TimelineModal } from './TimelineModal';
import { useGetCurrentUser } from '../../../../../../hooks';

export const TimeLineSection: React.FC = () => {
	const params = useParams();

	const isOpen = useTimelineStore(state => state.isOpen);
	const onClose = useTimelineStore(state => state.onClose);

	const { data: userData, isLoading: userLoading } = useGetCurrentUser();

	const { data, isLoading } = useGetTimelineList(Number(params?.id));

	return (
		<>
			<SectionWrapper
				borderRadius={'10px'}
				flex={{ base: '1 1 400px', md: 1 }}>
				<TimelineHeader />
				<VStack
					h={'calc(100% - 60px)'}
					maxH={'440px'}
					align={'stretch'}
					overflowY={'auto'}>
					{isLoading || userLoading ? (
						<TimelineSkeletonList />
					) : !data?.value?.timeline.length ? (
						<TimelinePlaceholder />
					) : (
						<>
							{data.value.timeline.map(it => (
								<TimelineItem
									userId={Number(params?.id)}
									item={it}
									key={it.id}
									currentUserId={Number(userData?.id)}
								/>
							))}
						</>
					)}
				</VStack>
			</SectionWrapper>
			<TimelineModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};
