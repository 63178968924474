import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const confirmAdminRoleSchema = yup.object().shape({
	text: yup
		.string()
		.oneOf(['ADMIN'], 'Invalid value')
		.required(ErrorMessages.REQUIRED),
});

export type ConfirmAdminRoleForm = yup.InferType<typeof confirmAdminRoleSchema>;

export const confirmAdminRoleResolver = yupResolver(confirmAdminRoleSchema);
