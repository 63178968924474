import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

interface IPayload {
	courseId: number;
	sectionId: number;
	lessonId: number;
}

export const useDeleteLesson = () => {
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		IPayload
	>({
		mutationFn: ({ courseId, sectionId, lessonId }) =>
			Api.delete(
				`/api/admin/courses/${courseId}/third-step/section/${sectionId}/lesson/${lessonId}`,
			),
		onSuccess: (data, { courseId, sectionId }) => {
			if (data.success) {
				queryClient.invalidateQueries(['section', courseId, sectionId]);
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while deleting lesson');
		},
	});
	return {
		isLoading,
		onDeleteLesson: mutate,
	};
};
