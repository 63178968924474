import { Center, Text } from '@chakra-ui/react';

export const TimelinePlaceholder: React.FC = () => {
	return (
		<Center bg={'background'} borderRadius={'8px'} h={'full'}>
			<Text variant={'bodyMedium'} color={'lightText'}>
				Nothing has been added yet
			</Text>
		</Center>
	);
};
