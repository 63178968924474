import { InputText, InputTextArea } from '../../../../../../../components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TimelineFormValues } from '../../../../../../../validation';

export const EmailForm: React.FC = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext<TimelineFormValues>();

	return (
		<>
			<InputText
				placeholder={'Enter email'}
				{...register('email')}
				isInvalid={!!errors.email?.message}
			/>
			<InputText
				placeholder={'Enter subject'}
				{...register('subject')}
				isInvalid={!!errors.subject?.message}
			/>
			<InputTextArea
				placeholder={'Enter body text'}
				{...register('bodyText')}
				isInvalid={!!errors.bodyText?.message}
			/>
		</>
	);
};
