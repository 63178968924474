/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	cloneElement,
	isValidElement,
	ReactElement,
	useState,
} from 'react';
import {
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	HStack,
	Box,
	Divider,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { months } from './helper';
import { PickerCustomHeader, PickerMonthYearSelector } from './components';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import { CalendarIcon, CloseIcon } from '../../assets/icons';

interface IDatePickerProps {
	value?: string | null;
	onSetValue?: (value: string | null) => void;
	isRange?: boolean;
	customPopoverTrigger?: ReactElement<{
		value?: string | null;
		placeholder?: string;
		errMsg?: string;
		onOpen?: VoidFunction;
	}>;
	placeholder?: string;
	errMsg?: string;
}
export const DatePicker: React.FC<IDatePickerProps> = ({
	value,
	onSetValue,
	isRange,
	customPopoverTrigger,
	placeholder = 'Date',
	errMsg,
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const [startDate, setStartDate] = useState<Date | null>(
		value ? new Date(value) : new Date(),
	);
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(
		months[new Date().getMonth()],
	);

	const minYear = new Date().getFullYear() - 70;
	const maxYear = new Date().getFullYear() + 11;

	const onChange = (date: Date) => {
		setStartDate(date);
		onSetValue?.(dayjs(date).format('YYYY-MM-DD'));
		onClose();
	};
	const onChangeRange = (dates: Array<Date | null>) => {
		const [start, end] = dates || [];
		setStartDate(start);
		setEndDate(end);
		onSetValue?.(
			`${dayjs(start).format('YYYY-MM-DD') || ''}, ${
				dayjs(end).format('YYYY-MM-DD') || ''
			}`,
		);
	};

	const setToday = () => {
		const currentDate = new Date();
		setSelectedMonth(months[currentDate.getMonth()]);
		setSelectedYear(currentDate.getFullYear());
	};

	return (
		<Popover isOpen={isOpen} onClose={onClose}>
			<PopoverTrigger>
				{isValidElement(customPopoverTrigger) ? (
					cloneElement(customPopoverTrigger, {
						value,
						placeholder,
						errMsg,
						onOpen,
					})
				) : (
					<HStack>
						<IconButton
							onClick={onOpen}
							size="sm"
							icon={<CalendarIcon />}
							aria-label="calendar"
							bg="transparent"
							_hover={{}}
							_active={{}}
							_focus={{}}
						/>
						<Text cursor="pointer">{value || placeholder}</Text>
						{value ? (
							<IconButton
								size="sm"
								icon={<CloseIcon size="14" />}
								aria-label="remove-value"
								bg="transparent"
								_hover={{}}
								_active={{}}
								_focus={{}}
								onClick={event => {
									event.stopPropagation();
									onSetValue?.(null);
								}}
							/>
						) : null}
					</HStack>
				)}
			</PopoverTrigger>
			<PopoverContent
				bg="white"
				p={0}
				css={{
					width: 'fit-content', // Set width to content's width
					height: 'fit-content', // Set height to content's height
					padding: 0, // Remove padding for a snug fit
				}}
				borderWidth={0}
				borderRadius="10px"
				justifyContent="center"
				alignItems="center"
				boxShadow="lg"
				zIndex={10}>
				<PopoverBody
					h="300px"
					zIndex={10}
					p={0}
					boxShadow="lg"
					borderRadius={0}>
					<HStack alignItems="flex-start">
						<Box p="20px">
							<ReactDatePicker
								renderCustomHeader={({
									date,
									decreaseMonth,
									increaseMonth,
									changeYear,
									changeMonth,
									prevMonthButtonDisabled,
									nextMonthButtonDisabled,
								}) => (
									<PickerCustomHeader
										selectedYear={selectedYear}
										selectedMonth={selectedMonth}
										changeYear={changeYear}
										changeMonth={changeMonth}
										date={date}
										decreaseMonth={decreaseMonth}
										increaseMonth={increaseMonth}
										prevMonthButtonDisabled={
											prevMonthButtonDisabled
										}
										nextMonthButtonDisabled={
											nextMonthButtonDisabled
										}
									/>
								)}
								onMonthChange={month => {
									const year = month.getFullYear();
									if (year !== selectedYear) {
										setSelectedYear(month.getFullYear());
									}
									setSelectedMonth(months[month.getMonth()]);
								}}
								selected={isRange ? undefined : startDate}
								startDate={isRange ? startDate : undefined}
								endDate={isRange ? endDate : undefined}
								onChange={event => {
									isRange
										? onChangeRange(
												event as Array<Date | null>,
										  )
										: onChange(event as Date);
								}}
								inline={true}
								selectsRange={!!isRange}
							/>
						</Box>
						<Divider
							h="300px"
							orientation="vertical"
							borderColor="gray.400"
							m={0}
						/>
						<PickerMonthYearSelector
							setToday={setToday}
							year={selectedYear}
							month={selectedMonth}
							setSelectedYear={setSelectedYear}
							setSelectedMonth={setSelectedMonth}
							minYear={minYear}
							maxYear={maxYear}
						/>
					</HStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
