import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useSteps } from '../../../hooks';
import { useCourseContext } from '../context';
import { steps } from '../constants';
import { Stepper } from '../components';
import {
	FirstStepTab,
	ThirdStepTab,
	SecondStepTab,
	FourthStepTab,
} from '../tabs';

export const EditCourse: React.FC = () => {
	const { id } = useParams();

	const { goToNext, goToPrevious, activeStep, setActiveStep } = useSteps({
		index: 0,
	});

	const { setCourseId } = useCourseContext();

	useEffect(() => {
		if (id) {
			setCourseId(Number(id));
		}
	}, [id]);

	return (
		<Box>
			<Tabs isLazy={true} isManual={true} index={activeStep}>
				<Flex>
					{steps.map((it, index) => {
						const isCompleted = index < activeStep;
						const isCurrent = index === activeStep;
						const isActive = isCompleted || isCurrent;
						return (
							<Stepper
								key={index}
								isActive={isActive}
								label={it.label}
								stepNumber={index + 1}
							/>
						);
					})}
				</Flex>
				<TabPanels>
					<TabPanel>
						<FirstStepTab goToNext={goToNext} />
					</TabPanel>
					<TabPanel>
						<SecondStepTab setActiveStep={setActiveStep} />
					</TabPanel>
					<TabPanel>
						<ThirdStepTab
							setActiveStep={setActiveStep}
							goToNext={goToNext}
							goToPrevious={goToPrevious}
						/>
					</TabPanel>
					<TabPanel>
						<FourthStepTab setActiveStep={setActiveStep} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
