import { Box, Button, VStack } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { greenButton } from '../../../../../../theme/elementStyles';
import { EditUserSection } from '../EditUserSection';
import { ANIMATION_DURATION } from '../../../CourseAnalytics/constants';
import { ChangePasswordForm } from './ChangePassword.form';

interface PasswordSectionProps {
	userId: number;
}

export const PasswordSection: React.FC<PasswordSectionProps> = ({ userId }) => {
	const [selectedOptionId, setSelectedOptionId] = useState(0);
	return (
		<Box flex={1}>
			<EditUserSection title={'Password'} isDisabled={false}>
				<AnimatePresence mode="wait">
					{selectedOptionId === 0 ? (
						<motion.div
							key="password-section"
							initial={{ opacity: 0, x: 500 }}
							animate={{ opacity: 1, x: 0 }}
							exit={{ opacity: 0, x: 500 }}
							transition={{ duration: ANIMATION_DURATION }}
							style={{ overflow: 'hidden' }}>
							<VStack>
								<Button
									{...greenButton}
									h="40px"
									w="100%"
									borderRadius="8px"
									fontWeight={600}
									onClick={() => setSelectedOptionId(1)}>
									Change Password
								</Button>
							</VStack>
						</motion.div>
					) : null}

					{selectedOptionId === 1 ? (
						<motion.div
							key="course_details"
							initial={{ opacity: 0, x: 500 }}
							animate={{ opacity: 1, x: 0 }}
							exit={{ opacity: 0, x: 500 }}
							transition={{ duration: ANIMATION_DURATION }}
							style={{ overflow: 'hidden' }}>
							<ChangePasswordForm
								onClose={() => setSelectedOptionId(0)}
								userId={userId}
							/>
						</motion.div>
					) : null}
				</AnimatePresence>
			</EditUserSection>
		</Box>
	);
};
