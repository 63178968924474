import { useMutation } from 'react-query';
import { API_IDENTITY } from '../../../api/api.base';
import {
	ApiResponse,
	PermissionGroup,
	CreatePermissionGroupPayload,
} from '../../../types';
import { queryClient } from '../../../main';
import { Alerter } from '../../../utils/Alerter';

export const useCreatePermissionGroup = (onClose: VoidFunction) => {
	return useMutation<
		ApiResponse<{ groups: PermissionGroup[] }>,
		unknown,
		CreatePermissionGroupPayload
	>({
		mutationFn: payload =>
			API_IDENTITY.post('/api/admin/permissions/add', payload),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
			} else {
				Alerter.success(data.messages?.[0].message);
				queryClient.invalidateQueries('permission-groups');
				onClose();
			}
		},
		onError: () => {
			Alerter.error('Error while creating permission group');
		},
	});
};
