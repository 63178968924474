import React from 'react';
import {
	Box,
	Flex,
	VStack,
	Text,
	IconButton,
	Image,
	Collapse,
	useDisclosure,
	Skeleton,
} from '@chakra-ui/react';
import { EditIcon, LeftPaginationArrowIcon } from '../../../../../assets/icons';
import { FieldWrapper, AttachmentItem } from '../../../components';
import { LessonItemType, SectionItemType } from '../../../types';
import { getFileNameWithExtension } from '../../../../../utils';

interface ISectionPreview {
	item: SectionItemType;
	sectionCountName: string;
	setActiveStep: (value: number) => void;
}

interface ILessonPreviewItem {
	countName: string;
	item: LessonItemType;
	onGoBack: () => void;
}
const LessonPreviewItem: React.FC<ILessonPreviewItem> = ({
	item,
	countName,
	onGoBack,
}) => {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Box bg={'background'} p={'20px'} borderRadius={'10px'}>
			<Box bg={'background'} pb={'20px'}>
				<Flex align={'center'} justify={'space-between'}>
					<Flex align={'center'}>
						<Text
							fontWeight={'700'}
							color={'darkBlue'}
							fontSize={'xl'}>
							{countName}
						</Text>
						<IconButton
							bg={'white'}
							ml={'20px'}
							onClick={onGoBack}
							aria-label={'edit lesson'}
							icon={<EditIcon fillColor={'#08BB4B'} />}
						/>
					</Flex>
					<IconButton
						onClick={onToggle}
						aria-label={'open lesson'}
						icon={
							<Box transform={'rotate(-90deg)'}>
								<LeftPaginationArrowIcon />
							</Box>
						}
					/>
				</Flex>
				<FieldWrapper mt={'10px'} bg={'white'}>
					<Text fontWeight={'500'} color={'lightBlue'}>
						{item.name ? item.name : 'Draft'}
					</Text>
				</FieldWrapper>
			</Box>
			<Collapse in={isOpen}>
				<VStack align={'stretch'} spacing={'20px'}>
					{item?.videoLink ? (
						<Box bg={'white'}>
							<video
								style={{ borderRadius: '16px' }}
								controls={true}
								width={'400px'}
								height={'300px'}>
								<source src={item?.videoLink} />
							</video>
						</Box>
					) : null}
					{item?.imagePreviewLink ? (
						<Box
							bg={'white'}
							display={'inline-flex'}
							borderRadius={'10px'}>
							<Image
								h={'220px'}
								borderRadius={'16px'}
								src={item.imagePreviewLink}
							/>
						</Box>
					) : null}

					{item?.imageLink ? (
						<Box
							bg={'white'}
							display={'inline-box'}
							borderRadius={'10px'}>
							<Image
								h={'220px'}
								borderRadius={'16px'}
								src={item.imageLink}
								fallback={
									<Skeleton
										h={'220px'}
										borderRadius={'16px'}
										w={'300px'}
									/>
								}
							/>
						</Box>
					) : null}

					{item?.attachments.map(it => (
						<AttachmentItem
							bg={'white'}
							name={getFileNameWithExtension(it.itemLink)}
							key={it.id}
							isRemoved={false}
						/>
					))}
				</VStack>
			</Collapse>
		</Box>
	);
};
export const SectionPreview: React.FC<ISectionPreview> = ({
	item,
	sectionCountName,
	setActiveStep,
}) => {
	return (
		<VStack
			borderRadius={'10px'}
			p={'20px'}
			bg={'white'}
			align={'stretch'}
			spacing={'20px'}>
			{/*    Section Name*/}
			<Box>
				<Flex
					px={'20px'}
					py={'10px'}
					borderRadius={'10px'}
					align={'center'}
					bg={'lightBlue'}>
					<Text fontWeight={'700'} color={'white'} fontSize={'xl'}>
						{sectionCountName}
					</Text>
					<IconButton
						bg={'white'}
						ml={'20px'}
						onClick={() => {
							setActiveStep(2);
						}}
						aria-label={'edit section'}
						icon={<EditIcon fillColor={'#08BB4B'} />}
					/>
				</Flex>
				<FieldWrapper mt={'10px'} bg={'background'}>
					<Text fontWeight={'500'} color={'lightBlue'}>
						{item.name}
					</Text>
				</FieldWrapper>
			</Box>
			{/*    Lessons*/}
			{item.lessons.map((it, index) => (
				<LessonPreviewItem
					key={it.id}
					onGoBack={() => setActiveStep(2)}
					countName={`Lesson ${index + 1}`}
					item={it}
				/>
			))}
		</VStack>
	);
};
