import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const createPermissionGroupSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
});

export const createPermissionGroupResolver = yupResolver(
	createPermissionGroupSchema,
);
