import React from 'react';
import { Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { AlertDialogWrapper } from '../../../components';
import { useDeletePermissionGroup } from '../queries';

interface IDeletePermissionModal {
	isOpen: boolean;
	onClose: () => void;
	id: number;
}
export const DeletePermissionModal: React.FC<IDeletePermissionModal> = ({
	isOpen,
	onClose,
	id,
}) => {
	const deletePermissionGroupMutation = useDeletePermissionGroup();

	// Reversed actions and titles because of design
	return (
		<AlertDialogWrapper
			size={'xl'}
			isOpen={isOpen}
			acceptCallback={onClose}
			title="Delete permission group"
			acceptButtonText="Cancel"
			cancelButtonText="Delete"
			acceptButtonProps={{
				height: '40px',
				width: 'auto',
				borderColor: 'lightGreen',
				ml: 'auto',
			}}
			onClose={() => {
				if (id) {
					deletePermissionGroupMutation.mutate(id, {
						onSettled: () => {
							onClose();
						},
					});
				}
			}}
			cancelButtonProps={{
				isLoading: deletePermissionGroupMutation.isLoading,
				isDisabled: deletePermissionGroupMutation.isLoading,
				leftIcon: <DeleteIcon />,
				colorScheme: 'red',
				height: '40px',
				width: 'auto',
				ml: '10px',
			}}>
			<Text color={'darkBlue'} fontSize="16px">
				Are you sure? You can not undo this action afterwards.
			</Text>
		</AlertDialogWrapper>
	);
};
