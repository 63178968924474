import { ApiResponse } from '../../../../types';
import { useQuery } from 'react-query';
import { TimelineItemType } from '../../types';
import { API_IDENTITY } from '../../../../api/api.base';
import { QueryKeys } from './query-keys';

export const useGetMessageDetails = (id?: number) => {
	return useQuery<ApiResponse<TimelineItemType>>({
		queryFn: () => API_IDENTITY.get(`/api/admin/timeline/item/${id}`, {}),
		enabled: !!id,
		queryKey: [QueryKeys.GET_TIMELINE_DETAILS, id],
	});
};
