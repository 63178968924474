import { HStack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
	CalendarIcon,
	ClockIcon,
	PaperclipIcon,
} from '../../../../../../../../assets/icons';
import { USDateFormat } from '../../../../../../../../constants';

interface IItemFooterContent {
	date: string | null;
	timeFrom: string | null;
	timeTo: string | null;
	attachmentCount: number;
}

export const ItemFooterContent: React.FC<IItemFooterContent> = ({
	date,
	timeTo,
	timeFrom,
	attachmentCount,
}) => {
	const displayTime =
		!!timeFrom && !timeTo
			? `${dayjs(`${date} ${timeFrom}`).format('hh:mm A')}`
			: !!timeFrom && !!timeTo
				? `${dayjs(`${date} ${timeFrom}`).format('hh:mm')}-${dayjs(
					`${date} ${timeTo}`,
			  ).format('hh:mm A')}`
				: null;

	return (
		<>
			{date || attachmentCount ? (
				<HStack spacing={'5px'}>
					{date ? (
						<HStack
							spacing={'4px'}
							p={'4px'}
							bg={'white'}
							borderRadius={'10px'}>
							<CalendarIcon width={16} height={16} />
							<Text variant={'bodySmall'}>
								{dayjs(date).format(USDateFormat)}
							</Text>
						</HStack>
					) : null}
					{timeFrom || timeTo ? (
						<HStack
							spacing={'4px'}
							p={'4px'}
							bg={'white'}
							borderRadius={'10px'}>
							<ClockIcon />
							<Text variant={'bodySmall'}>{displayTime}</Text>
						</HStack>
					) : null}
					{attachmentCount ? (
						<HStack
							spacing={'4px'}
							p={'4px'}
							bg={'white'}
							borderRadius={'10px'}>
							<PaperclipIcon
								width={16}
								height={16}
								fillColor={'#0F1B49'}
							/>
							<Text variant={'bodySmall'}>{attachmentCount}</Text>
						</HStack>
					) : null}
				</HStack>
			) : null}
		</>
	);
};
