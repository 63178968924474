export type PermissionGroup = {
	claimsIds: { id: number; name: string }[];
	id: number;
	isDeletable: boolean;
	name: string;
	changedAt: string;
	createdAt: string;
};

export type CreatePermissionGroupPayload = {
	name: string;
	claimsIds: number[];
};

export interface UpdatePermissionGroupPayload
	extends CreatePermissionGroupPayload {
	id: number;
}

export const permissionGroups = [
	{
		id: 1,
		name: 'FMR Explorer',
	},
	{ id: 2, name: 'Courses' },
	{ id: 3, name: 'City Research' },
	{ id: 4, name: 'Property Lookup' },
	{ id: 5, name: 'Deal Calculator' },
	{ id: 6, name: 'Deal Explorer' },
	{ id: 7, name: 'Messenger' },
	{ id: 8, name: 'Cruz Control' },
	{ id: 9, name: 'Finder' },
];
