import { Button, HStack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { CalendarIcon } from '../../../../../../../assets/icons';
import { USDateFormat } from '../../../../../../../constants';

interface IProps {
	label?: string;
	errMsg?: string;
	value?: string | null;
	placeholder?: string;
	onOpen?: VoidFunction;
}

export const DatePickerTrigger = React.forwardRef<null, IProps>(
	({ errMsg, value, placeholder, onOpen, ...rest }, ref) => {
		const textColor = value ? 'darkBlue' : 'lightText';

		return (
			<Button
				ref={ref}
				{...rest}
				onClick={onOpen}
				h={'full'}
				variant="unstyled"
				w="100%">
				<HStack
					h="56px"
					px="16px"
					bg={'background'}
					borderRadius="10px"
					border={errMsg ? '1px solid #E53E3E' : 'none'}>
					<CalendarIcon />
					<Text color={textColor}>
						{value
							? dayjs(value).format(USDateFormat)
							: placeholder}
					</Text>
				</HStack>
				{/*{errMsg ? (*/}
				{/*	<Text*/}
				{/*		textAlign={'left'}*/}
				{/*		fontSize={'10px'}*/}
				{/*		lineHeight={'12px'}*/}
				{/*		color={'#DC2D2D'}*/}
				{/*		mt={'4px'}>*/}
				{/*		{errMsg}*/}
				{/*	</Text>*/}
				{/*) : null}*/}
			</Button>
		);
	},
);

DatePickerTrigger.displayName = 'DatePickerTrigger';
