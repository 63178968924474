interface ICloseIconProps {
	color?: string;
	size?: string;
}
export const CloseIcon: React.FC<ICloseIconProps> = ({
	color = '#1C2959',
	size = '24',
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 14 14"
			fill="none">
			<path
				d="M13 1L1 13M1 1L13 13"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
