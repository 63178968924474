/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Box, Flex, HStack, Text, Image } from '@chakra-ui/react';
import { AnalyticsDetailsItem } from '../AnalyticsDetails/AnalyticsDetailsItem';
import dayjs from 'dayjs';
import { CourseAnalyticsItem } from '../../../../types';
import { CoursesItem } from './CourseItem';
import Logo from '../../../../../../assets/images/pdf-doc-logo.png';
import { USDateFormat } from '../../../../../../constants';

interface IPDFDocumentProps {
	name?: string;
	email?: string;
	lastLoginDate?: string;
	numberOfLogins?: number;
	courses: CourseAnalyticsItem[];
}

export const PDFDocument: React.FC<IPDFDocumentProps> = ({
	name,
	email,
	lastLoginDate,
	numberOfLogins,
	courses,
}) => {
	return (
		<Box w="1000px">
			<Flex
				alignItems="center"
				// justifyContent="space-around"
				gap="50px"
				py="20px"
				px="40px"
				bg="blueText">
				<HStack>
					<Image src={Logo} />
					{/* <Text
						mr="6px"
						color="white"
						fontWeight={500}
						fontSize="25px">
						Section 8
					</Text>
					<Box>
						<Text
							mx="6px"
							borderRadius={'10px'}
							background={'lightGreen'}
							textAlign="center"
							verticalAlign="center"
							color="white"
							fontWeight={700}
							px={'10px'}
							py={'4px'}
							fontSize="25px">
							Pro
						</Text>
					</Box> */}
					<Box h="80px" borderLeft="1px solid white" mx="20px" />
				</HStack>
				<Text
					textAlign="center"
					verticalAlign="center"
					color="white"
					fontWeight={500}
					fontSize="25px"
					mt="-25px">
					Course progress analytics
				</Text>
			</Flex>
			<Box bg="background" px="60px" py="40px">
				<Box p="20px" borderRadius="10px" bg="white">
					<Flex gap="40px" flexDirection="row">
						<Box>
							<AnalyticsDetailsItem label="Email" value={email} />
							<AnalyticsDetailsItem label="Name" value={name} />
						</Box>
						<Box>
							<AnalyticsDetailsItem
								label="Last Login Date"
								value={
									lastLoginDate
										? dayjs(lastLoginDate)?.format(
												USDateFormat,
										  )
										: '-'
								}
							/>
							<AnalyticsDetailsItem
								label="Number of Logins"
								value={numberOfLogins}
							/>
						</Box>
					</Flex>
				</Box>
				<Box mt="30px">
					{courses.map((item: CourseAnalyticsItem, index: number) => {
						return <CoursesItem key={index} {...item} />;
					})}
				</Box>
			</Box>
		</Box>
	);
};
