import React from 'react';
import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { colors } from '../../theme/colors';

interface ICustomCheckbox extends CheckboxProps {
	mainColor?: string;
	size?: string;
}

export const CustomCheckbox: React.FC<ICustomCheckbox> = ({
	mainColor = colors.greenButton[500],
	size = '24px',
	...props
}) => {
	return (
		<Checkbox
			colorScheme={'#08BB4B'}
			_focusVisible={{}}
			sx={{
				span: {
					bg: 'white',
					border: `1px solid ${mainColor}`,
					borderRadius: '4px',
					width: size,
					height: size,
				},
				'span[data-checked]': {
					bg: `${mainColor}`,
					transition: '0.3s all ease',
					border: `1px solid ${mainColor}`,
				},
				'span[data-checked][data-disabled]': {
					bg: `${mainColor}`,
					transition: '0.3s all ease',
					border: `1px solid ${mainColor}`,
					cursor: 'not-allowed',
				},
				'span[data-checked][data-disabled] div svg': {
					stroke: 'white !important',
				},
				'span[data-checked]:hover': {
					bg: `${mainColor}`,
					opacity: 0.75,
					border: `1px solid ${mainColor}`,
				},
				'span[data-checked] div svg': {
					width: '16px',
					height: '16px',
				},
				'span[data-focus-visible]': {
					boxShadow: 'none',
				},
			}}
			{...props}
		/>
	);
};
