import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useUpdateCoursePosition = () => {
	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		number[]
	>({
		mutationFn: ids =>
			Api.put('/api/admin/courses/position', { courseIds: ids }),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
			Alerter.success('Course positions updated!');
		},
		onError: () => {
			Alerter.error('Error while updating course position');
		},
	});

	return {
		isLoading,
		onUpdatePosition: mutate,
	};
};
