import { ApiResponse } from '../../../../types';
import { useQuery } from 'react-query';
import { TimelineItemType } from '../../types';
import { API_IDENTITY } from '../../../../api/api.base';
import { QueryKeys } from './query-keys';

export const useGetTimelineList = (id?: number) => {
	return useQuery<
		ApiResponse<{
			timeline: TimelineItemType[];
		}>
	>({
		queryFn: () => API_IDENTITY.get(`/api/admin/timeline/${id}`, {}),
		enabled: !!id,
		queryKey: [QueryKeys.GET_TIMELINE_LIST, id],
	});
};
