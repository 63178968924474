import React from 'react';
import {
	FormLabel,
	FormControl,
	FormErrorMessage,
	FormControlProps,
	FormLabelProps,
	TextareaProps,
	Textarea,
} from '@chakra-ui/react';

interface IInputTextAreaProps extends TextareaProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
}

export const InputTextArea = React.forwardRef<null, IInputTextAreaProps>(
	({ label, errorMsg, formControlProps, formLabelProps, ...rest }, ref) => {
		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? (
					<FormLabel
						fontSize={'xl'}
						mb="8px"
						color={'darkBlue'}
						fontWeight={700}
						{...formLabelProps}>
						{label}
					</FormLabel>
				) : null}
				<Textarea
					border={'none'}
					placeholder={'Enter'}
					height="170px"
					fontSize={'16px'}
					color={'darkBlue'}
					fontWeight={'500'}
					_placeholder={{ color: 'lightGray' }}
					borderRadius="10px"
					bg="background"
					ref={ref}
					{...rest}
				/>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);

InputTextArea.displayName = 'InputTextArea';
