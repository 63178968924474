export const CheckIcon: React.FC<{ color?: string }> = ({
	color = 'white',
}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g id="check">
				<path
					id="Icon"
					d="M20 6L9 17L4 12"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};
