import React, { memo } from 'react';

import { Flex, Button } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { BreadCrumbsItem } from '../../types';
import { ArrowRightIcon } from '../../assets/icons/ArowRightIcon';

interface IBreadCrumbsProps {
	items: BreadCrumbsItem[];
}
export const BreadCrumbs: React.FC<IBreadCrumbsProps> = memo(({ items }) => {
	const navigate = useNavigate();
	return (
		<Flex w="full" flexWrap="wrap">
			{items.map((item: BreadCrumbsItem, index: number) => {
				const isLast = index + 1 === items.length;
				return (
					<Flex key={index} mr={2} overflow="hidden">
						<Button
							variant="unstyled"
							color="#7E788F"
							rightIcon={!isLast ? <ArrowRightIcon /> : undefined}
							fontSize={16}
							// textDecoration={isLast ? 'underline' : 'none'}
							onClick={() => {
								if (!item.route) {
									return;
								}
								navigate({
									pathname: item.route,
									...item.routeParams,
								});
							}}>
							{item.label}
						</Button>
					</Flex>
				);
			})}
		</Flex>
	);
});

BreadCrumbs.displayName = 'BreadCrumbs';
