import React from 'react';
import {
	Box,
	TableContainer,
	Table,
	Tr,
	Td,
	Center,
	Spinner,
	Tbody,
} from '@chakra-ui/react';
import { colors } from '../../../../../theme/colors';
import { ExtendedPagination } from '../../../../../components';
import { VerificationUser } from '../../types';
import { TableRow } from './TableRow';
import { TableHeader } from './TableHeader';

interface IUsersVerificationTableProps {
	isLoading: boolean;
	updateStatusLoading: boolean;
	users?: VerificationUser[];
	selectedUsers: VerificationUser[];
	totalCount?: number;
	pageSize: number;
	currentPage: number;
	isAllSelected: boolean;
	setCurrentPage: (page: number) => void;
	setPageSize: (pageSize: number) => void;
	onChangeVerificationStatus: (
		userIds: number[],
		communityAllow: boolean,
		onClose?: () => void,
	) => void;
	addUserToSelected: (user: VerificationUser) => void;
	removeUserFromSelected: (user: VerificationUser) => void;
	selectAllHandler: () => void;
	onUpdateChannelsBanStatus: (
		userId: number,
		banned: boolean,
		onSuccess?: () => void,
		onError?: () => void,
	) => void;
	updateChannelBanLoading: boolean;
}

export const UsersVerificationTable: React.FC<IUsersVerificationTableProps> = ({
	isLoading,
	users,
	totalCount,
	setCurrentPage,
	setPageSize,
	pageSize,
	currentPage,
	onChangeVerificationStatus,
	updateStatusLoading,
	selectedUsers,
	addUserToSelected,
	removeUserFromSelected,
	selectAllHandler,
	isAllSelected,
	onUpdateChannelsBanStatus,
	updateChannelBanLoading,
}) => (
	<Box>
		<TableContainer
			mt="20px"
			border="1px solid"
			borderColor={colors.dropBlue}
			borderRadius="10px"
			boxShadow="none">
			<Table variant="simple">
				<TableHeader
					selectAllHandler={selectAllHandler}
					isAllSelected={isAllSelected}
				/>
				<Tbody>
					{isLoading ? (
						<Tr bg="white">
							<Td colSpan={8}>
								<Center>
									<Spinner />
								</Center>
							</Td>
						</Tr>
					) : (
						users?.map((user, index) => {
							const lastItem = users.length - 1 === index;
							return (
								<TableRow
									selectedUsers={selectedUsers}
									addUserToSelected={addUserToSelected}
									removeUserFromSelected={
										removeUserFromSelected
									}
									lastItem={lastItem}
									key={index}
									onChangeVerificationStatus={
										onChangeVerificationStatus
									}
									updateStatusLoading={updateStatusLoading}
									user={user}
									onUpdateChannelsBanStatus={
										onUpdateChannelsBanStatus
									}
									updateChannelBanLoading={
										updateChannelBanLoading
									}
								/>
							);
						})
					)}
				</Tbody>
			</Table>
		</TableContainer>
		<ExtendedPagination
			currentPage={currentPage}
			gotoPage={setCurrentPage}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalCount={totalCount || 0}
			currentItemsLength={users?.length}
			perPageTextProps={{
				color: colors.lightGray,
				fontWeight: 500,
				fontSize: '16px',
			}}
			pagesTextProps={{
				color: colors.darkBlueTable[100],
				fontWeight: 500,
				fontSize: '16px',
			}}
			selectProps={{
				backgroundColor: 'white',
				color: colors.darkBlue,
				fontWeight: 500,
				border: 'none',
				boxShadow: '0px 0px 3px 0px rgba(139, 139, 139, 0.10)',
				minW: '74px',
				borderRadius: '10px',
				h: '46px',
			}}
			defaultPaginationProps={{
				mx: '0px',
				mt: '40px',
			}}
		/>
	</Box>
);
