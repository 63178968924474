import React from 'react';
import {
	Center,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { SectionWrapper } from '../MainInfo/components/SectionWrapper';
import {
	BillingTransaction,
	formatBillingAmount,
	transactionsSum,
} from './helper';
import { colors } from '../../../../theme/colors';
import { RenderTd, RenderTh } from '../../../../components';
import { formatBillingDate } from '../../../../utils';

enum TransactionResponseText {
	ACCEPTED = 'Accepted',
	DECLINED = 'Declined',
}

interface BillingHistoryProps {
	transactions: BillingTransaction[] | undefined;
}

export const BillingHistory: React.FC<BillingHistoryProps> = ({
	transactions,
}) => {
	return (
		<SectionWrapper borderRadius={'8px'}>
			<TableContainer
				mt={'22px'}
				borderRadius={'10px'}
				border={'1px solid #BDCDE9'}>
				<Table variant="simple" size={'sm'}>
					<Thead>
						<Tr bg={'#BDCDE9'}>
							<RenderTh color={'darkBlue'} value={'First Name'} />
							<RenderTh color={'darkBlue'} value={'Last Name'} />
							<RenderTh color={'darkBlue'} value={'Email'} />
							<RenderTh
								color={'darkBlue'}
								value={'Phone Number'}
							/>
							<RenderTh
								color={'darkBlue'}
								value={'Credit Card Number'}
							/>
							<RenderTh color={'darkBlue'} value={'Expiration'} />
							<RenderTh color={'darkBlue'} value={'CC Type'} />
							<RenderTh color={'darkBlue'} value={'Date'} />
							<RenderTh color={'darkBlue'} value={'Status'} />
							<RenderTh color={'darkBlue'} value={'Amount'} />
						</Tr>
					</Thead>
					<Tbody bg={'#FFFFFF'}>
						{transactions && transactions.length ? (
							transactions?.map((item, index) => (
								<Tr key={index}>
									<RenderTd
										borderRight={`1px solid ${colors.dropBlue}`}
										value={item.firstName}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={item.lastName}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={item.email}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={
											item.phone
												? `+1 ${item.phone}`
												: item.cellPhone
												? `+1 ${item.cellPhone}`
												: '-'
										}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={
											item?.ccNumber
												? `*${item.ccNumber.slice(-4)}`
												: ''
										}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={
											item?.ccExp
												? item.ccExp.slice(0, 2) +
												  '/' +
												  item.ccExp.slice(2)
												: ''
										}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={item?.ccType ? item.ccType : ''}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={formatBillingDate(
											item.actions[
												item.actions.length - 1
											].date,
										)}
									/>
									<RenderTd
										borderRight={'1.5px solid #BDCDE9'}
										value={
											item.actions[
												item.actions.length - 1
											].success
												? TransactionResponseText.ACCEPTED
												: TransactionResponseText.DECLINED
										}
									/>
									<RenderTd
										value={
											item.actions[
												item.actions.length - 1
											].amount
												? formatBillingAmount(
														item.actions[
															item.actions
																.length - 1
														].amount,
												  )
												: 0
										}
									/>
								</Tr>
							))
						) : (
							<Tr>
								<Td colSpan={13}>
									<Center py={'10px'}>
										<Text
											fontSize={'18px'}
											fontWeight={'700'}>
											History is empty
										</Text>
									</Center>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
			<Flex mt={'20px'} fontWeight={'700'} gap={'5px'}>
				<Text>Total Lifetime Value:</Text>
				<Text>
					{formatBillingAmount(transactionsSum(transactions))}
				</Text>
			</Flex>
		</SectionWrapper>
	);
};
