export function countObjectValues(
	object?: Record<string, any> | null,
	value?: boolean | null,
) {
	let count = 0;
	for (const key in object) {
		if (object[key] === value) {
			count++;
		}
	}
	return count;
}
