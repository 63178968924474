import React, { SetStateAction, Dispatch } from 'react';
import {
	Box,
	Center,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { ExtendedPagination } from '../../../components';
import { IInfoLogs } from '../../../types/logs.types';
import { Alerter } from '../../../utils/Alerter';
import { getNormalDate } from '../helper';
import { ClearTableModal } from './сlearTableModal';

interface InfoTableProps {
	data: { totalCount: number; logInfo?: IInfoLogs[] };
	isLoading: boolean;
	currentPage: number;
	pageSize: number;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setPageSize: Dispatch<SetStateAction<number>>;
	deleteLogs: (url: string) => Promise<any>;
}

export const InfoTable: React.FC<InfoTableProps> = ({
	isLoading,
	data,
	currentPage,
	pageSize,
	setCurrentPage,
	setPageSize,
	deleteLogs,
}) => {
	const {
		isOpen: isOpenConfirmation,
		onOpen: onOpenConfirmation,
		onClose: onCloseConfirmation,
	} = useDisclosure();
	const openDeleteConfirmationWindow = () => {
		onOpenConfirmation();
	};
	const closeDeleteConfirmationWindow = async () => {
		const result = await deleteLogs('/api/admin/logging/info');
		if (result.success) {
			Alerter.success('Logs cleared');
			onCloseConfirmation();
		} else {
			Alerter.error('Something went wrong');
		}
	};
	return (
		<Box>
			<TableContainer
				mt="20px"
				border="1px solid"
				borderColor="#e3e0e0"
				borderRadius="8px"
				boxShadow="md">
				<Table variant="simple">
					<Thead>
						<Tr>
							<Th>Date</Th>
							<Th>State</Th>
							<Th>Short description</Th>
							<Th>Properties processed</Th>
						</Tr>
					</Thead>
					<Tbody>
						{isLoading ? (
							<Tr>
								<Td colSpan={4}>
									<Center>
										<Spinner />
									</Center>
								</Td>
							</Tr>
						) : (
							data?.logInfo?.map(
								(item: IInfoLogs, index: number) => (
									<Tr key={index}>
										<Td>{getNormalDate(item.dateTime)}</Td>
										<Td>{item.state ? item.state : '-'}</Td>
										<Td>
											<Text
												maxWidth="600px"
												whiteSpace={'normal'}>
												{item.message}
											</Text>
										</Td>
										<Td>
											{item.properties
												? item.properties
												: '-'}
										</Td>
									</Tr>
								),
							)
						)}
					</Tbody>
				</Table>
				<ExtendedPagination
					currentPage={currentPage}
					gotoPage={val => setCurrentPage(val)}
					pageSize={pageSize}
					setPageSize={val => {
						setPageSize(val);
						setCurrentPage(1);
					}}
					totalCount={data?.totalCount}
					currentItemsLength={data?.logInfo?.length}
					extendedPagination={true}
					openDeleteConfirmationWindow={openDeleteConfirmationWindow}
				/>
				<ClearTableModal
					onCloseConfirmation={onCloseConfirmation}
					deleteTableDataFn={closeDeleteConfirmationWindow}
					isOpenConfirmation={isOpenConfirmation}
				/>
			</TableContainer>
		</Box>
	);
};
