import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalFooter,
	ModalBody,
	Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ListOfAvailablePages } from '../../components';
import { InputText } from '../../../../components';
import { createPermissionGroupResolver } from '../../../../validation';
import {
	useCreatePermissionGroup,
	useUpdatePermissionGroup,
} from '../../queries';
import { PermissionGroup } from '../../../../types';

interface ICreatePermissionGroupProps {
	isOpen: boolean;
	onClose: VoidFunction;
	groupToEdit: PermissionGroup | null;
}

interface ICreatePermissionForm {
	name: string;
}

export const CreatePermissionGroup: React.FC<ICreatePermissionGroupProps> = ({
	isOpen,
	onClose,
	groupToEdit,
}) => {
	const [selectedClaims, setSelectedClaims] = useState<number[]>([]);

	const createPermissionGroupMutation = useCreatePermissionGroup(onClose);
	const updatePermissionGroupMutation = useUpdatePermissionGroup(onClose);

	const {
		formState: { errors },
		handleSubmit,
		register,
		reset,
	} = useForm<ICreatePermissionForm>({
		resolver: createPermissionGroupResolver,
	});

	useEffect(() => {
		if (groupToEdit) {
			reset({ name: groupToEdit.name });
			setSelectedClaims(groupToEdit.claimsIds.map(elem => elem.id));
		} else {
			reset({});
			setSelectedClaims([]);
		}
	}, [groupToEdit, isOpen]);

	const onSubmit = (data: ICreatePermissionForm) => {
		if (groupToEdit) {
			updatePermissionGroupMutation.mutate({
				id: groupToEdit.id,
				name: data.name,
				claimsIds: selectedClaims,
			});
		} else {
			createPermissionGroupMutation.mutate({
				name: data.name,
				claimsIds: selectedClaims,
			});
		}
	};

	const onCheckClaim = (claim: number) => {
		if (selectedClaims.includes(claim)) {
			setSelectedClaims(prev => prev.filter(elem => elem !== claim));
		} else {
			setSelectedClaims(prev => [...prev, claim]);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalContent>
					<ModalHeader fontSize="20px">
						{groupToEdit ? 'Edit' : 'Create'} Permission Group
					</ModalHeader>
					<ModalCloseButton right="24px" top="16px" />
					<ModalBody>
						<InputText
							{...register('name')}
							label="Name"
							errorMsg={errors.name?.message}
						/>
						<ListOfAvailablePages
							selectedValues={selectedClaims}
							onSelect={onCheckClaim}
						/>
					</ModalBody>
					<ModalFooter gap="10px">
						<Button onClick={onClose}>Cancel</Button>
						<Button
							colorScheme="lightBlueButton"
							type="submit"
							isLoading={
								createPermissionGroupMutation.isLoading ||
								updatePermissionGroupMutation.isLoading
							}>
							{groupToEdit ? 'Edit' : 'Create'}
						</Button>
					</ModalFooter>
				</ModalContent>
			</form>
		</Modal>
	);
};
