import React, { useCallback, useEffect, useState } from 'react';
import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	VStack,
} from '@chakra-ui/react';
import { CustomSelect, Loader } from '../../../../../../../components';
import {
	timelineDefaultValues,
	TimelineTypeEnum,
	timelineTypeOptions,
} from '../../../../../constants';
import {
	blueButton,
	greenButton,
} from '../../../../../../../theme/elementStyles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
	TimelineFormValues,
	timelineResolver,
} from '../../../../../../../validation';
import { SingleValue } from 'react-select';
import { CallForm } from './CallForm';
import { EmailForm } from './EmailForm';
import { DMForm } from './DMForm';
import { NoteForm } from './NoteForm';
import { TimelineDateTime } from './TimelineDateTime';
import {
	getTimelinePayload,
	getTimelineValues,
	mergeAndSortAttachments,
} from '../../../../../helper';
import {
	useCreateTimelineItem,
	useGetMessageDetails,
	useUpdateTimelineItemById,
} from '../../../../../hooks';
import { useParams } from 'react-router-dom';
import { FilePicker } from './FilePicker';
import { FileItem } from './FileItem';
import { useTimelineStore } from '../../../../../store';
import {
	TimelineEnumAttachment,
	UnionTimelineAttachment,
} from '../../../../../types';
import { FileViewList } from '../FileViewList';

interface ITimelineModal {
	isOpen: boolean;
	onClose: VoidFunction;
}

export const TimelineModal: React.FC<ITimelineModal> = ({
	isOpen,
	onClose,
}) => {
	const params = useParams();

	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const [attachments, setAttachments] = useState<UnionTimelineAttachment[]>(
		[],
	);

	const timelineId = useTimelineStore(state => state.timelineId);

	const header = timelineId ? 'Editing an added' : 'Add to the timeline';

	const methods = useForm<TimelineFormValues>({
		resolver: timelineResolver,
		defaultValues: timelineDefaultValues,
	});

	const { control, handleSubmit, watch, reset, getValues, setValue } =
		methods;

	const { data, isLoading } = useGetMessageDetails(Number(timelineId));

	const createTimeline = useCreateTimelineItem();

	const updateTimeline = useUpdateTimelineItemById();

	// const { append, remove, fields } = useFieldArray<TimelineFormValues>({
	// 	control,
	// 	name: 'files',
	// });

	const typeWatch = watch('type');

	const onReset = useCallback(() => {
		onClose();
		reset(timelineDefaultValues);
		setSelectedFile(null);
		setAttachments([]);
	}, []);

	// const onRemove = (value: number) => {
	// 	remove(value);
	// };

	const onSubmit = (values: TimelineFormValues) => {
		const payload = getTimelinePayload(
			values,
			Number(params?.id),
			selectedFile,
			timelineId,
		);
		if (timelineId) {
			updateTimeline.mutate(payload, {
				onSuccess: data => {
					if (data.success) {
						onReset();
					}
				},
			});
		} else {
			createTimeline.mutate(payload, {
				onSuccess: data => {
					if (data.success) {
						onReset();
					}
				},
			});
		}
	};

	const renderForm = useCallback(() => {
		switch (typeWatch) {
			case TimelineTypeEnum.Call:
				return <CallForm />;
			case TimelineTypeEnum.Email:
				return <EmailForm />;
			case TimelineTypeEnum.DirectMessage:
				return <DMForm />;
			case TimelineTypeEnum.Note:
				return <NoteForm />;
			default:
				return null;
		}
	}, [typeWatch]);

	const onRemoveAttachment = useCallback(
		(id: number, type: number, indexItem: number) => {
			const imageIds = getValues('imageIdsToDelete');
			const fileIds = getValues('fileIdsToDelete');

			if (type === TimelineEnumAttachment.File) {
				setValue('fileIdsToDelete', [...imageIds, id], {
					shouldDirty: true,
				});
			}
			if (type !== TimelineEnumAttachment.File) {
				setValue('imageIdsToDelete', [...fileIds, id], {
					shouldDirty: true,
				});
			}
			setAttachments(prev =>
				prev.filter((_, index) => index !== indexItem),
			);
		},
		[getValues, attachments],
	);

	useEffect(() => {
		if (data?.value) {
			reset(getTimelineValues(data?.value));
			setAttachments(
				mergeAndSortAttachments(data.value.files, data.value.images),
			);
		}
	}, [data?.value]);

	return (
		<Modal
			scrollBehavior={'inside'}
			onClose={onReset}
			closeOnOverlayClick={false}
			isOpen={isOpen}>
			<ModalOverlay />
			<ModalContent bg="white" maxW="440px">
				<ModalHeader
					px="20px"
					fontSize="20px"
					fontWeight="700"
					lineHeight="24px">
					{header}
				</ModalHeader>
				<ModalCloseButton color="lightBlue" bg={'background'} />
				<ModalBody p="20px">
					{isLoading ? (
						<Loader centerHeight={'150px'} />
					) : (
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<VStack spacing={'20px'} align={'stretch'}>
									<Controller
										render={({
											field: { value, onChange },
											fieldState: { error },
										}) => {
											return (
												<CustomSelect
													isDisabled={!!timelineId}
													menuPosition={'fixed'}
													onChange={(
														newValue: SingleValue<any>,
													) => {
														onChange(
															Number(
																newValue?.value,
															),
														);
													}}
													value={timelineTypeOptions.find(
														it =>
															it.value === value,
													)}
													errMsg={error?.message}
													options={
														timelineTypeOptions
													}
													placeholder={'Select type'}
												/>
											);
										}}
										name={'type'}
										control={control}
									/>
									{renderForm()}
									{typeWatch &&
									typeWatch !== TimelineTypeEnum.Note ? (
											<TimelineDateTime />
									) : null}
									{typeWatch ? (
										<FilePicker
											isDisabled={!!selectedFile}
											setValue={value => {
												setSelectedFile(value);
											}}
											alignSelf={'center'}
										/>
									) : null}
									<VStack spacing={'5px'} align={'stretch'}>
										{attachments.length ? (
											<FileViewList
												onRemove={onRemoveAttachment}
												canRemove={true}
												items={attachments}
											/>
										) : null}
										{selectedFile ? (
											<FileItem
												item={selectedFile}
												onRemove={() => {
													setSelectedFile(null);
												}}
											/>
										) : null}
									</VStack>
									<HStack spacing={'10px'}>
										<Button
											onClick={onReset}
											{...blueButton}
											w={'full'}>
											Cancel
										</Button>
										<Button
											{...greenButton}
											isLoading={
												createTimeline.isLoading ||
												updateTimeline.isLoading
											}
											isDisabled={
												createTimeline.isLoading ||
												updateTimeline.isLoading
											}
											w={'full'}
											type={'submit'}>
											{timelineId ? 'Save' : 'Add'}
										</Button>
									</HStack>
								</VStack>
							</form>
						</FormProvider>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
