import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Box,
	Button,
	Flex,
	IconButton,
	Skeleton,
	Text,
} from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons';
import { colors } from '../../../../../theme/colors';
import { CustomCheckbox, InputTextArea } from '../../../../../components';
import { EditIcon } from '../../../../../assets/icons';
import { courseCompletionMessageResolver } from '../../../../../validation';
import { useUpdateCourseCompletionMessage } from '../../../queries';

interface ICourseCompletionMessage {
	message?: string | null;
	isChecked?: boolean;
	courseId: number;
	dataLoading: boolean;
}

export const CourseCompletionMessage: React.FC<ICourseCompletionMessage> = ({
	message,
	isChecked,
	courseId,
	dataLoading,
}) => {
	const [checkboxValue, setCheckboxValue] = useState(false);
	const [edit, setEdit] = useState(false);

	const onSuccessAction = () => {
		setEdit(false);
	};

	const { isLoading, onUpdateCourseCompletionMessage } =
		useUpdateCourseCompletionMessage(onSuccessAction);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm<{ message: string }>({
		resolver: courseCompletionMessageResolver,
		defaultValues: {
			message: '',
		},
		values: {
			message: message ? message : '',
		},
	});

	const messageValue = watch('message');

	const onSubmit = (data: { message: string }) => {
		const { message } = data;
		onUpdateCourseCompletionMessage({
			courseId,
			isCourseCompletedMessage: true,
			courseCompletedMessage: message,
		});
	};

	const onRemoveMessage = (courseId: number) =>
		onUpdateCourseCompletionMessage({
			courseId,
			isCourseCompletedMessage: false,
		});

	useEffect(() => {
		reset({ message: message ? message : '' });
	}, [edit]);

	useEffect(() => {
		setCheckboxValue(!!isChecked);
	}, [isChecked]);

	const getDropdownHeight = () => {
		switch (true) {
			case checkboxValue && !!errors.message?.message:
				return '266px';
			case (checkboxValue && edit) || (checkboxValue && !message?.length):
				return '240px';
			case checkboxValue:
				return '174px';
			default:
				return '0px';
		}
	};

	const dropdownHeight = getDropdownHeight();

	const showEditButton = checkboxValue && message?.length && !edit;
	const showHideButton = checkboxValue && message?.length && edit;
	const showSubmitButton =
		(checkboxValue && edit) || (checkboxValue && !message?.length);
	const disableSubmitButton = !messageValue.length;
	const readOnlyInput = !edit && !!message?.length;

	return (
		<Skeleton isLoaded={!dataLoading} borderRadius="10px">
			<Box padding="20px" borderRadius="10px" bg="white">
				<Flex align="center" columnGap="20px">
					<CustomCheckbox
						size="32px"
						isDisabled={isLoading ? isChecked : undefined}
						isChecked={checkboxValue}
						onChange={() => {
							if (isChecked) {
								return onRemoveMessage(courseId);
							}
							setCheckboxValue(prev => !prev);
						}}
					/>
					<Text
						fontSize="20px"
						lineHeight="24px"
						fontWeight={700}
						color={colors.darkBlue}>
						Send a message to the user after the course is completed
					</Text>
					{showEditButton ? (
						<IconButton
							w="32px"
							minW="32px"
							h="32px"
							p="6px"
							aria-label="Edit message"
							icon={<EditIcon fillColor={colors.lightGreen} />}
							onClick={() => setEdit(true)}
						/>
					) : null}

					{showHideButton ? (
						<IconButton
							w="32px"
							minW="32px"
							ml="auto"
							h="32px"
							p="6px"
							aria-label="Edit message"
							icon={
								<ChevronUpIcon
									fontSize="26px"
									color={colors.blueText}
								/>
							}
							onClick={() => setEdit(false)}
						/>
					) : null}
				</Flex>
				<Box
					mt={checkboxValue ? '10px' : '0px'}
					height={dropdownHeight}
					overflow="hidden"
					transition="height 0.3s ease, margin 0.3s ease">
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box p="1px">
							<InputTextArea
								{...register('message')}
								label=""
								placeholder={'Message'}
								resize="none"
								isReadOnly={readOnlyInput}
								errorMsg={edit ? errors.message?.message : ''}
							/>
						</Box>

						{showSubmitButton ? (
							<Box mt="10px">
								<Button
									type="submit"
									variant="outline"
									w="100%"
									py="15px"
									h="56px"
									borderRadius="10px"
									borderColor={colors.lightGreen}
									color={colors.lightGreen}
									isLoading={isLoading}
									isDisabled={disableSubmitButton}
									_disabled={{
										color: colors.lightGray,
										borderColor: colors.lightGray,
										cursor: 'not-allowed',
									}}>
									Save
								</Button>
							</Box>
						) : null}
					</form>
				</Box>
			</Box>
		</Skeleton>
	);
};
