import { useMutation, useQueryClient } from 'react-query';
import { API_IDENTITY } from '../../../api/api.base';
import { ApiResponse, ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils/Alerter';
import { RiskScoreResponse } from '../types';

export const useUpdateRiskScoreMailingMutation = () => {
	const queryClient = useQueryClient();

	const onChangeRiskScoreQuery = (id: number, value: boolean) => {
		queryClient.setQueryData<
			ApiResponse<RiskScoreResponse | null> | undefined
		>(['user-risk-score', id], oldData => {
			console.log(oldData, 'oldData');
			if (oldData?.value) {
				return {
					...oldData,
					value: {
						...oldData.value,
						isMailingDisabled: value,
					},
				};
			}
			return oldData;
		});
	};

	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ id: number; value: boolean }
	>({
		mutationFn: payload =>
			API_IDENTITY.put(
				`/api/admin/users/${payload.id}/risk-score/mailing`,
				{ isMailingDisabled: payload.value },
			),
		onSuccess(data, variables) {
			if (data.success) {
				Alerter.success(
					data.messages?.[0]?.message || 'Mailing settings saved',
				);
			} else {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Error occurred updating mailing',
				);
				onChangeRiskScoreQuery(variables.id, !variables.value);
			}
		},
		onError(error, variables) {
			console.log(error, 'useUpdateRiskScoreMailingMutation err');
			Alerter.error('Error while changing mailing settings');
			onChangeRiskScoreQuery(variables.id, !variables.value);
		},
		onMutate(variables) {
			console.log('on mutate', variables);
			onChangeRiskScoreQuery(variables.id, variables.value);
		},
	});
};
