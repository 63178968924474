import { StylesConfig } from 'react-select';

export const stylesConfig = (): StylesConfig => ({
	control: (provided, { selectProps, isDisabled }) => {
		const isInvalid = Boolean(selectProps['aria-invalid']);

		const bg = isDisabled ? '#F0F0F0' : '#F1F4F9';

		return {
			...provided,
			border: 'none',
			borderTopLeftRadius: '10px',
			borderTopRightRadius: '10px',
			borderBottomLeftRadius: selectProps.menuIsOpen ? 0 : '10px',
			borderBottomRightRadius: selectProps.menuIsOpen ? 0 : '10px',
			fontSize: '16px',
			background: bg,
			fontFamily: 'Inter',
			height: '56px',
			fontWeight: 500,
			lineHeight: '21px',
			transition: 'all 0.1s ease-in',
			width: '100%',
			boxShadow: isInvalid ? '0px 0px 0px 1px #E53E3E' : 'none',
			'&:hover': {
				// border: `1px solid ${isFocused ? '#0F1B49' : '#BDCDE9'}`,
				cursor: 'pointer',
				background: '#EBF0F9',
			},
		};
	},
	placeholder: (defaultStyles, state) => ({
		...defaultStyles,
		fontWeight: 500,
		color: state.isDisabled ? '#8E8E8E' : '#7E788F',
	}),
	option: (styles, { isDisabled, isSelected }) => {
		const backgroundColor = isDisabled ? '#F0F0F0' : 'transparent';

		return {
			...styles,
			padding: '10px 16px',
			lineHeight: '21px',
			fontSize: '16px',
			color: isDisabled ? '#8E8E8E' : '#10162C',
			fontWeight: '500',
			fontFamily: 'inherit',
			backgroundColor,
			cursor: isDisabled ? 'not-allowed' : 'pointer',
			position: 'relative',
			'&:hover': {
				backgroundColor: isDisabled ? '#F0F0F0' : '#1C2959',
				color: isDisabled ? '#8E8E8E' : 'white',
			},
			'::after': isSelected
				? {
						content: '""',
						width: '8px',
						height: '8px',
						position: 'absolute',
						borderRadius: '8px',
						right: '16px',
						top: 'calc(50% - 4px)',
						backgroundColor: 'currentcolor',
				  }
				: undefined,
		};
	},
	clearIndicator: base => {
		return {
			...base,
			'&:hover': {
				color: '#0F1B49',
			},
			svg: {
				height: '23px',
				width: '23px',
			},
		};
	},
	menuList: base => {
		return {
			...base,
			'::-webkit-scrollbar': {
				width: '5px',
			},
			'::-webkit-scrollbar-track': {
				background: 'white',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				backgroundColor: '#4A5991CC',
				opacity: 0.1,
				borderRadius: '10px',
				minHeight: '60px',
			},
		};
	},
	indicatorSeparator: props => {
		return {
			display: 'none',
			...props,
		};
	},
	singleValue: (props, { isDisabled }) => {
		return {
			...props,
			color: isDisabled ? '#8E8E8E' : '#10162C',
			fontWeight: '500',
		};
	},
	noOptionsMessage: props => ({
		...props,
		color: '#7E788F',
		fontSize: '14px',
		fontWeight: '500',
	}),
	menuPortal: props => ({
		...props,
		zIndex: 999,
	}),
	dropdownIndicator(base, { isDisabled }) {
		return {
			...base,
			color: isDisabled ? '#8E8E8E' : '#1C2959',
			paddingRight: '16px',
			'&:hover': {
				color: '#1C2959',
			},
		};
	},
	valueContainer: base => ({
		...base,
		paddingLeft: '16px',
	}),
	menu: props => ({
		...props,
		zIndex: 999,
		marginTop: 0,
		marginBottom: 0,
		boxShadow: 'none',
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: '10px',
		borderBottomRightRadius: '10px',
		background: '#F1F4F9',
	}),
});
