import {
	Box,
	Divider,
	Flex,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { Icon } from '../../../../../../../components';
import dayjs from 'dayjs';
import {
	ChatIcon,
	DotsIcon,
	EditIcon,
	OpenIcon,
	PhoneIcon,
} from '../../../../../../../assets/icons';
import { useCallback } from 'react';
import { TimelineTypeEnum } from '../../../../../constants';
import { useDeleteMessage } from '../../../../../hooks';
import { USDateFormat } from '../../../../../../../constants';

const menuListStyles = {
	fontSize: '14px',
	borderRadius: '4px',
	_hover: {
		color: 'white',
		bg: 'lightBlue',
	},
	fontWeight: 500,
};

const buttonStyles = {
	minW: '30px',
	h: '30px',
	_hover: {
		bg: 'darkBlue',
	},
	_active: {
		bg: 'darkBlue',
	},
	bg: 'lightBlue',
};

interface IItemHeader {
	type: number;
	onOpenModal: VoidFunction;
	createdAt: string;
	changedAt: string | null;
	adminName: string;
	isOwn: boolean;
	userId: number;
	timelineId: number;
	onEdit: VoidFunction;
}

export const ItemHeader: React.FC<IItemHeader> = ({
	type,
	onOpenModal,
	changedAt,
	createdAt,
	adminName,
	isOwn,
	userId,
	timelineId,
	onEdit,
}) => {
	const { mutate, isLoading } = useDeleteMessage();

	const getIcon = useCallback(() => {
		switch (type) {
			case TimelineTypeEnum.Call:
				return <PhoneIcon />;
			case TimelineTypeEnum.Email:
				return (
					<Icon
						name={'mail'}
						width={24}
						height={24}
						color={'#4A5991'}
					/>
				);
			case TimelineTypeEnum.Note:
				return (
					<EditIcon width={24} height={24} fillColor={'#4A5991'} />
				);
			case TimelineTypeEnum.DirectMessage:
				return <ChatIcon />;
			default:
				return null;
		}
	}, [type]);

	const getNameByType = useCallback(() => {
		switch (type) {
			case TimelineTypeEnum.Call:
				return 'call';
			case TimelineTypeEnum.Email:
				return 'email';
			case TimelineTypeEnum.Note:
				return 'note';
			case TimelineTypeEnum.DirectMessage:
				return 'DM';
			default:
				return '';
		}
	}, [type]);

	return (
		<Flex>
			<Flex gap={'8px'} flex={1}>
				<Box>{getIcon()}</Box>
				<Box flex={1}>
					<HStack spacing={'4px'}>
						<Text noOfLines={1} fontWeight={600}>
							{isOwn ? 'You' : adminName}
						</Text>
						<Text noOfLines={1} color={'lightText'}>
							{`added ${getNameByType()}`}
						</Text>
					</HStack>
					<Flex gap={'10px'} align={'center'} mt={'5px'}>
						<HStack
							spacing={'8px'}
							divider={
								<Divider
									orientation={'vertical'}
									borderColor={'dropBlue'}
									h={'15px'}
								/>
							}>
							<Text variant={'bodySmall'} color={'inactiveBlue'}>
								{dayjs(createdAt).format(USDateFormat)}
							</Text>
							<Text variant={'bodySmall'} color={'inactiveBlue'}>
								{dayjs(createdAt).format('hh:mm A')}
							</Text>
						</HStack>
						{changedAt ? (
							<Text variant={'bodySmall'} color={'lightText'}>
								{`Edited ${dayjs(changedAt).format(
									USDateFormat,
								)}`}
							</Text>
						) : null}
					</Flex>
				</Box>
				{isOwn ? (
					<Menu isLazy={true}>
						<MenuButton
							{...buttonStyles}
							aria-label={'actions'}
							as={IconButton}
							icon={<DotsIcon />}
						/>
						<MenuList minW={'auto'} p={'8px'}>
							<MenuItem onClick={onOpenModal} {...menuListStyles}>
								View Details
							</MenuItem>
							<MenuItem onClick={onEdit} {...menuListStyles}>
								Edit
							</MenuItem>
							<MenuItem
								isDisabled={isLoading}
								onClick={() => {
									mutate({
										id: timelineId,
										userId,
									});
								}}
								{...menuListStyles}>
								Delete
							</MenuItem>
						</MenuList>
					</Menu>
				) : (
					<IconButton
						onClick={onOpenModal}
						{...buttonStyles}
						aria-label={'open-modal'}
						icon={<OpenIcon />}
					/>
				)}
			</Flex>
		</Flex>
	);
};
