import React from 'react';
import { Box, IconButton, Image, Text } from '@chakra-ui/react';
import { FieldWrapper } from '../../../components';
import { IoMdClose } from 'react-icons/io';

interface IProps {
	imgUrl?: string;
	onRemove: () => void;
	label?: string;
}
export const PreviewAuthorPhoto: React.FC<IProps> = ({
	imgUrl,
	onRemove,
	label,
}) => {
	return (
		<FieldWrapper>
			{label ? (
				<Text
					fontWeight={'700'}
					fontSize={'xl'}
					mb="8px"
					color={'darkBlue'}>
					{label}
				</Text>
			) : null}
			<Box pos={'relative'} display={'inline-flex'}>
				<Image
					boxSize={'50px'}
					borderRadius={'full'}
					src={imgUrl}
					alt={'photo'}
				/>
				<IconButton
					onClick={onRemove}
					right={-3}
					top={-2}
					h={'18px'}
					minW={'18px'}
					variant={'ghost'}
					position={'absolute'}
					aria-label={'remove photo'}
					icon={<IoMdClose />}
				/>
			</Box>
		</FieldWrapper>
	);
};
