import React from 'react';
import {
	Box,
	Flex,
	BoxProps,
	CloseButton,
	Text,
	IconButton,
	Center,
	Spinner,
	Avatar,
	VStack,
} from '@chakra-ui/react';
import { LinkItems } from '../../../constants';
import { NavItem } from './NavItem';
import { useAuth } from 'oidc-react';
import { LogoutIcon } from '../../../assets/icons';
import { ExpandedButton } from './ExpandedButton';
import { ICurrentUser } from '../../../types';
import { NavAccordion } from './NavAccordion';

interface SidebarProps extends BoxProps {
	onClose: () => void;
	menuExpanded: boolean;
	setMenuExpanded: (value: boolean) => void;
	data?: ICurrentUser;
	isLoading: boolean;
}

export const SidebarContent: React.FC<SidebarProps> = ({
	onClose,
	menuExpanded,
	setMenuExpanded,
	data,
	isLoading,
	...rest
}: SidebarProps) => {
	const { signOut } = useAuth();

	const fullName = `${data?.firstName} ${data?.lastName}`;

	return (
		<Flex
			direction="column"
			align="space-between"
			px={menuExpanded ? '20px' : '10px'}
			py={8}
			bg="lightBlue"
			transition=".3s"
			w={{ base: 'full', md: menuExpanded ? '300px' : '120px' }}
			overflowX="hidden"
			h="100vh"
			pos="fixed"
			{...rest}>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				width={{ base: 'auto', md: 'calc(300px - 2rem)' }}>
				<Flex alignItems={'center'}>
					<Text
						mr={menuExpanded ? '0px' : '6px'}
						color="white"
						fontWeight={500}
						fontSize="25px">
						{menuExpanded ? 'Section 8' : 'S8'}
					</Text>
					<Text
						mx={menuExpanded ? '6px' : '0px'}
						borderRadius={'10px'}
						background={'lightGreen'}
						color="white"
						fontWeight={700}
						px={'10px'}
						py={'4px'}
						fontSize="25px">
						Pro
					</Text>
					{menuExpanded ? (
						<Text color="white" fontWeight={500} fontSize="25px">
							Admin
						</Text>
					) : null}
				</Flex>
				<CloseButton
					color="white"
					display={{ base: 'flex', md: 'none' }}
					onClick={onClose}
				/>
			</Flex>
			<VStack mt={'32px'} flexGrow={1}>
				{LinkItems.map(item => {
					if (item.text === 'Community') {
						return (
							<NavAccordion
								key={item.id}
								menuExpanded={menuExpanded}>
								{item.innerLinks?.map(link => {
									return (
										<NavItem
											key={link.text}
											icon={link.icon}
											link={link.link}
											isAccordionItem={true}
											menuExpanded={menuExpanded}>
											Verification
										</NavItem>
									);
								})}
							</NavAccordion>
						);
					}
					return (
						<NavItem
							key={item.text}
							icon={item.icon}
							link={item.link}
							menuExpanded={menuExpanded}>
							{item.text}
						</NavItem>
					);
				})}
			</VStack>

			<Box>
				{isLoading ? (
					<Center>
						<Spinner size="lg" color="white" />
					</Center>
				) : (
					<>
						{menuExpanded ? (
							<Flex>
								<Avatar name={fullName} boxSize={'50px'} />
								<Box ml={'15px'}>
									<Text color="white" fontSize="16px">
										{fullName}
									</Text>
									<Text color="gray.400" fontSize="12px">
										{data?.email}
									</Text>
								</Box>

								<IconButton
									ml={'auto'}
									onClick={signOut}
									background={'none'}
									_hover={{
										background: 'blackAlpha.500',
									}}
									_active={{
										background: 'blackAlpha.500',
									}}
									aria-label={'sign-out'}
									icon={<LogoutIcon />}
								/>
							</Flex>
						) : (
							<VStack spacing={'17px'}>
								<IconButton
									onClick={signOut}
									background={'none'}
									_hover={{
										background: 'blackAlpha.500',
									}}
									_active={{
										background: 'blackAlpha.500',
									}}
									aria-label={'sign-out'}
									icon={<LogoutIcon />}
								/>
								<Avatar name={fullName} boxSize={'50px'} />
							</VStack>
						)}
					</>
				)}
			</Box>
			<ExpandedButton
				menuExpanded={menuExpanded}
				onClick={() => setMenuExpanded(!menuExpanded)}
			/>
		</Flex>
	);
};
