import { ApiResponseEmpty } from '../../../../types';
import { useMutation, useQueryClient } from 'react-query';
import { DeleteTimelinePayload } from '../../types';
import { QueryKeys } from './query-keys';
import { Alerter } from '../../../../utils/Alerter';
import { API_IDENTITY } from '../../../../api/api.base';

export const useDeleteMessage = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, DeleteTimelinePayload>({
		mutationFn: payload =>
			API_IDENTITY.delete(`/api/admin/timeline/item/${payload.id}`, {}),
		onSuccess: (data, { userId }) => {
			if (data.success) {
				if (userId) {
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_TIMELINE_LIST, userId],
					});
				}
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};
