import React from 'react';
import { Flex, Icon, HStack, Tooltip, Center, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { NavLink } from 'react-router-dom';

interface NavItemProps {
	icon: IconType;
	children: React.ReactNode;
	link: string;
	menuExpanded: boolean;
	isAccordionItem?: boolean;
}
export const NavItem: React.FC<NavItemProps> = ({
	icon,
	children,
	link,
	menuExpanded,
	isAccordionItem,
}) => (
	<Flex w="100%" justify={menuExpanded ? 'flex-start' : 'center'}>
		<NavLink
			style={{
				width: `${menuExpanded ? 'inherit' : 'auto'}`,
			}}
			to={link}>
			{({ isActive }) => (
				<HStack
					transition=".3s"
					borderRadius={8}
					color="white"
					bg={
						isActive
							? isAccordionItem && menuExpanded
								? 'lightBlue'
								: 'blackAlpha.400'
							: undefined
					}
					_hover={{
						bg:
							isAccordionItem && menuExpanded
								? 'lightBlue'
								: 'blackAlpha.500',
						color: 'white',
					}}
					p={'20px'}>
					<Tooltip
						fontSize="md"
						label={!menuExpanded ? children : ' '}
						placement="bottom"
						shouldWrapChildren={true}>
						<Center minW="20px">
							{icon ? (
								<Icon
									as={icon}
									fontSize="22px"
									justifyContent="center"
								/>
							) : null}
						</Center>
					</Tooltip>
					{menuExpanded ? (
						<Text
							fontWeight={isActive ? '700' : '500'}
							fontSize="md"
							pl={5}
							whiteSpace="nowrap"
							pr={10}>
							{children}
						</Text>
					) : null}
				</HStack>
			)}
		</NavLink>
	</Flex>
);
