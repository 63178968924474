import React, { FC, PropsWithChildren } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
} from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';

interface EditUserSection extends PropsWithChildren {
	title: string;
	isDisabled: boolean;
}

export const EditUserSection: FC<EditUserSection> = React.memo(
	({ children, title, isDisabled }) => {
		const opacity = !isDisabled ? '1' : '0.5';
		const cursor = isDisabled ? 'not-allowed' : 'pointer';
		return (
			<Accordion
				defaultIndex={[1]}
				bg={'#F1F4F9'}
				opacity={opacity}
				borderRadius={'10px'}
				_hover={{ bg: '#F1F4F9', borderRadius: '10px' }}
				mt={3}
				allowToggle={true}>
				<AccordionItem border={'none'}>
					<AccordionButton
						cursor={cursor}
						_hover={{
							bg: '#F1F4F9',
							borderRadius: '10px',
						}}
						onClick={e => {
							if (isDisabled) {
								e.preventDefault();
							}
						}}
						p={'20px 17px'}>
						<Box
							as="span"
							flex="1"
							textAlign="left"
							fontSize={'20px'}
							fontWeight={700}>
							{title}
						</Box>
						{isDisabled ? <LockIcon /> : <AccordionIcon />}
					</AccordionButton>
					<AccordionPanel
						borderTop={'3px solid white'}
						p={'20px 17px 20px 17px'}>
						{children}
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		);
	},
);

EditUserSection.displayName = 'EditUserSection';
