import React from 'react';
import { Box, BoxProps, Progress, Text } from '@chakra-ui/react';

interface ICustomProgressProps extends BoxProps {
	progress: number;
	showProgress?: boolean;
	variant?: 'light' | 'dark';
}
export const CustomProgress: React.FC<ICustomProgressProps> = ({
	progress,
	showProgress,
	variant = 'dark',
	...rest
}) => {
	return (
		<Box {...rest}>
			<Progress
				variant={variant}
				h="8px"
				borderRadius="10px"
				value={progress}
			/>
			{showProgress ? (
				<Text mt="5px" fontSize="14px" color="lightGray">
					Progress {progress}/100%
				</Text>
			) : null}
		</Box>
	);
};
