import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import { API_IDENTITY } from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useChangeUserPassword = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ userId: number; password: string }
	>({
		mutationFn: payload =>
			API_IDENTITY.post('/api/admin/users/change-user-password', payload),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
				return;
			}
			Alerter.success(data.messages[0].message);
		},
		onError: () => {
			Alerter.error('Error while changing password');
		},
	});
};
