import { useQuery } from 'react-query';
import { fetchTransactionHistory } from '../helpers';

export const useGetTransactionHistory = (
	pageSize: number,
	currentPage: number,
	date: string | null,
	cardNumbers: string[],
) => {
	return useQuery(
		[
			'transaction-history',
			currentPage,
			pageSize,
			date,
			cardNumbers.length,
		],
		() => fetchTransactionHistory(pageSize, currentPage, date, cardNumbers),
	);
};
