import { create } from 'zustand';
import { PermissionGroup } from '../types';

interface IState {
	items: PermissionGroup[];
	setItems: (value: PermissionGroup[]) => void;
}

export const usePermissionGroupsStore = create<IState>(set => ({
	items: [],
	setItems: value => set(() => ({ items: value })),
}));
