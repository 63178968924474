import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { SectionItemType } from '../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useGetSections = (courseId: number) => {
	const { isFetching, data } = useQuery<
		ApiResponse<{
			sections: SectionItemType[];
			courseCompletedMessage: string | null;
			isCourseCompletedMessage: boolean;
		}>
	>({
		queryKey: ['sections', courseId],
		enabled: !!courseId,
		queryFn: async () =>
			Api.get(`/api/admin/courses/${courseId}/third-step`, {}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0]?.message);
			}
		},
		onError: () => {
			Alerter.error('Error while getting sections');
		},
	});

	return {
		isLoading: isFetching,
		sections: data?.value.sections,
		courseCompletedMessage: data?.value.courseCompletedMessage,
		isCourseCompletedMessage: data?.value.isCourseCompletedMessage,
	};
};
