import { useMutation } from 'react-query';
import { ApiResponse } from '../../../types';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';
import { useNavigate } from 'react-router-dom';

export const useCreateCourse = () => {
	const navigate = useNavigate();
	const { isLoading, mutate } = useMutation<ApiResponse<number>>({
		mutationFn: async () => API.post('/api/admin/courses/create', {}),
		onSuccess: data => {
			if (data.success) {
				navigate('/courses/create-course', {
					state: {
						courseId: data.value,
					},
				});
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while create course');
		},
	});

	return {
		isLoading,
		onCreateCourse: mutate,
	};
};
