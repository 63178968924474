import {
	Box,
	Button,
	Flex,
	HStack,
	IconButton,
	Link,
	Text,
} from '@chakra-ui/react';
import { formatFileSize } from '../../../../../../../utils';
import { UnionTimelineAttachment } from '../../../../../types';
import { CloseIcon } from '../../../../../../../assets/icons';

interface IFileItemView {
	item: UnionTimelineAttachment;
	onRemove?: VoidFunction;
	canRemove: boolean;
}

export const FileItemView: React.FC<IFileItemView> = ({
	item,
	onRemove,
	canRemove,
}) => {
	const name = `${item.name}.${item.extension}`;
	return (
		<Flex
			h={'37px'}
			px={'8px'}
			borderRadius={'5px'}
			bg={'background'}
			align={'center'}>
			<Box flex={1}>
				<Link
					aria-label={item.name}
					rel="noopener noreferrer"
					href={item.fileUrl || item.imageUrl}
					isExternal={true}
					variant={'link'}
					size={'sm'}
					color={'inactiveBlue'}>
					<Text noOfLines={1} fontWeight={600} variant={'bodyMedium'}>
						{name}
					</Text>
				</Link>
			</Box>
			<HStack spacing={'16px'}>
				<Text variant={'bodyMedium'} color={'lightText'}>
					{formatFileSize(item.fileSize)}
				</Text>
				{canRemove ? (
					<IconButton
						onClick={onRemove}
						variant={'ghost'}
						size={'xs'}
						aria-label={'remove-file'}
						icon={<CloseIcon size={'12'} color={'#4A5991'} />}
					/>
				) : null}
			</HStack>
		</Flex>
	);
};
