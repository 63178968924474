export const allowImageMime = {
	'image/png': ['.png'],
	'image/bmp': ['.bmp'],
	'image/jpeg': ['.jpeg', '.jpg'],
	'image/gif': ['.gif'],
};

export const allowVideoMime = {
	'video/mp4': ['.mp4'],
	'video/webm': ['.webm'],
};
