import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const inviteUserSchema = yup.object().shape({
	firstName: yup.string().required(ErrorMessages.REQUIRED),
	lastName: yup.string().required(ErrorMessages.REQUIRED),
	email: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.email(ErrorMessages.EMAIL_INVALID),
	roleId: yup.string().required(ErrorMessages.REQUIRED),
	permissionId: yup.string().required(ErrorMessages.REQUIRED),
	isContactCreated: yup.boolean(),
	areDealsCreated: yup.boolean(),
	value: yup.number(),
	description: yup.string(),
});

export const inviteUserResolver = yupResolver(inviteUserSchema);
