import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';

export const useDeleteAuthor = () => {
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation<
		ApiResponseEmpty,
		unknown,
		number
	>({
		mutationFn: id => Api.delete(`/api/admin/courses/authors/${id}`),
		onSuccess: data => {
			if (data.success) {
				Alerter.success('Author successfully deleted');
				queryClient.invalidateQueries('authors');
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while deleting author');
		},
	});
	return {
		isLoading,
		onDeleteAuthor: mutate,
	};
};
