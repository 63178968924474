import axios, { AxiosError } from 'axios';
import { userManager } from '../utils';

const byPassInterceptionRoutes = ['/verify'];

export const byPassInterceptionRegExp = new RegExp(
	byPassInterceptionRoutes.join('|'),
);

export default function interceptor(baseUrl: string) {
	const axiosInstance = axios.create();
	axiosInstance.defaults.baseURL = baseUrl;

	axiosInstance.interceptors.request.use(async req => {
		const user = await userManager.getUser();

		const token = user?.access_token;

		if (!user) {
			const settings = userManager.settings;
			await userManager.signinRedirect(settings);
		}

		if (token) {
			if (req.headers) {
				req.headers.Authorization = `Bearer ${token}`;
			} else delete req.headers['Authorization'];
		}

		return req;
	});

	axiosInstance.interceptors.response.use(
		undefined,
		async (err: AxiosError) => {
			const { response, config } = err;
			if (
				response &&
				(response.status === 403 || response.status === 401)
			) {
				if (
					config?.url &&
					!byPassInterceptionRegExp.test(config?.url)
				) {
					await userManager.removeUser();
					await userManager.signoutRedirect({
						extraQueryParams: {
							returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
						},
					});
				}
			}
			return Promise.reject(err);
		},
	);
	return axiosInstance;
}
