import { HStack, VStack, Text, Box } from '@chakra-ui/react';
import { ItemFooterContent } from './ItemFooterContent';
import { TimelineTypeEnum } from '../../../../../../constants';
import { TimelineContentType } from '../../../../../../types';

interface IItemContent {
	type: number;
	content: TimelineContentType;
	date: string | null;
	timeFrom: string | null;
	timeTo: string | null;
	attachmentCount: number;
}

export const ItemContent: React.FC<IItemContent> = ({
	type,
	content,
	date,
	timeTo,
	timeFrom,
	attachmentCount,
}) => {
	const getContent = () => {
		switch (type) {
			case TimelineTypeEnum.Email:
				return (
					<>
						<HStack spacing={'4px'}>
							<Text variant={'bodyMedium'} color={'lightText'}>
								From:
							</Text>
							<Text
								noOfLines={1}
								variant={'bodyMedium'}
								color={'lightBlue'}>
								{content?.email || ''}
							</Text>
						</HStack>
						<Box>
							{content?.subject ? (
								<Text
									noOfLines={1}
									fontWeight={600}
									variant={'bodyMedium'}
									color={'lightBlue'}>
									{content.subject}
								</Text>
							) : null}
							<Text
								noOfLines={2}
								variant={'bodyMedium'}
								mt={'5px'}>
								{content?.bodyText || ''}
							</Text>
						</Box>
					</>
				);
			case TimelineTypeEnum.Call:
				return (
					<Box>
						{content?.topic ? (
							<Text
								noOfLines={1}
								fontWeight={600}
								variant={'bodyMedium'}
								color={'lightBlue'}>
								{content?.topic}
							</Text>
						) : null}
						{content?.note ? (
							<Text
								noOfLines={2}
								variant={'bodyMedium'}
								mt={'5px'}>
								{content?.note}
							</Text>
						) : null}
					</Box>
				);

			case TimelineTypeEnum.DirectMessage:
				return (
					<Text noOfLines={2} variant={'bodyMedium'}>
						{content?.message || ''}
					</Text>
				);

			case TimelineTypeEnum.Note:
				return (
					<Text noOfLines={2} variant={'bodyMedium'}>
						{content?.note || ''}
					</Text>
				);
			default:
				return null;
		}
	};

	return (
		<VStack align={'stretch'} spacing={'10px'} mt={'10px'}>
			{getContent()}
			<ItemFooterContent
				date={date}
				timeTo={timeTo}
				timeFrom={timeFrom}
				attachmentCount={attachmentCount}
			/>
		</VStack>
	);
};
