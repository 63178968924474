import React from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import {
	CheckCircleIcon,
	CirclePlaceholderIcon,
	EditIcon,
} from '../../../../../assets/icons';

interface IAuthorItem {
	onSelected: () => void;
	selected: boolean;
	setIdToEdit: () => void;
	name: string;
}
export const AuthorItem: React.FC<IAuthorItem> = ({
	onSelected,
	selected,
	setIdToEdit,
	name,
}) => {
	return (
		<Flex
			align={'center'}
			justify={'space-between'}
			bg={'white'}
			p={'20px'}
			borderRadius={'10px'}>
			<Flex align={'center'}>
				<Text fontWeight={'500'} color={'lightBlue'}>
					{name}
				</Text>
				<IconButton
					ml={'20px'}
					onClick={setIdToEdit}
					aria-label={'Edit'}
					icon={<EditIcon fillColor={'#08BB4B'} />}
				/>
			</Flex>

			<IconButton
				onClick={onSelected}
				aria-label={'pick author'}
				icon={
					selected ? <CheckCircleIcon /> : <CirclePlaceholderIcon />
				}
			/>
		</Flex>
	);
};
