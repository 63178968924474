import { ApiResponseEmpty } from '../../../../types';
import { useMutation, useQueryClient } from 'react-query';
import { CreateTimelinePayload } from '../../types';
import { QueryKeys } from './query-keys';
import { Alerter } from '../../../../utils/Alerter';
import { API_IDENTITY } from '../../../../api/api.base';

export const useCreateTimelineItem = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, CreateTimelinePayload>({
		mutationFn: ({ payload }) =>
			API_IDENTITY.post('/api/admin/timeline', payload),
		onSuccess: (data, { userId }) => {
			if (data.success) {
				if (userId) {
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_TIMELINE_LIST, userId],
					});
				}
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};
