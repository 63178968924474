import { ApiResponseEmpty } from '../../../../types';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateTimelinePayload } from '../../types';
import { QueryKeys } from './query-keys';
import { Alerter } from '../../../../utils/Alerter';
import { API_IDENTITY } from '../../../../api/api.base';

export const useUpdateTimelineItemById = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, UpdateTimelinePayload>({
		mutationFn: ({ payload, timelineId }) =>
			API_IDENTITY.put(`/api/admin/timeline/${timelineId}`, payload, {
				'Content-Type': 'multipart/form-data',
			}),
		onSuccess: (data, { userId, timelineId }) => {
			if (data.success) {
				if (userId) {
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_TIMELINE_LIST, userId],
					});
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_TIMELINE_DETAILS, timelineId],
					});
				}
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};
