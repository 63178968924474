export const ChatIcon: React.FC = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.2 14.7L7.43227 17.5023C7.04621 17.8932 6.85318 18.0887 6.68726 18.1024C6.54331 18.1144 6.40237 18.0565 6.30836 17.9469C6.2 17.8205 6.2 17.5458 6.2 16.9964V15.5925C6.2 15.0996 5.79637 14.7429 5.30868 14.6715V14.6715C4.12837 14.4986 3.2014 13.5716 3.02851 12.3913C3 12.1967 3 11.9645 3 11.5V7.32C3 5.80786 3 5.05179 3.29428 4.47423C3.55314 3.96619 3.96619 3.55314 4.47423 3.29428C5.05179 3 5.80786 3 7.32 3H13.98C15.4921 3 16.2482 3 16.8258 3.29428C17.3338 3.55314 17.7469 3.96619 18.0057 4.47423C18.3 5.05179 18.3 5.80786 18.3 7.32V11.1M18.3 21L16.3412 19.6382C16.0659 19.4468 15.9282 19.351 15.7784 19.2832C15.6454 19.223 15.5056 19.1791 15.362 19.1527C15.2003 19.1229 15.0326 19.1229 14.6972 19.1229H13.08C12.0719 19.1229 11.5679 19.1229 11.1828 18.9267C10.8441 18.7541 10.5688 18.4787 10.3962 18.14C10.2 17.755 10.2 17.251 10.2 16.2429V13.98C10.2 12.9719 10.2 12.4679 10.3962 12.0828C10.5688 11.7441 10.8441 11.4688 11.1828 11.2962C11.5679 11.1 12.0719 11.1 13.08 11.1H18.12C19.1281 11.1 19.6321 11.1 20.0172 11.2962C20.3559 11.4688 20.6312 11.7441 20.8038 12.0828C21 12.4679 21 12.9719 21 13.98V16.4229C21 17.2616 21 17.6809 20.863 18.0117C20.6803 18.4527 20.3299 18.8032 19.8888 18.9858C19.558 19.1229 19.1387 19.1229 18.3 19.1229V21Z"
				stroke="#4A5991"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
