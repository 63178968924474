import React from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { InputText, InputTextArea } from '../../../../../components';
import { FieldWrapper } from '../../../components';
import { useForm } from 'react-hook-form';
import {
	CreateSectionForm,
	createSectionResolver,
} from '../../../../../validation/create-section.schema';
import { useCreateSection } from '../../../queries';

interface ICreateSection {
	onClose: () => void;
	courseId: number;
}
export const CreateSection: React.FC<ICreateSection> = ({
	onClose,
	courseId,
}) => {
	const {
		reset,
		formState: { errors },
		handleSubmit,
		register,
	} = useForm<CreateSectionForm>({
		defaultValues: {
			name: '',
			description: '',
		},
		mode: 'onSubmit',
		resolver: createSectionResolver,
	});

	const { isLoading, onCreateSection } = useCreateSection();

	const onResetForm = () => {
		onClose();
		reset({
			name: '',
			description: '',
		});
	};

	const onSubmit = (values: CreateSectionForm) => {
		if (courseId) {
			onCreateSection(
				{
					courseId: courseId,
					body: values,
				},
				{
					onSuccess: () => {
						onResetForm();
					},
				},
			);
		}
	};

	return (
		<FieldWrapper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<VStack spacing={'20px'} align={'stretch'}>
					<InputText
						label={'Section Name'}
						isDisabled={isLoading}
						placeholder={'Name'}
						errorMsg={errors.name?.message}
						{...register('name')}
					/>
					<InputTextArea
						isDisabled={isLoading}
						label={'Section Description'}
						placeholder={'Description'}
						errorMsg={errors.description?.message}
						{...register('description')}
					/>
					<Button
						isLoading={isLoading}
						isDisabled={isLoading}
						size={'lg'}
						borderColor={'lightGreen'}
						color={'lightGreen'}
						variant={'outline'}
						type={'submit'}>
						Save
					</Button>
				</VStack>
			</form>
		</FieldWrapper>
	);
};
