import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { CoursesItem } from './Courses.item';
import { CourseAnalyticsItem } from '../../../types';

interface ICoursesListProps {
	courses: CourseAnalyticsItem[];
}

export const CoursesList: React.FC<ICoursesListProps> = ({ courses }) => {
	return (
		<SimpleGrid
			alignItems="flex-start"
			mt="20px"
			gridTemplateColumns="repeat(auto-fill, minmax(390px,1fr))"
			gap="20px">
			{courses.map(item => (
				<CoursesItem key={item.id} {...item} />
			))}
		</SimpleGrid>
	);
};
