import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { IErrorLogs, IInfoLogs } from '../../types/logs.types';
import { ErrorsTable } from './components/ErrorsTable';
import { InfoTable } from './components/InfoTable';

interface ILogsProps {
	currentMenuTab: number;
	setCurrentMenuTab: React.Dispatch<React.SetStateAction<number>>;
	data: {
		totalCount: number;
		logInfo?: IInfoLogs[];
		logErrors?: IErrorLogs[];
	};
	isLoading: boolean;
	currentPage: number;
	pageSize: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	setPageSize: React.Dispatch<React.SetStateAction<number>>;
	deleteLogs: (url: string) => Promise<any>;
}

export const Logs: React.FC<ILogsProps> = ({
	currentMenuTab,
	setCurrentMenuTab,
	data,
	isLoading,
	currentPage,
	pageSize,
	setCurrentPage,
	setPageSize,
	deleteLogs,
}) => {
	return (
		<Box p={{ base: '10px', md: '20px' }} bg="white">
			<Button
				borderRadius={'0px'}
				colorScheme="linkedin"
				variant="ghost"
				_hover={{ bg: 'none' }}
				onClick={() => {
					setCurrentMenuTab(0);
					setCurrentPage(1);
				}}
				borderBottom={
					!currentMenuTab ? '2px solid #008CC9' : '2px solid white'
				}
				color={!currentMenuTab ? '#008CC9' : ''}>
				Main
			</Button>
			<Button
				borderRadius={'0px'}
				variant="ghost"
				_hover={{ bg: 'none' }}
				onClick={() => {
					setCurrentMenuTab(1);
					setCurrentPage(1);
				}}
				borderBottom={
					currentMenuTab ? '2px solid #008CC9' : '2px solid white'
				}
				color={currentMenuTab ? '#008CC9' : ''}>
				Errors
			</Button>

			{currentMenuTab ? (
				<ErrorsTable
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					data={data}
					isLoading={isLoading}
					deleteLogs={deleteLogs}
				/>
			) : (
				<InfoTable
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					data={data}
					isLoading={isLoading}
					deleteLogs={deleteLogs}
				/>
			)}
		</Box>
	);
};
