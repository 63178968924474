/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import { useDrag, useDrop } from 'react-dnd/dist/hooks';
import { Link } from 'react-router-dom';
import {
	Box,
	Button,
	Flex,
	Image,
	Skeleton,
	VStack,
	Text,
	HStack,
} from '@chakra-ui/react';
import { EditIcon, HighlightIcon } from '../../../assets/icons';
import { colors } from '../../../theme/colors';
import { CourseItemType, StatusCourseEnum } from '../types';

interface ICourseItem {
	item: CourseItemType;
	setIdToDeleteCourse: (id: number) => void;
	setIdToPublishCourse: (id: number) => void;
	setIdToUnpublishedCourse: (id: number) => void;
	setCourseToUpdateHighlight: (course: CourseItemType) => void;
	onDropItem: (draggedItemId: number, newOrder: number) => void;
	isEditing: boolean;
	index: number;
	onHoverItem: (draggedItemId: number, newOrder: number) => void;
}
export const CourseItem: React.FC<ICourseItem> = ({
	item,
	setIdToDeleteCourse,
	setIdToPublishCourse,
	setIdToUnpublishedCourse,
	setCourseToUpdateHighlight,
	isEditing,
	onDropItem,
	index,
	onHoverItem,
}) => {
	const onAction = () => {
		if (item.statusId === StatusCourseEnum.Unpublished) {
			setIdToPublishCourse(item.id);
		}
		if (item.statusId === StatusCourseEnum.Published) {
			setIdToUnpublishedCourse(item.id);
		}
	};

	const [{ isDragging }, drag, preview] = useDrag({
		type: 'draggable-item',
		item: { id: item.id, index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		options: {
			dropEffect: 'copy',
		},
	});

	const [, drop] = useDrop({
		accept: 'draggable-item',
		hover(course: CourseItemType) {
			if (course.id !== item.id) {
				onHoverItem(course.id, index);
			}
		},
		drop(course: CourseItemType) {
			onDropItem(course.id, index);
		},
	});

	return (
		<Box
			borderRadius={'10px'}
			padding={'20px'}
			background={item.highlighted ? 'lightBlue' : 'white'}
			opacity={isDragging ? 0.75 : 1}
			ref={node => {
				if (isEditing) {
					drop(node);
					preview(node);
					drag(node);
				}
			}}
			cursor={isEditing ? (isDragging ? 'grabbing' : 'grab') : 'default'}>
			<VStack spacing={'20px'} align={'stretch'}>
				<Flex justifyContent={'right'}>
					{isEditing ? (
						<Button
							onClick={() => setCourseToUpdateHighlight(item)}
							leftIcon={
								item.highlighted ? undefined : <HighlightIcon />
							}
							borderRadius={'50px'}
							colorScheme={
								item.highlighted
									? 'whiteButton'
									: 'lightBlueButton'
							}
							fontWeight={500}>
							<Text
								color={
									item.highlighted
										? colors.lightBlue
										: 'white'
								}>
								{item.highlighted
									? 'Remove highlight'
									: 'Highlight'}
							</Text>
						</Button>
					) : (
						<Button
							onClick={onAction}
							rightIcon={
								item.statusId ===
								StatusCourseEnum.Published ? undefined : (
									<EditIcon />
								)
							}
							borderRadius={'50px'}
							colorScheme={
								item.statusId === StatusCourseEnum.Published
									? 'greenButton'
									: 'inactiveButton'
							}>
							{item.statusId === StatusCourseEnum.Unpublished
								? 'Unpublished'
								: 'Published'}
						</Button>
					)}
				</Flex>
				<Box>
					<Image
						objectFit={'contain'}
						fallback={
							<Skeleton h={'224px'} borderRadius={'10px'} />
						}
						borderRadius={'10px'}
						alt={'course image'}
						src={item?.imageLink ? item.imageLink : undefined}
						h={'224px'}
						w={'full'}
					/>
				</Box>
				<Box>
					<Text
						isTruncated={true}
						fontWeight={'700'}
						fontSize={'2xl'}
						color={item.highlighted ? 'white' : 'darkBlue'}>
						{item.title}
					</Text>
					<Text
						isTruncated={true}
						fontWeight={'500'}
						color={item.highlighted ? 'white' : 'darkBlue'}>
						{item.description}
					</Text>
				</Box>
				<HStack spacing={'14px'}>
					<Button
						as={Link}
						to={isEditing ? '' : `/courses/edit-course/${item.id}`}
						colorScheme={
							item.highlighted ? 'whiteButton' : 'darkButton'
						}
						borderRadius="10px"
						color={item.highlighted ? 'darkButton' : 'white'}
						h={'59px'}
						w={'full'}
						isDisabled={isEditing}>
						Edit Course
					</Button>
					<Button
						onClick={() => {
							setIdToDeleteCourse(item.id);
						}}
						color={item.highlighted ? 'white' : 'darkBlue'}
						variant={'outline'}
						border={`2px solid ${
							item.highlighted ? 'white' : colors.lightBlue
						}`}
						h={'59px'}
						w={'full'}
						borderRadius="10px"
						isDisabled={isEditing}
						_hover={
							!isEditing
								? {
										bg: item.highlighted
											? colors.darkBlueTable[100]
											: 'gray.100',
								  }
								: {}
						}>
						Delete Course
					</Button>
				</HStack>
			</VStack>
		</Box>
	);
};
